import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Grid, Typography } from "@mui/material";
import CustomDialog from "../../../components/atoms/CustomDialog";
import Label from "../../../components/atoms/Label";
import { Box } from "@mui/system";
import VehicleDocumentModal from "../../../components/organisms/VehicleDocumentModal";
import DriverDocumentModal from "../../../components/organisms/DriverDocumentModal";
import DetailTag from "../../../components/atoms/DetailTag";
import { AirlineSeatReclineNormalRounded, RouteRounded } from "@mui/icons-material";
import moment from "moment";
import no_data from '../../../assets/no-data.svg';
import turkish_lira from '../../../assets/icons/turkish-lira.svg';
import StationCardForOverTime from "../../../components/molecules/StationCardForOverTime";
import { CostContext } from "../Mesailer/mesai-detay";
import { useOverTime } from "../../../context/OverTimeContext";
import Cost from "../../../components/atoms/Cost";
import PlanCard from "../../../components/molecules/PlanCard";
import styles from './yolcular.module.scss';
const OverTimeDetailModal = React.memo(
  ({ queryId, data, searchParams, setSearchParams, role }) => {
    const navigate = useNavigate();
    const overtime = data.find((v) => v.id == queryId);
    const [selectedPlan, setSelectedPlan] = useState(null)
    const handleClose = () => {
      if (searchParams.has("id")) {
        searchParams.delete("id");
        setSearchParams(searchParams);
      }
    };
    const labels = [
      {
        title: "İsim",
        value: overtime?.firstName,
      },
      {
        title: "Soyisim",
        value: overtime?.lastName,
      },
      {
        title: "Kurum",
        value: overtime?.workTime?.customer?.title,
      },
      {
        title: "Yolcu Tipi",
        value:
          overtime?.workTime?.customer?.customerType === "Company"
            ? "Personel"
            : overtime?.workTime?.customer?.customerType === "School"
              ? "Öğrenci"
              : "",
      },
      {
        title:
          overtime?.workTime?.customer?.customerType === "Company"
            ? "Telefon"
            : overtime?.workTime?.customer?.customerType === "School"
              ? "Veli Telefon"
              : "",
        value:
          overtime?.workTime?.customer?.customerType === "Company"
            ? overtime?.phone?.areaCode + overtime?.phone?.number
            : overtime?.workTime?.customer?.customerType === "School"
              ? overtime?.parent?.phone?.areaCode +
              overtime?.parent?.phone?.number
              : "",
      },
      {
        title: "Adres",
        value: ` ${overtime?.address?.neighborhood || "-"}
            ${overtime?.address?.street || "-"} Cd/Sk.
            No: ${overtime?.address?.buildingInformation || "-"}
            ${overtime?.address?.district || "-"}/
            ${overtime?.address?.province || "-"}`,
      },
    ];
    return (
      <CustomDialog
        size="md"
        open={queryId ? true : false}
        closeFunc={handleClose}
        title="Mesai Detayı"
        buttons={
          role === "ADMIN" || role === "MANAGER"
            ? [
              /*  {
                   onClick: () => alert("todo"),
                   type: "button",
                   color: "error",
                   variant: "outlined",
                   text: "Sil",
               }, */
              {
                onClick: () =>
                  navigate(
                    `/app/musteriler/${overtime?.workTime?.customer?.id}/yolcular/duzenle/${overtime?.id}`
                  ),
                type: "submit",
                variant: "contained",
                text: "Düzenle",
              },
            ]
            : []
        }
      >
        <Box className={styles.card}>
          {overtime?.plans?.map((v, i) => (
            <PlanCard key={i} plan={v} onClick={(e) => {
              e.preventDefault()
              setSelectedPlan(overtime?.plans[i])
            }} />
          ))
          }
        </Box>
        {selectedPlan && <Box className={styles.container}>
          <Box className={styles.header}>
            <Box className={styles.tags}>
              <VehicleDocumentModal
                vehicleId={selectedPlan?.vehicle?.id || null}
                text={selectedPlan?.vehicle?.licensePlate || "Bilinmiyor"}
              />
              <DriverDocumentModal
                driverId={selectedPlan?.vehicle?.driver?.id || selectedPlan?.vehicle?.driver?.id || null}
                text={selectedPlan?.vehicle?.driver ? `${selectedPlan?.vehicle.driver?.firstName || ""} ${selectedPlan?.vehicle.driver?.lastName || ""}` : selectedPlan?.vehicle?.driver ? `${selectedPlan?.vehicle.driver?.firstName || ""} ${selectedPlan?.vehicle.driver?.lastName || ""}` : "Bilinmiyor"}
              />
              <DetailTag
                icon={<AirlineSeatReclineNormalRounded fontSize="inherit" />}
                text={selectedPlan?.vehicle?.capacity || "Bilinmiyor"}
              />
              <DetailTag
                icon={<i className="bi bi-stopwatch-fill" />}
                text={selectedPlan?.duration ? `~ ${moment.duration(selectedPlan?.duration).hours() ? moment.duration(selectedPlan?.duration).hours() + " saat " : ""}${moment.duration(selectedPlan?.duration).minutes() + " dakika"}` : "Bilinmiyor"}
              />
              <DetailTag
                icon={<RouteRounded fontSize="inherit" />}
                text={selectedPlan?.distance ? `~ ${new Intl.NumberFormat('tr-TR').format(selectedPlan?.distance)} metre` : "Bilinmiyor"}
              />
            </Box>
            <Divider flexItem />
            <Typography variant="h6">
              | Güzergah için seçilen duraklar
            </Typography>
          </Box>
          <Box className={styles.list2}>
            {
              selectedPlan?.stations?.length > 0 ?
                selectedPlan?.stations?.map((station, index) => (
                  <StationCardForOverTime
                    key={station.id}
                    station={station}
                    index={index}
                  />
                ))
                :
                <Box display="flex" flexDirection="column" alignItems="center" gap="1rem" p="1rem">
                  <img src={no_data} alt="" />
                  <Typography>
                    Durak bulunamadı!
                  </Typography>
                </Box>
            }
          </Box>
        </Box>}
        {/*  <Grid container spacing={3}>
          {data.length > 0 &&
            labels.map((v, i) => (
              <Grid key={i} item xs={12} md={6}>
                <Label title={v.title} value={v.value} />
              </Grid>
            ))}
        </Grid> */}
      </CustomDialog>
    );
  }
);
export default OverTimeDetailModal;





