import React, { forwardRef, MutableRefObject } from 'react'
import { MapContainer, MapContainerProps, TileLayer } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import { Box, CircularProgress } from '@mui/material';
import L from 'leaflet'

export const MAP_DEFAUTLS = {
    center: [37.783333, 29.094715], //Denizli
    zoom: 12,
}

type Ref = L.Map

const Leaflet = forwardRef<Ref, { children: any, setMapReady: (e: boolean) => void, isLoading: boolean }>(({ children, setMapReady, isLoading }, ref) => {
    return (
        <MapContainer
            whenReady={() => setMapReady(true)}
            {...MAP_DEFAUTLS as MapContainerProps}
            ref={ref}
            minZoom={6}
            doubleClickZoom={false}
        >
            <TileLayer
                url='https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
            {
                isLoading &&
                <Box sx={{
                    position: "fixed",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "rgba(255,255,255,0.7)",
                    zIndex: 1001
                }}>
                    <CircularProgress />
                </Box>
            }
            {children}
        </MapContainer>
    )
})

export default Leaflet;