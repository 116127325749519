import { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import PageLayout from "../../../components/templates/PageLayout";
import {
  getCustomerByIdRequest,
  getCustomerStatisticRequest,
  getCustomerPermissionsRequest,
  updateCustomerPermissionsRequest,
} from "../../../api/controllers/customer-controller";
import { Outlet, useNavigate, useOutlet, useParams } from "react-router";
import { RouteRounded, ShareLocationRounded } from "@mui/icons-material";
import styles from "./musteriler.module.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Link } from "react-router-dom";
import { useCustomer } from "../../../context/CustomerContext";
import Switch from "@mui/material/Switch";
import { getManagerCustomerPermissionsRequest } from "../../../api/controllers/manager-controller";
import { useAuth } from "../../../context/AuthContext";

export default function Page() {
  const { customer, setCustomer, actionButtons } = useCustomer();
  const { props } = useOutlet();
  const tabValue = props.children.props.match.route.path;
  const navigate = useNavigate();
  const { customerId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth();
  const { role } = currentUser;
  const [managerPermission, setManagerPermission] = useState([]);
console.log(role)
  useEffect(() => {
    if (customerId && (role === "MANAGER" || role === "ADMIN") ) {
      (async () => {
        let res = await getManagerCustomerPermissionsRequest(
          currentUser?.id,
          customerId
        );
        console.log(res,"we here");
        if (res) {
          setManagerPermission(res.data);
        }
      })();
    }
  }, [customerId]);

  const getCustomer = async () => {
    setIsLoading(true);
    try {
      let res = await getCustomerByIdRequest(customerId);
      if (res) {
        setCustomer(res.data);
      }
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    getCustomer();
  }, []);

  const menuItems = [
    {
      onClick: () => navigate(`/app/musteriler/${customerId}/detay/duzenle`),
      icon: null,
      text: "Müşteriyi Düzenle",
    },
  ];
console.log(managerPermission)
  return (
    !isLoading && (
      <TabContext value={tabValue}>
        <PageLayout
          title={customer?.title || "Müşteri Detayı"}
          detailPage
          buttons={actionButtons}
          sidebar={<Sidebar tabValue={tabValue} customer={customer} />}
          menuItems={
            tabValue === "detay" &&
            managerPermission?.includes("CUSTOMER_EDIT") &&
            menuItems
          }
        >
          <TabPanel sx={{ padding: 0 }} value={tabValue}>
            <Outlet />
          </TabPanel>
        </PageLayout>
      </TabContext>
    )
  );
}

const Sidebar = ({ tabValue, customer }) => {
  const [customerPerms, setCustomerPerms] = useState([]);
  const [switchState, setSwitchState] = useState({
    TRAVELER_READ: false,
    ROUTE_READ: false,
  });

  useEffect(() => {
    setSwitchState({
      TRAVELER_READ: customerPerms?.includes("TRAVELER_READ"),
      ROUTE_READ: customerPerms?.includes("ROUTE_READ"),
    });
  }, [customerPerms]);

  const [statistic, setStatistic] = useState({});

  useEffect(() => {
    (async () => {
      let res = await getCustomerStatisticRequest(customer.id);
      if (res) {
        setStatistic(res.data);
      }
    })();
  }, [window.location.search]);

  const getCustomerPermissions = async () => {
    try {
      let res = await getCustomerPermissionsRequest(customer.id);
      console.log(res,"adsdasd");
      if (res) {
        setCustomerPerms(res.data);
      }
    } catch (error) {}
  };
  const sidebarItemsCompany = [
    {
      text: "Bilgiler",
      to: `detay`,
      icon: <i className="bi bi-info-circle-fill" />,
    },
    {
      text: "Vardiyalar",
      to: `vardiyalar`,
      tabValue: "vardiyalar",
      icon: <i className="bi bi-clock-history" />,
      count: `${statistic.workTimeCount || 0}`,
    },
    {
      text: "Departmanlar",
      to: `departmanlar`,
      tabValue: "departmanlar",
      icon: <i className="bi bi-boxes"></i>,
      count: `${statistic.departmentCount || 0}`,
    },
    {
      text: "Yolcular",
      to: `yolcular`,
      tabValue: "yolcular",
      icon: <i className="bi bi-people-fill" />,
      count: `${statistic.travelerCount || 0}`,
    },
    {
      text: "Güzergahlar",
      to: `guzergahlar`,
      icon: <RouteRounded fontSize="inherit" />,
      count: `${statistic.planCount || 0}`,
    },
    {
      text: "Günlük Görevler",
      to: `gunluk-gorevler`,
      icon: <ShareLocationRounded fontSize="inherit" />,
    },
    {
      text: "Raporlar",
      to: `raporlar`,
      icon: <i className="bi bi-file-earmark-spreadsheet" />,
    },
  ];

  const sidebarItemsSchool = [
    {
      text: "Bilgiler",
      to: `detay`,
      icon: <i className="bi bi-info-circle-fill" />,
    },
    {
      text: "Vardiyalar",
      to: `vardiyalar`,
      tabValue: "vardiyalar",
      icon: <i className="bi bi-clock-history" />,
      count: `${statistic.workTimeCount || 0}`,
    },
    {
      text: "Yolcular",
      to: `yolcular`,
      tabValue: "yolcular",
      icon: <i className="bi bi-people-fill" />,
      count: `${statistic.travelerCount || 0}`,
    },
    {
      text: "Güzergahlar",
      to: `guzergahlar`,
      icon: <RouteRounded fontSize="inherit" />,
      count: `${statistic.planCount || 0}`,
    },
    {
      text: "Günlük Görevler",
      to: `gunluk-gorevler`,
      icon: <ShareLocationRounded fontSize="inherit" />,
    },
    {
      text: "Raporlar",
      to: `raporlar`,
      icon: <i className="bi bi-file-earmark-spreadsheet" />,
    },
  ];

  const handleRoutePermission = async (caseState) => {
    let permission = "";

    switch (caseState) {
      case "route":
        permission = "ROUTE_READ";
        break;
      case "traveler":
        permission = "TRAVELER_READ";
        break;
      default:
        break;
    }

    try {
      let res = await updateCustomerPermissionsRequest(customer.id, permission);
      console.log(res);
      setSwitchState((prevState) => ({
        ...prevState,
        [permission]: res.data?.customerPermissionType?.includes(permission),
      }));
    } catch (err) {}
  };
console.log(customerPerms)
  useEffect(() => {
    getCustomerPermissions();
  }, []);

  return (
    <TabList
      orientation="vertical"
      variant="scrollable"
      sx={{ marginTop: "1rem" }}
    >
      {customer.customerType === "School" &&
        sidebarItemsSchool.map((item, i) => (
          <Tab
            key={i}
            sx={{
              "&.MuiTab-root": {
                minHeight: "auto",
                justifyContent: "flex-start",
                minHeight: "2.5rem",
              },
            }}
            className={`${styles.sidebar_tab}`}
            iconPosition="start"
            icon={item.icon}
            component={Link}
            to={item.to}
            value={item.to}
            label={
              <div
                className={`${styles.label}  ${
                  item.to === tabValue && styles.selected
                }`}
              >
                <span>{item.text}</span>
                {item.count && (
                  <span className={styles.count}>{item.count}</span>
                )}
              </div>
            }
          />
        ))}
      {customer.customerType === "Company" &&
        sidebarItemsCompany.map((item, i) => (
          <Tab
            key={i}
            sx={{
              "&.MuiTab-root": {
                minHeight: "auto",
                justifyContent: "flex-start",
                minHeight: "2.5rem",
              },
            }}
            className={`${styles.sidebar_tab}`}
            iconPosition="start"
            icon={item.icon}
            component={Link}
            to={item.to}
            value={item.to}
            label={
              <div
                className={`${styles.label}  ${
                  item.to === tabValue && styles.selected
                }`}
              >
                <span>{item.text}</span>
                {item.count && (
                  <span className={styles.count}>{item.count}</span>
                )}
              </div>
            }
          />
        ))}
      <hr style={{ width: "90%", alignSelf: "center", marginBottom: "6px" }} />
      <Box sx={{ marginLeft: "18px" }}>
        <h3>İzinler</h3>
        <Box sx={{ marginTop: "6px" }}>
          <Box
            sx={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p>Yolcu Görme</p>
            <Switch
              checked={switchState.TRAVELER_READ}
              onChange={() => handleRoutePermission("traveler")}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p>Rota Görme</p>
            <Switch
              checked={switchState.ROUTE_READ}
              onChange={() => handleRoutePermission("route")}
            />
          </Box>
        </Box>
      </Box>
    </TabList>
  );
};
