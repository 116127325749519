import { ReactNode, useMemo } from 'react'
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import station from "../../../assets/markers/station.svg";
import station_red from "../../../assets/markers/station_red.svg";
type typeplan = "arrival" | "departure";

const StationMarker = ({ children, planType, position, eventHandlers, draggable }: { children?: ReactNode, planType?: typeplan, position: any, eventHandlers?: any, draggable?: any, key?: number | string }) => useMemo(() => {
    const icon = new L.Icon({
        iconUrl: planType === "departure" ? station : station_red,
        iconRetinaUrl: planType === "departure" ? station : station_red,
        iconAnchor: [16, 32],
        popupAnchor: undefined,
        shadowUrl: undefined,
        shadowSize: undefined,
        shadowAnchor: undefined,
        iconSize: [32, 32],
    });

    return (
        <Marker
            position={position}
            icon={icon}
            eventHandlers={eventHandlers || null}
            draggable={draggable}
        >
            {children}
        </Marker>
    )
}, [])

export default StationMarker;