import React from 'react';
import styles from './style.module.scss';
import { IconButton } from '@mui/material';
import { VisibilityRounded, VisibilityOffRounded } from '@mui/icons-material';

function MapDisplayButton({ icon, count, show, filterFunc, }) {

    return (
        <span className={styles.display_button}>
            {icon}
            {count}
            {
                filterFunc &&
                <IconButton
                    onClick={filterFunc}
                >
                    {
                        show ? <VisibilityOffRounded /> : <VisibilityRounded />
                    }
                </IconButton>
            }
        </span>
    )
}
export default MapDisplayButton;