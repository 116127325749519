import { useState } from "react";
import { Checkbox, List, ListItem, ListItemButton, ListItemText, ListSubheader, TextField } from "@mui/material";

const FilterList = ({ filter, setFilter, data, filterKey, getLabel, title }) => {
    const [search, setSearch] = useState("");

    const searchedManagers = data.filter(v => getLabel(v).toLocaleLowerCase().includes(search.toLocaleLowerCase()));

    const handleCheck = (id) => {
        if (filter[filterKey].some(v => v === id)) {
            setFilter(prev => ({ ...prev, [filterKey]: prev[filterKey].filter(v => v !== id) }));
        } else {
            setFilter(prev => ({ ...prev, [filterKey]: [...prev[filterKey], id] }));
        }
    }

    return (
        <List
            sx={{
                width: '100%',
                height: 150,
                overflow: "auto",
            }}
            subheader={
                <ListSubheader sx={{ p: 0, lineHeight: "1.5rem" }} component="div">
                    {title}
                </ListSubheader>
            }
        >
            <ListItem
                disablePadding
            >
                <TextField
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    variant="standard"
                    placeholder="Liste içinde arayın"
                    size="small"
                    fullWidth
                />
            </ListItem>
            {
                searchedManagers.map((value, i) => {
                    return (
                        <ListItem
                            key={i}
                            disablePadding
                        >
                            <ListItemButton
                                onClick={() => handleCheck(value.id)}
                                role={undefined}
                                dense
                                sx={{ p: "0 0.5rem" }}
                            >
                                <Checkbox
                                    checked={filter[filterKey].some(v => v === value.id)}
                                    edge="start"
                                    disableRipple
                                    size="small"
                                />
                                <ListItemText primary={getLabel(value)} />
                            </ListItemButton>
                        </ListItem>
                    );
                })
            }
        </List>
    )
}

export default FilterList;