import { useState, useEffect, useMemo, createRef } from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import TrackingNavigation from '../../../components/molecules/TrackingNavigation';
import VehicleMarkerForTracking from '../../../components/molecules/VehicleMarkerForTracking';
import WsHandler from '../../../components/WsHandler';
import { getVehiclesRequest } from '../../../api/controllers/vehicle-controller';
import styles from './servisler.module.scss';
import { Helmet } from 'react-helmet';
import Leaflet, { MAP_DEFAUTLS } from '../../../components/atoms/Leaflet';
import VehicleHistory from '../../../components/atoms/VehicleHistory';
import { getVehicleLogByVehicleIdRequest } from '../../../api/controllers/vehicle-log-controller';
import moment from 'moment';
import { toast } from 'react-toastify';

const Page = () => {
    const map = createRef();
    const [mapReady, setMapReady] = useState(false);
    const { vehicleId } = useParams();
    const navigate = useNavigate();
    const [vehicles, setVehicles] = useState([]);
    const [activeId, setActiveId] = useState(null);
    const [filters, setFilters] = useState([])
    const [vehicleHistory, setVehicleHistory] = useState({
        isLoading: false,
        startDate: null,
        endDate: new Date(),
        data: [],
        activeIndex: 0,
    })

    const getVehicles = async () => {
        let res = await getVehiclesRequest();
        if (res) {
            setVehicles(res.data);
            return res.data
        }
    }

    const filterVehicles = (status) => {
        navigate("/app/servisler")
        let x
        if (filters.some(v => v === status)) {
            x = filters.filter(v => v !== status)
        } else {
            x = filters
            x.push(status)
        }
        setFilters(x)
    }

    const filteredVehicles = useMemo(
        () => vehicles.filter(v => !filters.includes(v?.lastLog?.status)),
        [filters.length, vehicles],
    )

    const getVehicleHistory = async () => {
        setVehicleHistory(prev => ({ ...prev, isLoading: true }));
        try {
            let res = await getVehicleLogByVehicleIdRequest({ startDateTime: moment(vehicleHistory.startDate).toISOString(), endDateTime: moment(vehicleHistory.endDate).toISOString(), vehicleId: vehicleId });
            if (res) {
                if (res.data.length > 0) {
                    await setVehicleHistory(prev => ({ ...prev, activeIndex: 0, data: res.data }));
                    const list = await res.data.map(v => [v.latLng.lat, v.latLng.lng]);
                    await map.current.flyToBounds(list, { padding: [50, 50] });
                } else {
                    toast.error("Belirtilen zaman aralığında veri bulunamadı!")
                }
            }
        } catch (error) { }
        setVehicleHistory(prev => ({ ...prev, isLoading: false }));
    }

    const resetHistory = () => {
        setVehicleHistory({
            isLoading: false,
            startDate: null,
            endDate: new Date(),
            data: [],
            activeIndex: 0,
        });
    }

    useEffect(() => {
        let stompClient
        (async () => {
            let vehicleList = await getVehicles();
            stompClient = WsHandler({ vehicles: vehicleList, setVehicles });
        })()
        return () => {
            if (stompClient) {
                stompClient.deactivate();
                stompClient.disconnect();
                stompClient.forceDisconnect();
            }
        }
    }, []);

    useEffect(() => {
        if (mapReady) {
            if (vehicleId) {
                setActiveId(vehicleId);
            } else {
                setActiveId(null);
                let list = filteredVehicles.filter(v => v?.lastLog).map(v => [v.lastLog.latLng.lat, v.lastLog.latLng.lng]);
                if (list.length > 0) {
                    if (map.current.getZoom() === MAP_DEFAUTLS.zoom) {
                        map.current.flyToBounds(list, { padding: [50, 50] });
                    }
                } else {
                    map.current.flyTo(MAP_DEFAUTLS.center, MAP_DEFAUTLS.zoom);
                }
            }
        }
    }, [mapReady, vehicleId, filteredVehicles])

    return (
        <>
            <Helmet>
                <title>Canlı Takip | Damlatur Optimizasyon Sistemi</title>
            </Helmet>
            <Box>
                <TrackingNavigation
                    vehicles={filteredVehicles}
                    activeId={activeId}
                    filterVehicles={filterVehicles}
                    filters={filters}
                    vehicleHistory={vehicleHistory}
                    setVehicleHistory={setVehicleHistory}
                    getVehicleHistory={getVehicleHistory}
                    resetHistory={resetHistory}
                />
                <Leaflet setMapReady={setMapReady} ref={map}>
                    {
                        !vehicleHistory.data.length > 0 &&
                        filteredVehicles.map(v =>
                            <VehicleMarkerForTracking
                                key={v.id}
                                vehicle={v}
                                isActive={v.id == activeId}
                                navigate={navigate}
                            />
                        )
                    }
                    {
                        vehicleHistory.data.length > 0 && vehicleId &&
                        <VehicleHistory
                            vehicleHistory={vehicleHistory}
                            setVehicleHistory={setVehicleHistory}
                            resetHistory={resetHistory}
                        />
                    }
                </Leaflet>
            </Box>
            {
                !vehicleHistory.data.length > 0 &&
                <Button
                    onClick={() => navigate("/app/servisler/liste")}
                    className={`${styles.view_button} ${styles.map_view}`}
                    startIcon={<i className="bi bi-list-task"></i>}
                >
                    Servis ekle & görüntüle
                </Button>
            }
        </>
    );
}

export default Page;