import { Button, MenuItem, ListItemIcon } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomDataGrid from '../../../components/atoms/CustomDataGrid';
import PageLayout from '../../../components/templates/PageLayout';
import { getDriversRequest } from '../../../api/controllers/driver-controller';
import { useNavigate } from 'react-router-dom';
import { getDocumentTypesOfVehicleRequest } from '../../../api/controllers/document-type-controller';
import { getVehiclesPageableRequest, deleteVehicleRequest} from '../../../api/controllers/vehicle-controller';
import styles from './servisler.module.scss';
import { toast } from 'react-toastify';
import CustomMenu from '../../../components/molecules/CustomMenu';
import { DescriptionRounded, Info, ShareLocationRounded, Delete } from '@mui/icons-material';
import CreateVehicleModal from './servis-ekle';
import DocumentModal from './servis-dokuman';
import { Stack } from '@mui/system';
import Search from '../../../components/molecules/Search';
import ConfirmModal from '../../../components/atoms/DeleteConfirmModal';
import {useSearchParams} from 'react-router-dom';


const Page = () => {
    const navigate = useNavigate();

    //States



    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);
    const [pageable, setPageable] = useState({ page: 0, size: 10, total: 0 });

    const [createModal, setCreateModal] = useState(false);
    const [documentModal, setDocumentModal] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [selectedVehicleId, setSelectedVehicleId] = useState(null);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [filter, setFilter] = useState({
        search: "",
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const deleteId = searchParams.get("deleteId");

    //Araçları getirir
    const getVehicles = async () => {
        setIsTableLoading(true);
        try {
            let res = await getVehiclesPageableRequest(pageable, filter);
            if (res) {
                setData(res.data.content);
                setPageable({ ...pageable, total: res.data.totalElements });
            }
        } catch (error) { }
        setIsTableLoading(false);
    };


    //Dökümanları getirir
    const getDocumentTypes = async () => {
        let res = await getDocumentTypesOfVehicleRequest();
        if (res) {
            setDocumentTypes(res.data);
        }
    }


    //Sürücüleri getirir
    const getDrivers = async () => {
        let res = await getDriversRequest();
        if (res) {
            setDrivers(res.data);
        }
    }


    //Tablo sütunları
    const columns = [
        {
            field: 'licensePlate',
            headerName: "Plaka",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value : "-",
        },
        {
            field: 'brand',
            headerName: "Marka",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value : "-",
        },
        {
            field: 'model',
            headerName: "Model",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value : "-",
        },
        {
            field: 'driver',
            headerName: "Sürücü",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value.firstName + " " + value.lastName : "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            minWidth: 200,
            renderCell: ({ value }) => <CustomMenu>
                <MenuItem onClick={() => navigate(`/app/servisler/${value}`)}>
                    <ListItemIcon>
                        <ShareLocationRounded fontSize="small" />
                    </ListItemIcon>
                    Takip Et
                </MenuItem>
                <MenuItem onClick={async () => navigate(`/app/servisler/liste/detay/${value}`)}>
                    <ListItemIcon>
                        <Info fontSize="small" />
                    </ListItemIcon>
                    Detaylar
                </MenuItem>
                <MenuItem onClick={async () => {
                    if (documentTypes.length > 0) {
                        await setSelectedVehicleId(value);
                        setDocumentModal(true);
                    } else {
                        toast.warn(<p>Öncelikle <b>Belge Yönetimi</b> sayfasından belge tanımı ekleyin!</p>)
                    }
                }}>
                    <ListItemIcon>
                        <DescriptionRounded fontSize="small" />
                    </ListItemIcon>
                    Belgeler
                </MenuItem>
                <MenuItem onClick={() =>setSearchParams({deleteId:value})}>
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    Sil
                </MenuItem>
            </CustomMenu >
        },
    ]


    useEffect(() => {
        getDrivers();
        getDocumentTypes();
    }, [])

    useEffect(() => {
        getVehicles();
    }, [pageable.page, pageable.size, filter.search]);

    const deleteVehice = async (deleteId) => {
        let res = await deleteVehicleRequest(deleteId);
        if (res) {
            setData(data.filter(item => item.id?.toString() !== deleteId));
            setSearchParams(false);
            toast.success("Servis silindi");
        }
    }

    return (
        <>
            <PageLayout
                title="Servisler"
                buttons={[
                    {
                        onClick: () => navigate("/app/servisler/hakediş"),
                        variant: "outlined",
                        color: "secondary",
                        startIcon: <i className="bi bi-file-earmark-bar-graph"></i>,
                        text: "Hakediş",
                    },
                    {
                        onClick: () => navigate("/app/servisler/belge-yonetimi"),
                        variant: "outlined",
                        color: "secondary",
                        startIcon: <i className="bi bi-archive"></i>,
                        text: "Belge Yönetimi",
                    },
                    {
                        onClick: () => setCreateModal(true),
                        variant: "contained",
                        startIcon: <i className="bi bi-plus-lg"></i>,
                        text: "Servis Ekle",
                    }
                ]}>

                <Stack spacing={2}>
                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <Search
                            onChange={e => setFilter(prev => ({ ...prev, search: e }))}
                        />
                    </Stack>
                    <CustomDataGrid
                        data={data}
                        columns={columns}
                        loading={isTableLoading}
                        rowId={(params) => params?.id}
                        pageable={pageable}
                        setPageable={setPageable}
                    />
                </Stack>

                {/* Ekleme modalı */}
                {
                    createModal &&
                    <CreateVehicleModal
                        createModal={createModal}
                        setCreateModal={setCreateModal}
                        drivers={drivers}
                        getVehicles={getVehicles}
                        setDocumentModal={setDocumentModal}
                        setSelectedVehicleId={setSelectedVehicleId}
                        documentTypes={documentTypes}
                    />
                }

                {/* Döküman modalı */}
                {
                    documentModal &&
                    <DocumentModal
                        documentModal={documentModal}
                        setDocumentModal={setDocumentModal}
                        documentTypes={documentTypes}
                        selectedVehicleId={selectedVehicleId}
                        setSelectedVehicleId={setSelectedVehicleId}
                    />
                }
                
                {/* Delete modal */}
                {
                    deleteId && 
                    <ConfirmModal 
                        open={deleteId}
                        title={"Emin misiniz?"}
                        setOpen={setSearchParams}
                        message={"Servisi silmek istediğinize emin misiniz?"}
                        handleConfirm = {()=>deleteVehice(deleteId)}
                    />
                }

            </PageLayout >

            <Button
                onClick={() => navigate("/app/servisler")}
                className={`${styles.view_button} ${styles.list_view}`}
                startIcon={<i className="bi bi-map-fill"></i>}
            >
                Harita görünümüne geç
            </Button>
        </>
    )
}

export default Page;