import React, { useEffect } from 'react'
import { Alert, Box, Divider, TextField, Typography } from '@mui/material'
import styles from './style.module.scss'
import PlansSidebarLayout from '../../../templates/PlansSidebarLayout';
import { ArrowBackRounded } from '@mui/icons-material';
import TravelerCard from '../../../molecules/TravelerCard';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import { useOverTime } from '../../../../context/OverTimeContext';
import { createStationRequest } from '../../../../api/controllers/overtime-controller';

function CreateStation() {
    const navigate = useNavigate();
    const { planUuid, planType, mesaiId } = useParams();
    const { plan, setUpdateForm, overTime, stationForm, setStationForm, resetStationForm, getOverTime, setPlan, setTravelers } = useOverTime();
    const toast_1 = React.useRef(null);
    const toast_2 = React.useRef(null);

    const index = overTime?.plans?.findIndex((v) => v.uuid === planUuid && v.planDirectionType === planType.toUpperCase())

    const createStation = async () => {
        const form = { ...stationForm, travelersId: stationForm.travelersId.map(v => v.id) }
        try {
            let res = await createStationRequest(form, mesaiId, plan.id)
            if (res) {
                const overtime = await getOverTime(mesaiId)
                setUpdateForm((prev) => ({ ...prev, step: 2, plan: { ...prev.plan, stations: overtime.plans[index].stations } }))
                setPlan(overtime.plans[index])
                setTravelers(overtime.travelers)
                navigate(window.location.pathname.split("/station/olustur")[0])
            }
        } catch (error) { }
    }

    useEffect(() => {
        const notify_1 = () => toast_1.current = toast.info("Durağa yolcu ekleyip çıkarmak için yolcunun ikonuna tıklayın.", { autoClose: false });
        const update_1 = () => toast.update(toast_1.current, { autoClose: 10 });
        const notify_2 = () => toast_2.current = toast.info("Durağın konumunu değiştirmek için durak ikonuna tıklayarak sürükleyin.", { autoClose: false });
        const update_2 = () => toast.update(toast_2.current, { autoClose: 10 });
        notify_1();
        notify_2();
        return () => {
            update_1();
            update_2();
            resetStationForm();
        }
    }, [])

    return (
        <PlansSidebarLayout
            header={{
                iconButton: {
                    icon: <ArrowBackRounded fontSize="inherit" />,
                    props: {
                        onClick: () => {
                            navigate(window.location.pathname.split("/station/olustur")[0]);
                        },
                    }
                },
                title: {
                    text: "Durak Oluştur"
                }
            }}
            footer={{
                buttons: [
                    {
                        text: "İptal et",
                        props: {
                            variant: "outlined",
                            color: "secondary",
                            onClick: () => {
                                navigate(window.location.pathname.split("/station/olustur")[0]);
                            }
                        }
                    },
                    {
                        text: "Oluştur",
                        props: {
                            variant: "contained",
                            disabled: !(stationForm.title && stationForm.position.latitude && stationForm.position.longitude),
                            onClick: () => createStation(),
                        }
                    },
                ]
            }}
        >
            <Box className={styles.container}>
                <Box className={styles.element}>
                    <Typography variant="h6">
                        | Durak için isim belirleyin
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        placeholder="Durak ismini yazın"
                        value={stationForm.title || ""}
                        onChange={e => setStationForm(prev => ({ ...prev, title: e.target.value }))}
                    />
                </Box>
                <Divider flexItem />
                <Box className={styles.element}>
                    <Typography variant="h6">
                        | Durağa bağlı yolcular
                    </Typography>
                    {
                        stationForm.travelersId.length > 0 ?
                            stationForm.travelersId.map(v =>
                                <TravelerCard
                                    key={v.id}
                                    traveler={v}
                                    stationPosition={{ lat: stationForm.lat, lng: stationForm.lng }}
                                />
                            )
                            :
                            <Alert severity="warning">
                                Durağa bağlı yolcu bulunamadı!
                            </Alert>
                    }
                </Box>
            </Box>
        </PlansSidebarLayout>
    )
}

export default React.memo(CreateStation)