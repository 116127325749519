import React, { useEffect, useState } from "react";
import { Alert, Box, Divider, TextField, Typography } from "@mui/material";
import styles from "./style.module.scss";
import { usePlans } from "../../../../context/PlansContext";
import PlansSidebarLayout from "../../../templates/PlansSidebarLayout";
import { ArrowBackRounded } from "@mui/icons-material";
import TravelerCard from "../../../molecules/TravelerCard";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { createStationForEmployeeRequest } from "../../../../api/controllers/station-controller";
import { getManagerCustomerPermissionsRequest } from "../../../../api/controllers/manager-controller";
import { useCustomer } from "../../../../context/CustomerContext";
import { useAuth } from "../../../../context/AuthContext";

function CreateStation() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { workTimeId } = useParams();
  const {
    plan,
    setPlan,
    setUpdateForm,
    stationForm,
    setStationForm,
    resetStationForm,
    getStations,
    getTravelers,
  } = usePlans();
  const toast_1 = React.useRef(null);
  const toast_2 = React.useRef(null);
  const { role } = useCustomer();
  const { customerId } = useParams();
  
  const createStation = async () => {
    let sequence =
      plan.stationList.sort((a, b) => b.sequence - a.sequence)[0]?.sequence +
        1 || 1;
    let form = { ...stationForm, sequence: sequence };
    let res = await createStationForEmployeeRequest(plan.id, form);
    if (res) {
      navigate(window.location.pathname.split("/station/olustur")[0]);
      resetStationForm();
      const stations = await getStations(plan.id);
      setPlan((prev) => ({ ...prev, stationList: stations }));
      setUpdateForm((prev) => ({
        ...prev,
        plan: { ...prev.plan, stationList: stations },
      }));
      getTravelers(workTimeId);
    }
  };

  useEffect(() => {
    const notify_1 = () =>
      (toast_1.current = toast.info(
        "Durağa yolcu ekleyip çıkarmak için yolcunun ikonuna tıklayın.",
        { autoClose: false }
      ));
    const update_1 = () => toast.update(toast_1.current, { autoClose: 10 });
    const notify_2 = () =>
      (toast_2.current = toast.info(
        "Durağın konumunu değiştirmek için durak ikonuna tıklayarak sürükleyin.",
        { autoClose: false }
      ));
    const update_2 = () => toast.update(toast_2.current, { autoClose: 10 });
    notify_1();
    notify_2();
    return () => {
      update_1();
      update_2();
      resetStationForm();
    };
  }, []);

  return (
    <PlansSidebarLayout
      header={{
        iconButton: {
          icon: <ArrowBackRounded fontSize="inherit" />,
          props: {
            onClick: () => {
              navigate(window.location.pathname.split("/station/olustur")[0]);
            },
          },
        },
        title: {
          text: "Durak Oluştur",
        },
      }}
      footer={{
        buttons: [
          {
            text: "İptal et",
            props: {
              variant: "outlined",
              color: "secondary",
              onClick: () => {
                navigate(window.location.pathname.split("/station/olustur")[0]);
              },
            },
          },
          {
            text: "Oluştur",
            props: {
              variant: "contained",
              disabled: !(
                stationForm.title &&
                stationForm.lat &&
                stationForm.lng
              ),
              onClick: () => createStation(),
            },
          },
        ],
      }}
    >
      <Box className={styles.container}>
        <Box className={styles.element}>
          <Typography variant="h6">| Durak için isim belirleyin</Typography>
          <TextField
            fullWidth
            size="small"
            placeholder="Durak ismini yazın"
            value={stationForm.title || ""}
            onChange={(e) =>
              setStationForm((prev) => ({ ...prev, title: e.target.value }))
            }
          />
        </Box>
        <Divider flexItem />
        <Box className={styles.element}>
          <Typography variant="h6">| Durağa bağlı yolcular</Typography>
          {stationForm.travelerIds.length > 0 ? (
            stationForm.travelerIds.map((v) => (
              <TravelerCard
                key={v.id}
                traveler={v}
                stationPosition={{ lat: stationForm.lat, lng: stationForm.lng }}
              />
            ))
          ) : (
            <Alert severity="warning">Durağa bağlı yolcu bulunamadı!</Alert>
          )}
        </Box>
      </Box>
    </PlansSidebarLayout>
  );
}

export default React.memo(CreateStation);
