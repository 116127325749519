import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getWorkTimesByCustomerIdRequest } from '../../../../../../api/controllers/work-time-controller';
import { saveStudentListRequest, uploadStudentsExcelRequest } from '../../../../../../api/controllers/student-controller';
import { Alert, Autocomplete, TextField } from "@mui/material";
import { PatternFormat } from "react-number-format";
import PhoneTextfield from "../../../../../atoms/PhoneTextfield";
import { ProvinceInput, DistrictInput, NeighborhoodInput } from '../Utils';
import { getProvincesRequest } from '../../../../../../api/controllers/addresses-controller';
import ActionPageTitle from '../../../../../atoms/ActionPageTitle';
import ExcelUpload from '../../../../../atoms/ExcelUpload';
import { DataGrid } from "@mui/x-data-grid";
import CreateParent from "./CreateParent";
import template from '../../../../../../assets/templates/Ogrenci_Sablon.xlsx';
import AssignWorkTimeMultiple from "../../../../../molecules/AssignWorkTimeMultiple";

function CreateMultiple({ customer, getParents }) {

    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [createParentModal, setCreateParentModal] = useState(false);
    const [workTimes, setWorkTimes] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [uploadResult, setUploadResult] = useState({
        uploaded: false,
        content: [],
    });

    useEffect(() => {
        (async () => {
            let res = await getWorkTimesByCustomerIdRequest(customer.id);
            if (res) {
                setWorkTimes(res.data);
            }
        })()
    }, [])

    const handleUpload = async (file) => {
        const alert = toast.loading("Dosya yükleniyor...")
        const formData = new FormData();
        formData.append('result', file);
        try {
            let res = await uploadStudentsExcelRequest(formData);
            if (res && res.status === 200) {
                toast.update(alert, { render: "Dosya başarıyla yüklendi", type: "success", isLoading: false, autoClose: true });
                let x = res.data.map((val, i) => ({ ...val, index: i }));
                uploadResult.uploaded = true;
                uploadResult.content = x;
                setUploadResult({ ...uploadResult });
            }
        } catch (error) {
            toast.update(alert, { render: "Dosya yüklenemedi", type: "error", isLoading: false, autoClose: true });
        }
    }

    const columns = [
        {
            field: 'index',
            headerName: '#',
            valueGetter: ({ value }) => value + 1,
            width: 30
        },
        {
            field: 'firstName',
            headerName: 'İsim',
            minWidth: 150,
            renderCell: ({ value, row }) => (
                <TextField
                    fullWidth
                    size="small"
                    value={value}
                    onChange={e => {
                        uploadResult.content[row.index].firstName = e.target.value;
                        setUploadResult({ ...uploadResult });
                    }}
                    placeholder="İsim"
                    error={!value}
                />
            )
        },
        {
            field: 'lastName',
            headerName: 'Soyisim',
            minWidth: 150,
            renderCell: ({ value, row }) => (

                <TextField
                    fullWidth
                    size="small"
                    value={value}
                    onChange={e => {
                        uploadResult.content[row.index].lastName = e.target.value;
                        setUploadResult({ ...uploadResult });
                    }}
                    placeholder="Soyisim"
                    error={!value}
                />
            )
        },
        {
            field: 'idNum',
            headerName: 'T.C. Kimlik Numarası',
            minWidth: 150,
            renderCell: ({ value, row }) => (
                <PatternFormat
                    fullWidth
                    format="###########"
                    placeholder="T.C. Kimlik Numarası"
                    size="small"
                    value={value}
                    onValueChange={({ value }) => {
                        uploadResult.content[row.index].idNum = value;
                        setUploadResult({ ...uploadResult });
                    }}
                    customInput={TextField}
                // error={!value}
                />
            )
        },
        {
            field: 'classRoom',
            headerName: 'Sınıf',
            minWidth: 150,
            renderCell: ({ value, row }) => (
                <TextField
                    fullWidth
                    size="small"
                    value={value}
                    onChange={e => {
                        uploadResult.content[row.index].classRoom = e.target.value;
                        setUploadResult({ ...uploadResult });
                    }}
                    placeholder="Sınıf"
                    error={!value}
                />
            )
        },
        {
            field: 'veli_adi',
            headerName: 'Veli İsmi',
            minWidth: 150,
            renderCell: ({ row }) => (
                <TextField
                    fullWidth
                    size="small"
                    value={row.parent.firstName}
                    onChange={e => {
                        uploadResult.content[row.index].parent.firstName = e.target.value;
                        setUploadResult({ ...uploadResult });
                    }}
                    placeholder="Veli İsmi"
                    error={!row.parent.firstName}
                />
            )
        },
        {
            field: 'veli_soyadi',
            headerName: 'Veli Soyismi',
            minWidth: 150,
            renderCell: ({ row }) => (
                <TextField
                    fullWidth
                    size="small"
                    value={row.parent.lastName}
                    onChange={e => {
                        uploadResult.content[row.index].parent.lastName = e.target.value;
                        setUploadResult({ ...uploadResult });
                    }}
                    placeholder="Veli Soyismi"
                    error={!row.parent.lastName}
                />
            )
        },
        {
            field: 'veli_telefon',
            headerName: 'Veli Telefon',
            minWidth: 200,
            renderCell: ({ row }) => (
                <PhoneTextfield
                    size="small"
                    phone={row.parent.phone}
                    onChange={({ value }) => {
                        uploadResult.content[row.index].parent.phone.number = value;
                        setUploadResult({ ...uploadResult });
                    }}
                    error={!row.parent.phone.number}
                />
            )
        },
        {
            field: 'veli_email',
            headerName: 'Veli Email',
            minWidth: 200,
            renderCell: ({ row }) => (
                <TextField
                    fullWidth
                    size="small"
                    value={row.parent.email}
                    onChange={e => {
                        uploadResult.content[row.index].parent.email = e.target.value;
                        setUploadResult({ ...uploadResult });
                    }}
                    placeholder="Veli Email"
                /* error={!row.parent.email} */
                />
            )
        },
        {
            field: 'workTimeId',
            headerName: 'Vardiya',
            minWidth: 200,
            renderCell: ({ value, row }) => (
                <Autocomplete
                    fullWidth
                    options={workTimes}
                    value={workTimes.find(w => w.id === value) || null}
                    onChange={(event, newValue) => {
                        uploadResult.content[row.index].workTimeId = newValue?.id || null;
                        setUploadResult({ ...uploadResult });
                    }}
                    getOptionLabel={option => `${option.title} (${option.startTime.slice(0, 5)} - ${option.endTime.slice(0, 5)})`}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            size="small"
                            placeholder="Vardiya"
                            error={!value}
                        />}
                />
            )
        },
        {
            field: 'il',
            headerName: 'İl',
            minWidth: 200,
            renderCell: ({ row }) => <ProvinceInput value={row.address} row={row} provinces={provinces} uploadResult={uploadResult} setUploadResult={setUploadResult} />,
        },
        {
            field: 'ilce',
            headerName: 'İlçe',
            minWidth: 200,
            renderCell: ({ row }) => <DistrictInput value={row.address} row={row} provinces={provinces} uploadResult={uploadResult} setUploadResult={setUploadResult} />,
        },
        {
            field: 'mahalle',
            headerName: 'Mahalle',
            minWidth: 200,
            renderCell: ({ row }) => <NeighborhoodInput value={row.address} row={row} provinces={provinces} uploadResult={uploadResult} setUploadResult={setUploadResult} />,
        },
        {
            field: 'cadde',
            headerName: 'Cadde / Sokak',
            minWidth: 200,
            renderCell: ({ row }) => (
                <TextField
                    fullWidth
                    size="small"
                    value={row.address.street}
                    onChange={e => {
                        uploadResult.content[row.index].address.street = e.target.value;
                        setUploadResult({ ...uploadResult });
                    }}
                    placeholder="Cadde / Sokak"
                    error={!row.address.street}
                />
            )
        },
        {
            field: 'bina',
            headerName: 'Bina No',
            minWidth: 50,
            renderCell: ({ row }) => (
                <TextField
                    fullWidth
                    size="small"
                    value={row.address.buildingInformation}
                    onChange={e => {
                        uploadResult.content[row.index].address.buildingInformation = e.target.value;
                        setUploadResult({ ...uploadResult });
                    }}
                    placeholder="Bina No"
                    error={!row.address.buildingInformation}
                />
            )
        },
        {
            field: 'adres_detayi',
            headerName: 'Adres Detayı',
            minWidth: 200,
            renderCell: ({ row }) => (
                <TextField
                    fullWidth
                    size="small"
                    value={row.address.fullAddress}
                    onChange={e => {
                        uploadResult.content[row.index].address.fullAddress = e.target.value;
                        setUploadResult({ ...uploadResult });
                    }}
                    placeholder="Adres Detayı"
                />
            )
        },
    ];

    const onSubmit = async () => {
        setIsSubmitting(true);
        let checkNull = uploadResult.content.find(v => !v.workTimeId || !v.address.province || !v.address.district || !v.address.neighborhood);
        if (checkNull) {
            toast((checkNull.index + 1) + ". satırda bulunan gerekli alanları doldurunuz!", { type: "error" });
        } else {
            try {
                let res = await saveStudentListRequest(uploadResult.content);
                if (res) {
                    toast("Yolcular başarıyle eklendi", { type: "success" });
                    navigate(`/app/musteriler/${customer.id}/yolcular`);
                }
            } catch (error) { }
        }
        setIsSubmitting(false);
    }

    useEffect(() => {
        (async () => {
            let res = await getProvincesRequest();
            if (res) {
                setProvinces(res.data);
            }
        })()
    }, [])

    return (
        <>
            <ActionPageTitle
                title="Toplu Yolcu Ekle"
                buttonText={uploadResult.uploaded ? "Kaydet" : "Şablonu İndir"}
                buttonProps={{
                    loading: isSubmitting,
                    onClick: uploadResult.uploaded ? onSubmit : () => window.open(template, '_blank'),
                    variant: uploadResult.uploaded ? "contained" : "outlined",
                    color: uploadResult.uploaded ? "primary" : "success",
                }}
                extraButton={uploadResult.uploaded && <AssignWorkTimeMultiple workTimes={workTimes} uploadResult={uploadResult} setUploadResult={setUploadResult} />}
            />
            {
                !uploadResult.uploaded &&
                <>
                    <Alert severity="info" color="info" sx={{ my: "1.5rem" }}>
                        Yolcuların toplu olarak sorunsuz eklenebilmesi için üst tarafta bulunan şablona uygun yüklemeniz gerekmektedir.
                    </Alert>
                    <ExcelUpload handleUpload={handleUpload} />
                </>
            }
            {
                uploadResult.uploaded &&
                <>
                    <DataGrid
                        rows={uploadResult.content}
                        columns={columns}
                        getRowId={row => row.index}
                        sx={{
                            minHeight: "60vh",
                            overflowX: "auto",
                            mt: "1rem",
                        }}
                    />
                    <CreateParent
                        createParentModal={createParentModal}
                        setCreateParentModal={setCreateParentModal}
                        getParents={getParents}
                    />
                </>
            }
        </>
    )
}

export default CreateMultiple;