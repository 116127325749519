import { useEffect, useLayoutEffect, useState } from 'react';
import { Box, Checkbox, Divider, FormControlLabel, Grid, TextField } from '@mui/material';
import styles from './giris-yap.module.scss';
import { useFormik } from 'formik';
import { getMeRequest, loginWithPasswordRequest } from '../api/controllers/account-controller';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import SuspenseFallback from '../components/templates/SuspenseFallback';
import { LoadingButton } from '@mui/lab';
import { BASE_URL } from '../api/ApiProvider';
import { toast } from 'react-toastify';

const Page = () => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const mobileToken = searchParams.get("mobileToken");


    const formik = useFormik({
        initialValues: {
            password: "",
            rememberMe: false,
            username: ""
        },
        validationSchema: yup.object({
            username: yup
                .string('Kullanıcı adı girin')
                .required('Kullanıcı adı gereklidir'),
            password: yup
                .string('Şifre girin')
                .min(6, 'Şifre en az 6 haneli olmalıdır')
                .required('Şifre gereklidir'),
        }),
        onSubmit: values => {
            onSubmit(values);
        },
    });

    const onSubmit = async (values) => {
        setIsSubmitting(true);
        try {
            let res = await loginWithPasswordRequest(values);
            if (res && res.status === 200) {
                if (res.data.role === "ADMIN") {
                    navigate("/app/servisler");
                } else if (res.data.role === "MANAGER" || res.data.role === "CUSTOMER") {
                    navigate("/app/guzergahlar");
                }
                formik.resetForm();
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    useEffect(() => {
        const checkAuthentication = async () => {
            setIsLoading(true);
            try {
                let res = await getMeRequest({ hideMessage: true });
                if (res && res.status === 200) {
                    if (res.data.role === "ADMIN") {
                        navigate("/app/servisler");
                    } else if (res.data.role === "MANAGER" || res.data.role === "CUSTOMER") {
                        navigate("/app/guzergahlar");
                    }
                }
            } catch (error) { }
            setTimeout(() => {
                
                setIsLoading(false);
            }, 250);
        }
        checkAuthentication()
    }, [])
    

    useLayoutEffect(() => {
        localStorage.setItem("token", mobileToken);

    }, [])

    return (
        isLoading ?
            <SuspenseFallback /> :
            <Grid container className={styles.wrapper}>
                <Grid item xs={12} sm={7} md={8} xl={9} className={styles.bg_image} sx={{ backgroundImage: `url(${BASE_URL + "/backgroundphoto/getData/selected"})` }} />
                <Grid item xs={12} sm={5} md={4} xl={3}>
                    <Box className={styles.form_wrapper}>
                        <img src="../../assets/images/logo.svg" alt="Damlatur" />
                        <Divider style={{ width: "100%" }} />
                        <form
                            className={styles.form_container}
                            onSubmit={formik.handleSubmit}
                        >
                            <TextField
                                name="username"
                                label="Kullanıcı adı"
                                placeholder="Kullanıcı adınız"
                                fullWidth
                                autoFocus
                                onChange={formik.handleChange}
                                value={formik.values.username}
                                error={formik.touched.username && formik.errors.username}
                                helperText={formik.touched.username && formik.errors.username}
                            />
                            <TextField
                                name="password"
                                label="Şifre"
                                placeholder="Şifreniz"
                                type="password"
                                fullWidth
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                error={formik.touched.password && formik.errors.password}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                            <LoadingButton
                                loading={isSubmitting}
                                type="submit"
                                fullWidth
                                variant="contained"
                            >
                                Giriş yap
                            </LoadingButton>
                            <FormControlLabel
                                name="rememberMe"
                                onChange={formik.handleChange}
                                control={<Checkbox checked={formik.values.rememberMe} />}
                                label="Beni hatırla"
                            />
                        </form>
                    </Box>
                </Grid>
            </Grid >
    )
}

export default Page;