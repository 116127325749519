import { useState } from "react";
import { Grid, Box, IconButton } from "@mui/material";
import styles from "../tabs.module.scss";
import { useCustomer } from "../../../../context/CustomerContext";
import { useAuth } from "../../../../context/AuthContext";
import Subtitle from "../../../atoms/Subtitle";
import Label from "../../../atoms/Label";
import { EditRounded } from "@mui/icons-material";
import EditManager from "./EditManager";

export default function Info(props) {
  const { customer } = useCustomer();
  const { currentUser } = useAuth();
  const [editManagerModal, setEditManagerModal] = useState(false);

  const customerData = [
    {
      icon: <i className="bi bi-person-fill" />,
      title: "Yetkili İsmi",
      value: `${customer?.firstName} ${customer?.lastName}`,
    },
    {
      icon: <i className="bi bi-telephone-fill" />,
      title: "Yetkili Telefon",
      value: `${customer?.phone?.areaCode} ${customer?.phone?.number}`,
    },
    {
      icon: <i className="bi bi-envelope-fill" />,
      title: "Email Adresi",
      value: customer?.email,
    },
    {
      icon: <i className="bi bi-building" />,
      title: "Kullanıcı İsmi",
      value: customer?.username,
    },
    {
      icon: <i className="bi bi-building" />,
      title: "Vergi Numarası",
      value: customer?.taxNumber,
    },
    {
      icon: <i className="bi bi-building" />,
      title: "Vergi Dairesi",
      value: customer?.taxAdministration,
    },
    {
      icon: <i className="bi bi-geo-alt-fill" />,
      title: "Adres",
      value: `
                ${customer?.address?.neighborhood || "-"} 
                ${customer?.address?.street || "-"} Cd/Sk. 
                No: ${customer?.address?.buildingInformation || "-"} 
                ${customer?.address?.district || "-"}/ 
                ${customer?.address?.province || "-"}
                `,
      grid: 12,
    },
  ];

  const managerData = [
    {
      icon: <i className="bi bi-person-fill" />,
      title: "Yönetici İsmi",
      value: `${customer?.manager?.firstName} ${customer?.manager?.lastName}`,
    },
    {
      icon: <i className="bi bi-telephone-fill" />,
      title: "Yönetici Telefon",
      value: `${customer?.manager?.phone?.areaCode} ${customer?.manager?.phone?.number}`,
    },
  ];

  return (
    <Box className={styles.detail_page}>
      <Box>
        <Subtitle title="Müşteri Bilgileri" />
        <Grid container item xs={12} md={10} xl={8} spacing={3} p={1}>
          {customerData.map((val, i) => (
            <Grid key={i} item xs={12} md={val.grid | 6}>
              <Label title={val.title} icon={val.icon} value={val.value} />
            </Grid>
          ))}
        </Grid>
      </Box>

    
    </Box>
  );
}
