import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Grid, Box, Typography, Stepper, StepLabel, Step, Button, TextField, Autocomplete } from '@mui/material';
import PageLayout from '../../../components/templates/PageLayout';
import { createCustomerRequest } from '../../../api/controllers/customer-controller';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import styles from './musteriler.module.scss';
import { getManagersRequest } from '../../../api/controllers/manager-controller';
import PhoneTextfield from '../../../components/atoms/PhoneTextfield';
import { NumericFormat } from 'react-number-format';
import { LoadingButton } from '@mui/lab';
import { useAuth } from "../../../context/AuthContext";
import AddressModal from "../../../components/molecules/AddressModal";

const Page = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { currentUser } = useAuth();
    const { role } = currentUser;
    let validationSchema = null;

    if (activeStep === 1) {
        validationSchema = yup.object({
            title: yup
                .string()
                .required('Bu alan gereklidir'),
            username: yup
                .string()
                .required('Bu alan gereklidir'),
            taxNumber: yup
                .string()
                .max(11, 'Vergi numarası 11 haneden fazla olamaz')
                .min(10, 'Vergi numarası 10 haneden kısa olamaz'),
            address: yup.object({
                province: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                district: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                neighborhood: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                street: yup
                    .string()
                    .required('Bu alan gereklidir'),
                buildingInformation: yup
                    .string()
                    .required('Bu alan gereklidir'),
            }),
            password: yup
                .string()
                .required('Bu alan gereklidir')
                .min(6, 'Şifre en az 6 haneli olmalıdır'),
            rePassword: yup
                .string()
                .required('Bu alan gereklidir')
                .oneOf([yup.ref('password'), null], 'Şifreler uyuşmuyor'),
        })
    } else if (activeStep === 2) {
        validationSchema = yup.object({
            firstName: yup
                .string()
                .required('Bu alan gereklidir'),
            lastName: yup
                .string()
                .required('Bu alan gereklidir'),
            phone: yup.object({
                number: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .length(10, 'Telefon numarası 10 haneli olmalıdır'),
            }),
            email: yup
                .string()
                .required('Bu alan gereklidir')
                .email('Email adresini doğru formatta girin'),
        })
    }

    const formik = useFormik({
        initialValues: {
            address: {
                buildingInformation: "",
                country: "",
                district: null,
                fullAddress: "",
                neighborhood: null,
                position: {
                    latitude: null,
                    longitude: null
                },
                province: "Denizli",
                street: ""
            },
            customerType: "School",
            email: "",
            firstName: "",
            lastName: "",
            password: "",
            rePassword: "",
            phone: {
                areaCode: "+90",
                countryCode: "",
                number: ""
            },
            taxAdministration: "",
            taxNumber: "",
            title: "",
            username: "",
            managerId: null,
        },
        validationSchema: validationSchema,
        onSubmit: () => onSubmit(),
    });

    const steps = [
        {
            title: "Müşteri tipini seçin",
            label: 'Müşteri Tipi',
            left_button_func: () => window.history.back(),
            left_button_text: "İptal et",
            right_button_text: "Devam et",
        },
        {
            title: "Müşteriye ait bilgileri girin",
            label: 'Müşteri Bilgileri',
            left_button_func: () => handleBack(),
            left_button_text: "Geri",
            right_button_text: "Devam et",
        },
        {
            title: "Müşterinin yetkilisine ait bilgileri girin",
            label: 'Yetkili Kişi Bilgileri',
            left_button_func: () => handleBack(),
            left_button_text: "Geri",
            right_button_text: "Devam et",
        },
        role === "ADMIN" && {
            title: "Atamak istediğiniz yöneticiyi seçin",
            label: 'Yönetici Bilgisi',
            left_button_func: () => handleBack(),
            left_button_text: "Geri",
            right_button_text: "Kaydet",
        },
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            if ((role === "ADMIN" && activeStep === 3) || (role === "MANAGER" && activeStep === 2)) {
                let res = await createCustomerRequest(formik.values);
                if (res && res.status === 200) {
                    navigate("/app/musteriler")
                    formik.resetForm();
                    toast.success("Müşteri kaydedildi")
                }
            } else {
                handleNext();
            }
        } catch (error) {
        }
        setIsSubmitting(false);
    }

    return (
        <PageLayout
            title="Müşteri Ekle"
            small
        >
            <form autoComplete="off" onSubmit={formik.handleSubmit} >
                <Grid container spacing={3} >
                    <Grid item xs={12} md={8}>
                        <Typography
                            variant="h6"
                            sx={{ marginBottom: "1.5rem" }}
                        >| {steps[activeStep].title}</Typography>
                        {
                            activeStep === 0 &&
                            <CustomerType formik={formik} />
                        }
                        {
                            activeStep === 1 &&
                            <CustomerDetail formik={formik} />
                        }
                        {
                            activeStep === 2 &&
                            <CustomerStaffInfo formik={formik} />
                        }
                        {
                            activeStep === 3 &&
                            <AssignedManager formik={formik} />
                        }
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <Box className={styles.step_wrapper}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {
                                    steps.map((step, i) => (
                                        step &&
                                        <Step key={i}>
                                            <StepLabel>
                                                {step.label}
                                            </StepLabel>
                                        </Step>
                                    ))
                                }
                            </Stepper>
                        </Box>
                        <Box display="flex" gap="1rem">
                            <Button
                                type="button"
                                onClick={steps[activeStep].left_button_func}
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                sx={{ flex: 1 }}
                            >
                                {steps[activeStep].left_button_text}
                            </Button>
                            <LoadingButton
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{ flex: 1 }}
                            >
                                {steps[activeStep].right_button_text}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </PageLayout >
    )
}

export default Page;


const CustomerType = ({ formik }) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <button
                    type="button"
                    onClick={() => formik.setFieldValue("customerType", "School")}
                    className={`${styles.option_button} ${formik.values.customerType === "School" && styles.active}`}>
                    <i className="bi bi-mortarboard-fill"></i>
                    Okul
                </button>
            </Grid>
            <Grid item xs={12} md={4}>
                <button
                    type="button"
                    onClick={() => formik.setFieldValue("customerType", "Company")}
                    className={`${styles.option_button} ${formik.values.customerType === "Company" && styles.active}`}>
                    <i className="bi bi-briefcase-fill"></i>
                    İş yeri
                </button>
            </Grid>
        </Grid>
    )
}

const CustomerDetail = ({ formik }) => {
    return (
        <Grid container spacing={3} >
            <Grid item xs={12} md={6}>
                <TextField
                    name="title"
                    label="Ünvan"
                    placeholder="Şirket ünvanını girin"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    error={formik.touched.title && formik.errors.title}
                    helperText={formik.touched.title && formik.errors.title}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="username"
                    label="Kullanıcı Adı"
                    placeholder="Kullanıcı adını girin"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    error={formik.touched.username && formik.errors.username}
                    helperText={formik.touched.username && formik.errors.username}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumericFormat
                    name="taxNumber"
                    label="Vergi Numarası"
                    placeholder="Vergi numarasını girin"
                    fullWidth
                    onValueChange={({ value }) => formik.setFieldValue("taxNumber", value)}
                    value={formik.values.taxNumber}
                    customInput={TextField}
                    error={formik.touched.taxNumber && formik.errors.taxNumber}
                    helperText={formik.touched.taxNumber && formik.errors.taxNumber}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="taxAdministration"
                    label="Vergi Dairesi"
                    placeholder="Vergi dairesini girin"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.taxAdministration}
                    error={formik.touched.taxAdministration && formik.errors.taxAdministration}
                    helperText={formik.touched.taxAdministration && formik.errors.taxAdministration}
                />
            </Grid>
            <Grid item xs={12} >
                <AddressModal formik={formik} customer />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    type="password"
                    name="password"
                    label="Şifre"
                    placeholder="Şifre girin"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={formik.touched.password && formik.errors.password}
                    helperText={formik.touched.password && formik.errors.password}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    type="password"
                    name="rePassword"
                    label="Şifre (Tekrar)"
                    placeholder="Şifreyi tekrar girin"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.rePassword}
                    error={formik.touched.rePassword && formik.errors.rePassword}
                    helperText={formik.touched.rePassword && formik.errors.rePassword}
                />
            </Grid>
        </Grid>
    )
}

const CustomerStaffInfo = ({ formik }) => {
    return (
        <Grid container spacing={3} >
            <Grid item xs={12} md={6}>
                <TextField
                    name="firstName"
                    label="Yetkili İsmi"
                    placeholder="Yetkili ismini girin"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    error={formik.touched.firstName && formik.errors.firstName}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="lastName"
                    label="Yetkili Soyismi"
                    placeholder="Yetkili soyismini girin"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    error={formik.touched.lastName && formik.errors.lastName}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <PhoneTextfield
                    name="phone.number"
                    phone={formik.values.phone}
                    onChange={({ value }) => formik.setFieldValue("phone.number", value)}
                    error={formik.touched.phone?.number && formik.errors.phone?.number}
                    helperText={formik.touched.phone?.number && formik.errors.phone?.number}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="email"
                    label="Email Adresi"
                    placeholder="Email adresini girin"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.touched.email && formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                />
            </Grid>
        </Grid>
    )
}

const AssignedManager = ({ formik }) => {
    const [managers, setManagers] = useState([]);

    useEffect(() => {
        (async () => {
            let res = await getManagersRequest();
            if (res) {
                setManagers(res.data);
            }
        })()
    }, [])

    return (
        <Grid container spacing={3} >
            <Grid item xs={12} md={6}>
                <Autocomplete
                    options={managers}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label='Yönetici'
                            fullWidth
                        />}
                    getOptionLabel={(option) => option.firstName + " " + option.lastName + ` (${option.customerCount})`}
                    value={managers.find(v => v.id === formik.values.managerId)}
                    onChange={(event, newValue) => {
                        formik.setFieldValue("managerId", newValue?.id || null);
                    }}
                />
            </Grid>
        </Grid>
    )
}