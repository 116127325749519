import { useEffect } from 'react';
import styles from './style.module.scss';
import { Box, Autocomplete, TextField, IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import MapDisplayButton from '../../atoms/MapDisplayButton';
import moving_icon from '../../../assets/ignations/MOVING.svg';
import started_icon from '../../../assets/ignations/STARTED.svg';
import stopped_icon from '../../../assets/ignations/STOPPED.svg';
import CustomPopup from '../CustomPopup';
import { CloseRounded, ShareLocationRounded } from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';

const TrackingNavigation = ({ vehicles, filters, filterVehicles, vehicleHistory, setVehicleHistory, getVehicleHistory, resetHistory }) => {
    const navigate = useNavigate();
    const { vehicleId } = useParams();
    const getDisplayCount = (status) => vehicles.filter(v => v?.lastLog?.status === status).length
    const selectedVehicle = vehicles.find(v => v.id === parseInt(vehicleId));
    const display_buttons = [
        {
            icon: moving_icon,
            status: "MOVING",
        },
        {
            icon: started_icon,
            status: "STARTED"
        },
        {
            icon: stopped_icon,
            status: "STOPPED"
        },
    ]

    useEffect(() => {
        return () => {
            resetHistory();
        }
    }, [])

    return (
        <Box component="nav" className={styles.navbar}>
            <Autocomplete
                className={styles.autocomplete}
                size="small"
                options={vehicles}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label='Servis seçin'
                        placeholder='Listeden seçin'
                    />}
                getOptionLabel={(option) => option.licensePlate}
                value={selectedVehicle || null}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                onChange={(event, newValue) => {
                    if (newValue) {
                        navigate(`/app/servisler/${newValue.id}`)
                    } else {
                        navigate(`/app/servisler`)
                    }
                }}
            />
            {
                !vehicleId &&
                display_buttons.map((v, i) =>
                    <MapDisplayButton
                        key={i}
                        icon={<img src={v.icon} alt="" />}
                        count={getDisplayCount(v.status)}
                        filterFunc={() => filterVehicles(v.status)}
                        show={filters.some(f => f === v.status)}
                    />
                )
            }
            {
                vehicleId &&
                <CustomPopup
                    buttonText={vehicleHistory.startDate && vehicleHistory.endDate ? `${moment(vehicleHistory.startDate).format('L')} - ${moment(vehicleHistory.endDate).format('L')}` : "Servis geçmişi"}
                    buttonProps={{
                        startIcon: <ShareLocationRounded fontSize="inherit" />,
                        endIcon: vehicleHistory.startDate && vehicleHistory.endDate && <IconButton size="small" onClick={resetHistory}><CloseRounded fontSize="inherit" /></IconButton>
                    }}>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Başlangıç Zamanı"
                        value={vehicleHistory.startDate}
                        onChange={(newValue) => {
                            setVehicleHistory({ ...vehicleHistory, startDate: newValue || null })
                        }}
                        maxDateTime={vehicleHistory.endDate || new Date()}
                    />
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Bitiş Zamanı"
                        value={vehicleHistory.endDate}
                        onChange={(newValue) => {
                            setVehicleHistory({ ...vehicleHistory, endDate: newValue || null })
                        }}
                        minDateTime={vehicleHistory.startDate}
                        maxDateTime={new Date()}
                    />
                    <LoadingButton
                        loading={vehicleHistory.isLoading}
                        disabled={!vehicleHistory.startDate && !vehicleHistory.endDate}
                        sx={{ width: "100%" }}
                        variant="contained"
                        onClick={getVehicleHistory}
                    >
                        Servis geçmişini göster
                    </LoadingButton>
                </CustomPopup>
            }
        </Box >
    )
}

export default TrackingNavigation;


