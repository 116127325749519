import axios from "axios";

export const getAllStudiesRequest = () => axios.get(`/study/my`)

export const getStudyRequest = (id) => axios.get(`/study/${id}`)

export const saveStudyRequest = (form) => axios.post(`/study`, form)

export const getTravelerByAddressNeighborhoodRequest = (id, neighborhood) => axios.get(`/study/${id}/traveler?neighborhood=${neighborhood}`)

export const createStudyRequest = (form, etutId, isForced) => axios.post(`/study/${etutId}/addPlan?isForced=${isForced}`, form)

export const createVoyageStudyRequest = (form, etutId, isForced) => axios.post(`/study/${etutId}/addPlanVoyage?isForced=${isForced}`, form)

export const createStationRequest = (form, etutId, planId) => axios.post(`/study/${etutId}/plan/${planId}/station`, form)

export const updateStudyRequest = (form, etutId) => axios.put(`/study/${etutId}`, form)

export const deleteStationRequest = (studyTimeId, planId, stationId) => axios.delete(`/study/${studyTimeId}/plan/${planId}/station/${stationId}`)

export const updateStationRequest = (studyTimeId, planId, stationId, form) => axios.put(`/study/${studyTimeId}/plan/${planId}/station/${stationId}`, form)

export const updateStudyPlanRequest = (studyTimeId, planId, form, isForced) => axios.put(`/study/${studyTimeId}/plan/${planId}?isForced=${isForced}`, form)
