import {
  Grid,
  InputAdornment,
  TextField
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";

import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import * as yup from "yup";
import { getVehicleCostKmRequest, updateVehicleCostKmRequest } from "../../../../api/controllers/company-controller";

export const CostInputsForms = () => {
  
  const getData = async () => {
    try {
      let res = await getVehicleCostKmRequest();
      if (res) {
        formik.setValues(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const formik = useFormik({
    validationSchema: yup.object({
      monthlyMaintenance: yup.number().required("Bu alan gereklidir"),
      monthlyInspection: yup.number().required("Bu alan gereklidir"),
      monthlyKasko: yup.number().required("Bu alan gereklidir"),
      monthlyInsurance: yup.number().required("Bu alan gereklidir"),
      monthlyDriver: yup.number().required("Bu alan gereklidir"),
      monthlyTyre: yup.number("sda").required("Bu alan gereklidir"),
      fuelPrice: yup.number().required("Bu alan gereklidir"),
      fuelConsumptionHundredKm: yup.number().required("Bu alan gereklidir"),
      fuelCostKm: yup.number().required("Bu alan gereklidir"),
      monthlyTotalKm: yup.number().required("Bu alan gereklidir"),
    }),
    initialValues: {
      monthlyMaintenance: 0,
      monthlyInspection: 0,
      monthlyKasko: 0,
      monthlyInsurance: 0,
      monthlyDriver: 0,
      monthlyTyre: 0,
      fuelPrice: 0,
      fuelConsumptionHundredKm: 0,
      fuelCostKm: 0,
      monthlyTotalKm: 0,
      costKm: 0,
    },
    onSubmit: () => onSubmit(),
  });

  const onSubmit = async () => {
    try {
      let res = await updateVehicleCostKmRequest(formik.values);
      if (res) {
        toast.success("Bilgiler güncellendi");
        getData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <form onBlur={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <NumericFormat
              name="monthlyMaintenance"
              label="Aylık Bakım Ücreti"
              placeholder="Aylık Bakım Ücretini Giriniz"
              fullWidth
              onValueChange={({ floatValue }) =>
                formik.setFieldValue("monthlyMaintenance", floatValue)
              }
              customInput={TextField}
              value={formik.values.monthlyMaintenance}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>&#8378;</span>
                  </InputAdornment>
                ),
              }}
              error={
                formik.touched.monthlyMaintenance &&
                formik.errors.monthlyMaintenance
              }
              helperText={
                formik.touched.monthlyMaintenance &&
                formik.errors.monthlyMaintenance
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NumericFormat
              name="monthlyInspection"
              label="Aylık Muayene Ücreti"
              placeholder="Aylık Muayene Ücretini Giriniz"
              fullWidth
              onValueChange={({ floatValue }) =>
                formik.setFieldValue("monthlyInspection", floatValue)
              }
              value={formik.values.monthlyInspection}
              customInput={TextField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>&#8378;</span>
                  </InputAdornment>
                ),
              }}
              error={
                formik.touched.monthlyInspection &&
                formik.errors.monthlyInspection
              }
              helperText={
                formik.touched.monthlyInspection &&
                formik.errors.monthlyInspection
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NumericFormat
              name="monthlyKasko"
              label="Aylık Kasko Ücreti"
              placeholder="Aylık Kasko Ücretini Giriniz"
              fullWidth
              onValueChange={({ floatValue }) =>
                formik.setFieldValue("monthlyKasko", floatValue)
              }
              value={formik.values.monthlyKasko}
              customInput={TextField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>&#8378;</span>
                  </InputAdornment>
                ),
              }}
              error={formik.touched.monthlyKasko && formik.errors.monthlyKasko}
              helperText={
                formik.touched.monthlyKasko && formik.errors.monthlyKasko
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NumericFormat
              name="monthlyInsurance"
              label="Aylık Sigorta Ücreti"
              placeholder="Aylık Sigorta Ücretini Giriniz"
              fullWidth
              onValueChange={({ floatValue }) =>
                formik.setFieldValue("monthlyInsurance", floatValue)
              }
              value={formik.values.monthlyInsurance}
              customInput={TextField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>&#8378;</span>
                  </InputAdornment>
                ),
              }}
              error={
                formik.touched.monthlyInsurance &&
                formik.errors.monthlyInsurance
              }
              helperText={
                formik.touched.monthlyInsurance &&
                formik.errors.monthlyInsurance
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NumericFormat
              name="monthlyDriver"
              label="Aylık Sürücü Ücreti"
              placeholder="Aylık Sürücü Ücretini Giriniz"
              fullWidth
              onValueChange={({ floatValue }) =>
                formik.setFieldValue("monthlyDriver", floatValue)
              }
              value={formik.values.monthlyDriver}
              customInput={TextField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>&#8378;</span>
                  </InputAdornment>
                ),
              }}
              error={
                formik.touched.monthlyDriver && formik.errors.monthlyDriver
              }
              helperText={
                formik.touched.monthlyDriver && formik.errors.monthlyDriver
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NumericFormat
              name="monthlyTyre"
              label="Aylık Lastik Ücreti"
              placeholder="Aylık Lastik Ücretini Giriniz"
              fullWidth
              onValueChange={({ floatValue }) =>
                formik.setFieldValue("monthlyTyre", floatValue)
              }
              value={formik.values.monthlyTyre}
              customInput={TextField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>&#8378;</span>
                  </InputAdornment>
                ),
              }}
              error={formik.touched.monthlyTyre && formik.errors.monthlyTyre}
              helperText={
                formik.touched.monthlyTyre && formik.errors.monthlyTyre
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NumericFormat
              name="fuelPrice"
              label="Yakıt Fiyatı"
              placeholder="Yakıt Fiyatını Giriniz"
              fullWidth
              onValueChange={({ floatValue }) =>
                formik.setFieldValue("fuelPrice", floatValue)
              }
              value={formik.values.fuelPrice}
              customInput={TextField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>&#8378;</span>
                  </InputAdornment>
                ),
              }}
              error={formik.touched.fuelPrice && formik.errors.fuelPrice}
              helperText={formik.touched.fuelPrice && formik.errors.fuelPrice}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NumericFormat
              name="fuelConsumptionHundredKm"
              label="Ortalama Yakıt Tüketimi"
              placeholder=" Ortalama Yakıt Tüketimini Giriniz"
              fullWidth
              onValueChange={({ floatValue }) =>
                formik.setFieldValue("fuelConsumptionHundredKm", floatValue)
              }
              value={formik.values.fuelConsumptionHundredKm}
              customInput={TextField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">/100KM</InputAdornment>
                ),
              }}
              error={
                formik.touched.fuelConsumptionHundredKm &&
                formik.errors.fuelConsumptionHundredKm
              }
              helperText={
                formik.touched.fuelConsumptionHundredKm &&
                formik.errors.fuelConsumptionHundredKm
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NumericFormat
              disabled
              name="fuelCostKm"
              label="Kilometre Başına Yakıt Ücreti"
              placeholder="Kilometre Başına Yakıt Ücretini Giriniz"
              fullWidth
              /*  onValueChange={({ floatValue }) =>
                 formik.setFieldValue("fuelCostKm", floatValue)
               } */
              value={formik.values.fuelCostKm}
              customInput={TextField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>&#8378;</span>
                  </InputAdornment>
                ),
              }}
              error={formik.touched.fuelCostKm && formik.errors.fuelCostKm}
              helperText={formik.touched.fuelCostKm && formik.errors.fuelCostKm}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NumericFormat
              disabled
              name="costKm"
              label="Kilometre Başına Maliyet"
              placeholder="Kilometre Başına Maliyet"
              fullWidth
              /*  onValueChange={({ floatValue }) =>
                 formik.setFieldValue("costKm", floatValue)
               } */
              value={formik.values.costKm}
              customInput={TextField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>&#8378;</span>
                  </InputAdornment>
                ),
              }}
              error={formik.touched.costKm && formik.errors.costKm}
              helperText={formik.touched.costKm && formik.errors.costKm}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NumericFormat
              name="monthlyTotalKm"
              label="Aylık Toplam Kilometre"
              placeholder="Aylık Toplam Kilometre Ücretini Giriniz"
              fullWidth
              onValueChange={({ floatValue }) =>
                formik.setFieldValue("monthlyTotalKm", floatValue)
              }
              value={formik.values.monthlyTotalKm}
              customInput={TextField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">KM</InputAdornment>
                ),
              }}
              error={
                formik.touched.monthlyTotalKm && formik.errors.monthlyTotalKm
              }
              helperText={
                formik.touched.monthlyTotalKm && formik.errors.monthlyTotalKm
              }
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};
