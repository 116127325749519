import React, { useCallback, useRef } from 'react'
import styles from './style.module.scss';
import { Box, Typography } from '@mui/material';
import { useDropzone } from "react-dropzone";
import { createBgImageRequest } from "../../../api/controllers/background-controller"
import { toast } from 'react-toastify';

function Create(props) {
    const toastId = useRef(null);

    const onDrop = useCallback(acceptedFiles => {
        createBgImage(acceptedFiles);
        toastId.current = toast.loading("Resim yükleniyor...")
    }, [])

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: {
            'image/*': [],
        },
        onDrop
    });

    const createBgImage = async (acceptedFiles) => {
        const formData = new FormData();
        formData.append('result', acceptedFiles[0]);
        try {
            let res = await createBgImageRequest(formData);
            if (res) {
                props.getBgImages();
                toast.update(toastId.current, { render: "Resim yüklendi", type: "success", isLoading: false, autoClose: true });
            }
        } catch (error) {
            toast.dismiss(toastId.current);
        }
    }
    return (
        <Box className={styles.create_wrapper} {...getRootProps()}>
            <input {...getInputProps()} />
            <Typography>Yeni Resim Ekle</Typography>
        </Box>
    )
}

export default Create;