import axios from "axios";

export const deleteEmployeeEmployeeRequest = (stationId) => axios.delete(`/stationEmployee/employeeList/${stationId}`)

export const deleteStationEmployeeRequest = (id) => axios.delete(`/station/${id}`)

export const getStationsEmployeeRequest = (pageable) => axios.get(`/stationEmployee/allStations?page=${pageable.page}&size=${pageable.size}`)

export const getStationsEmployeeForAdmin = (pageable) => axios.get(`/stationEmployee/myStations?page=${pageable.page}&size=${pageable.size}`)

export const getStationEmployeeByPlanIdRequest = (planId) => axios.get(`/stationEmployee/plan/${planId}`)

export const getStationEmployeeIdRequest = (id) => axios.get(`/station/${id}`)

export const updateStationEmployeeListRequest = (stationId, form) => axios.post(`/station/${stationId}`, form)

export const updateStationEmployeeRequest = (form) => {
    const parsed = form.travelerIds.map(v => v.id);
    form = { ...form, travelerIds: parsed, lat: form.position.latitude, lng: form.position.longitude };
    return axios.put(`/station/${form.id}`, form);
}