import { forwardRef, useMemo } from 'react'
import { Marker, useMapEvents } from 'react-leaflet';
import L, { Icon } from 'leaflet';
import { usePlans } from '../../../context/PlansContext';
import station from "../../../assets/markers/station.svg";
import station_red from "../../../assets/markers/station_red.svg";
import { StationForm } from '../../../types/PlansContext';

type typeplan = "arrival" | "departure";

const CreateStationMarker = ({ planType }: { planType: typeplan }) => {
    const { stationForm, setStationForm } = usePlans();

    const icon = new L.Icon({
        iconUrl: planType === "departure" ? station : station_red,
        iconRetinaUrl: planType === "departure" ? station : station_red,
        iconAnchor: [16, 32],
        popupAnchor: undefined,
        shadowUrl: undefined,
        shadowSize: undefined,
        shadowAnchor: undefined,
        iconSize: [32, 32],
    });

    useMapEvents({
        click(e) {
            if (!stationForm.lat || !stationForm.lng) {
                setStationForm((prev: StationForm) => ({ ...prev, ...e.latlng }));
            }
        },
    })

    const eventHandlers = useMemo(
        () => ({
            dragend(e: any) {
                setStationForm((prev: StationForm) => ({ ...prev, ...e.target._latlng }));
            },
        }),
        [],
    )

    return (
        stationForm.lat && stationForm.lng ?
            <MemoizedMarker
                eventHandlers={eventHandlers}
                position={[stationForm.lat, stationForm.lng]}
                icon={icon}
            /> : <></>
    )
}

export default CreateStationMarker;

type Ref = L.Marker

const MemoizedMarker = forwardRef<Ref, { eventHandlers: any, position: any, icon: Icon, }>((props, ref) => useMemo(() => {

    return (
        <Marker
            ref={ref}
            draggable
            eventHandlers={props.eventHandlers}
            position={props.position}
            icon={props.icon}
        />
    )
}, [props.position[0], props.position[1]]))