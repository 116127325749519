import AuthProvider from './AuthContext';
import CustomerProvider from './CustomerContext';
import PlansProvider from './PlansContext';
import OverTimeProvider from './OverTimeContext';
import StudyProvider from './StudyContext';

function Providers({ children }) {
    return (
        <AuthProvider>
            <CustomerProvider>
                <PlansProvider>
                    <OverTimeProvider>
                        <StudyProvider>
                        {children}
                        </StudyProvider>
                    </OverTimeProvider>
                </PlansProvider>
            </CustomerProvider>
        </AuthProvider>
    )
}

export default Providers