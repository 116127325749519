import { TextField, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import CustomDialog from '../../../components/atoms/CustomDialog';
import { saveDriverRequest } from '../../../api/controllers/driver-controller';
import { toast } from 'react-toastify';
import PhoneTextfield from '../../../components/atoms/PhoneTextfield';
import AddressModal from '../../../components/molecules/AddressModal';


const CreateDriverModal = ({ documentTypes, createModal, setCreateModal, getDrivers, setDocumentModal, setSelectedDriverId }) => {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tabValue, setTabValue] = useState('1');


    const formik = useFormik({
        initialValues: {
            address: {
                buildingInformation: "",
                country: "",
                district: null,
                fullAddress: "",
                neighborhood: null,
                position: {
                    latitude: null,
                    longitude: null
                },
                province: "Denizli",
                street: ""
            },
            email: "",
            firstName: "",
            lastName: "",
            password: "",
            rePassword: "",
            phone: {
                areaCode: "+90",
                countryCode: "",
                number: ""
            },
            username: ""
        },
        onSubmit: values => {
            onSubmit(values);
        },
    });

    const handleClose = () => {
        setCreateModal(false);
        formik.resetForm();
        setTabValue("1");
    }

    const onSubmit = async () => {
        if (tabValue === "1") {
            setTabValue("2")
        } else if (tabValue === "2") {
            setIsSubmitting(true);
            try {
                let res = await saveDriverRequest(formik.values);
                if (res && res.status === 200) {
                    handleClose();
                    getDrivers();
                    if (documentTypes.length > 0) {
                        setSelectedDriverId(res.data.id);
                        setDocumentModal(true);
                    }
                    toast.success("Sürücü başarıyla eklendi")
                }
            } catch (error) {
                toast.error("Bir hata meydana geldi. Lütfen daha sonra tekrar deneyin!")
            }
            setIsSubmitting(false);
        }
    }

    return (
        <CustomDialog
            open={createModal}
            closeFunc={handleClose}
            title="Sürücü Ekle"
            onSubmit={formik.handleSubmit}
            buttons={[
                {
                    onClick: formik.handleSubmit,
                    variant: "contained",
                    type: "submit",
                    loading: isSubmitting,
                    text: "Kaydet",
                }
            ]}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="firstName"
                        label="İsim"
                        placeholder="Mehmet"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="lastName"
                        label="Soyisim"
                        placeholder="Yılmaz"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PhoneTextfield
                        name="phone.number"
                        phone={formik.values.phone}
                        onChange={({ value }) => formik.setFieldValue("phone.number", value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="email"
                        label="Mail Adresi"
                        placeholder="mehmet@yilmaz.com"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AddressModal formik={formik} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="password"
                        label="Şifre"
                        fullWidth
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="rePassword"
                        label="Şifre (Tekrar)"
                        fullWidth
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.rePassword}
                    />
                </Grid>
            </Grid>
        </CustomDialog>
    )
};

export default CreateDriverModal;