import { useEffect, useState } from 'react';
import { Grid, TextField, Autocomplete } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import CustomDialog from '../../../atoms/CustomDialog';
import { toast } from 'react-toastify';
import { getPlansByCustomerIdRequest } from '../../../../api/controllers/plan-controller';
import { getVehiclesRequest } from '../../../../api/controllers/vehicle-controller';
import { getWorkTimesByCustomerIdRequest } from '../../../../api/controllers/work-time-controller';
import { useParams } from 'react-router';
import { createDailyTaskByPlanIdRequest } from '../../../../api/controllers/daily-task-controller';

const CreateDailyTask = (props) => {
    const { customerId } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form, setForm] = useState({
        planId: null,
        vehicleId: null,
        startDate: new Date(),
        endDate: null,
        workTimeId: null,
    })
    const [lists, setLists] = useState({
        plans: [],
        vehicles: [],
        workTimes: [],
    })

    const getPlans = () => getPlansByCustomerIdRequest(customerId).then(res => setLists(prev => ({ ...prev, plans: res.data })));
    const getVehicles = () => getVehiclesRequest().then(res => setLists(prev => ({ ...prev, vehicles: res.data })));
    const getWorkTimes = () => getWorkTimesByCustomerIdRequest(customerId).then(res => setLists(prev => ({ ...prev, workTimes: res.data })));

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await createDailyTaskByPlanIdRequest(form);
            if (res) {
                toast.success("Günlük görev oluşturuldu");
                handleClose();
                props.getData();
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    const handleClose = () => {
        props.setOpen(false);
        setForm({
            planId: null,
            vehicleId: null,
            startDate: new Date(),
            endDate: null,
            workTimeId: null,
        })
    }

    const onChange = (name, value) => {
        setForm(prev => ({ ...prev, [name]: value }));
    }

    useEffect(() => {
        if (props.selectedPlan) {
            setForm(prev => ({ ...prev, planId: props.selectedPlan, vehicleId: props.selectedPlan.vehicle, workTimeId: props.selectedPlan.workTime }))
        }
        getPlans();
        getVehicles();
        getWorkTimes();
    }, [])

    return (
        <CustomDialog
            size="sm"
            open={props.open}
            closeFunc={handleClose}
            title="Günlük görev oluştur"
            buttons={[
                {
                    onClick: handleSubmit,
                    disabled: Object.values(form).includes(null),
                    loading: isSubmitting,
                    variant: "contained",
                    text: "Kaydet",
                }
            ]}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Autocomplete
                        options={lists.plans}
                        getOptionLabel={option => option.title}
                        value={form.planId}
                        onChange={(event, newValue) => {
                            onChange("planId", newValue);
                            if (newValue) {
                                setForm(prev => ({ ...prev, vehicleId: newValue.vehicle, workTimeId: newValue.workTime }));
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label='Plan'
                                fullWidth
                            />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        options={lists.vehicles}
                        getOptionLabel={option => option.licensePlate}
                        value={form.vehicleId}
                        onChange={(event, newValue) => onChange("vehicleId", newValue)}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label='Araç'
                                fullWidth
                            />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        options={lists.workTimes}
                        value={form.workTimeId}
                        getOptionLabel={option => option.title}
                        onChange={(event, newValue) => onChange("workTimeId", newValue)}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label='Vardiya'
                                fullWidth
                            />}
                    />
                </Grid>
                <Grid container item spacing={3} xs={12}>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="Başlangıç Tarihi"
                            value={form.startDate}
                            onChange={(newValue) => onChange("startDate", newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            minDate={new Date()}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="Bitiş Tarihi"
                            value={form.endDate}
                            onChange={(newValue) => onChange("endDate", newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            minDate={form.startDate}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </CustomDialog>
    )
}

export default CreateDailyTask;