import axios from "axios";

export const getDocumentTypesRequest = () => axios.get(`/documentType/getAll`);

export const getDocumentTypesOfAdminRequest = () => axios.get(`/documentType/getAll/admin`);

export const getDocumentTypesOfDriverRequest = () => axios.get(`/documentType/getAll/driver`);

export const getDocumentTypesOfVehicleRequest = () => axios.get(`/documentType/getAll/vehicle`);

export const getDocumentTypeRequest = (id) => axios.get(`/documentType/${id}`);

export const saveDocumentTypeRequest = (form) => axios.post(`/documentType`, form);

export const updateDocumentTypeRequest = (form) => axios.put(`/documentType/${form.id}`, form);