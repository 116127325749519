import styles from "./style.module.scss";
import { Box, IconButton, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { DeleteRounded, EditRounded } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Station } from "../../../types/CommonTypes";

type Props = {
  station?: Station;
  index?: number;
  deleteOnClick?: () => void;
  updateOnClick?: () => void;
};

const StationCardForOverTime = ({
  station,
  index,
  deleteOnClick,
  updateOnClick,
}: Props) => {
  const LightTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} placement="left" classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));

  return (
    <LightTooltip
      title={
        station?.travelers && station.travelers.length > 0
          ? station.travelers.map((v, i) => (
              <ul key={i}>
                <li>{`${i + 1} - ${v.firstName || ""} ${v.lastName || ""}`}</li>
              </ul>
            ))
          : ""
      }
    >
      <Box className={styles.container}>
        <Typography variant="body2">
          {Number(index) + 1} - {station?.title || "Bilinmiyor"}{" "}
          {updateOnClick ? `(${station?.travelers?.length || 0} kişi)` : ""}
        </Typography>
        <Box className={styles.actions}>
          {deleteOnClick && (
            <Tooltip title="Sil">
              <IconButton onClick={deleteOnClick} size="small" color="inherit">
                <DeleteRounded fontSize="inherit" color="inherit" />
              </IconButton>
            </Tooltip>
          )}
          {updateOnClick && (
            <Tooltip title="Düzenle">
              <IconButton onClick={updateOnClick} size="small" color="inherit">
                <EditRounded fontSize="inherit" color="inherit" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </LightTooltip>
  );
};

export default StationCardForOverTime;
