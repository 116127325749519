import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomDialog from '../../../components/atoms/CustomDialog';
import DocumentUpload from '../../../components/atoms/DocumentUpload';
import axios from 'axios';
import { toast } from 'react-toastify';


const DocumentModal = ({ documentModal, setDocumentModal, documentTypes, selectedVehicleId, setSelectedVehicleId }) => {
    const [documents, setDocuments] = useState([]);

    const getDocuments = async () => {
        let res = await axios.get(`/document/vehicle/${selectedVehicleId}`);
        if (res) {
            setDocuments(res.data)
        }
    }

    const uploadDocument = async ({ acceptedFiles, documentTypeId }) => {
        const formData = new FormData();
        formData.append('result', acceptedFiles[0]);
        let res = await axios.post(`/document/vehicle?vehicleId=${selectedVehicleId}&documentTypeId=${documentTypeId}`, formData)
        if (res) {
            getDocuments();
            toast.success("Belge yüklendi");
        }
    }

    const handleClose = () => {
        setDocumentModal(false);
        setSelectedVehicleId(null);
        setDocuments([]);
    }

    useEffect(() => {
        if (selectedVehicleId) {
            getDocuments();
        }
    }, [selectedVehicleId])

    return (
        <CustomDialog
            open={documentModal}
            closeFunc={handleClose}
            title="Servise ait belgeler"
        >
            <Box display="flex" gap="1.5rem" flexWrap="wrap">
                {
                    documentTypes.map((val, i) =>
                        <DocumentUpload
                            key={i}
                            documentTypeId={val.id}
                            name={val.name}
                            selectedVehicleId={selectedVehicleId}
                            document={documents?.find(v => v?.documentType?.id === val.id)}
                            getDocuments={getDocuments}
                            uploadDocument={uploadDocument}
                        />
                    )
                }
            </Box>
        </CustomDialog >
    )
};

export default DocumentModal;