import React, { useEffect } from 'react'
import { Alert, Box, Divider, TextField, Typography } from '@mui/material'
import styles from './style.module.scss'
import { usePlans } from '../../../../context/PlansContext';
import PlansSidebarLayout from '../../../templates/PlansSidebarLayout';
import { ArrowBackRounded } from '@mui/icons-material';
import TravelerCard from '../../../molecules/TravelerCard';
import { getStationEmployeeIdRequest, updateStationEmployeeRequest } from '../../../../api/controllers/station-employee-controller';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';

function UpdateStation() {
    const navigate = useNavigate();
    const { stationId, workTimeId } = useParams();
    const { plan, setPlan, setUpdateForm, stationForm, setStationForm, resetStationForm, getStations, getTravelers } = usePlans();
    const toast_1 = React.useRef(null);
    const toast_2 = React.useRef(null);

    const updateStation = async () => {
        let res = await updateStationEmployeeRequest(stationForm);
        if (res) {
            const stations = await getStations(plan.id);
            setPlan(prev => ({ ...prev, stationList: stations }));
            setUpdateForm(prev => ({ ...prev, plan: { ...prev.plan, stationList: stations } }));
            getTravelers(workTimeId);
            handleClose();
        }
    }

    useEffect(() => {
        if (stationId) {
            (async () => {
                let res = await getStationEmployeeIdRequest(stationId);
                if (res) {
                    setStationForm({ ...res.data, travelers: null, travelerIds: res.data.travelers });
                }
            })()
        } else {
            return () => {
                handleClose();
            }
        }
    }, [stationId])

    const handleClose = () => {
        navigate(window.location.pathname.split(`/station/${stationId}`)[0]);
        resetStationForm();
    }

    useEffect(() => {
        const notify_1 = () => toast_1.current = toast.info("Durağa yolcu ekleyip çıkarmak için yolcunun ikonuna tıklayın.", { autoClose: false });
        const update_1 = () => toast.update(toast_1.current, { autoClose: 10 });
        const notify_2 = () => toast_2.current = toast.info("Durağın konumunu değiştirmek için durak ikonuna tıklayarak sürükleyin.", { autoClose: false });
        const update_2 = () => toast.update(toast_2.current, { autoClose: 10 });
        notify_1();
        notify_2();
        return () => {
            update_1();
            update_2();
        }
    }, [])

    return (
        <PlansSidebarLayout
            header={{
                iconButton: {
                    icon: <ArrowBackRounded fontSize="inherit" />,
                    props: {
                        onClick: handleClose,
                    }
                },
                title: {
                    text: "Durak Düzenle"
                }
            }}
            footer={{
                buttons: [
                    {
                        text: "İptal et",
                        props: {
                            variant: "outlined",
                            color: "secondary",
                            onClick: handleClose,
                        }
                    },
                    {
                        text: "Kaydet",
                        props: {
                            variant: "contained",
                            onClick: updateStation,
                        }
                    },
                ]
            }}
        >
            <Box className={styles.container}>
                <Box className={styles.element}>
                    <Typography variant="h6">
                        | Durak için isim belirleyin
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        placeholder="Durak ismini yazın"
                        value={stationForm.title || ""}
                        onChange={e => setStationForm(prev => ({ ...prev, title: e.target.value }))}
                    />
                </Box>
                <Divider flexItem />
                <Box className={styles.element}>
                    <Typography variant="h6">
                        | Durağa bağlı yolcular
                    </Typography>
                    {
                        stationForm.travelerIds.length > 0 ?
                            stationForm.travelerIds.map(v =>
                                <TravelerCard
                                    key={v.id}
                                    traveler={v}
                                    stationPosition={{ lat: stationForm.position.latitude, lng: stationForm.position.longitude }}
                                />
                            )
                            :
                            <Alert severity="warning">
                                Durağa bağlı yolcu bulunamadı!
                            </Alert>
                    }
                </Box>
            </Box>
        </PlansSidebarLayout>
    )
}

export default React.memo(UpdateStation)