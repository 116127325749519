import React from 'react';
import styles from './style.module.scss';
import L from 'leaflet';
import { Marker, Polygon } from 'react-leaflet';


const ShowArea = props => {
    const center = L.polygon(props.coordinates).getBounds().getCenter();
    const text = L.divIcon({ html: props.text, className: styles.area_polygon_label });

    return (
        <Polygon color="var(--primary)" positions={props.coordinates}>
            <Marker position={center} icon={text} />
        </Polygon>
    );
}

export default ShowArea;