import { createConfirmation } from 'react-confirm';
import { getDistrictsByProvinceIdRequest, getMultipleNeighborhoodsByDistrictsIdListRequest, getNeighborhoodsByDistrictIdRequest, getProvincesRequest } from '../api/controllers/addresses-controller';
import ConfirmDialog from './atoms/ConfirmDialog';

//İl, ilçe ve mahalle getirir
export async function getAddress({ province, district, setProvinces, setDistricts, setNeighborhoods }) {
    let provinceData = await getProvincesRequest();
    if (provinceData) {
        setProvinces(provinceData.data)
        //fetch district
        if (province) {
            let findProvince = provinceData.data.find(v => v.provinceName === province);
            let districtData = await getDistrictsByProvinceIdRequest(findProvince.id);
            if (districtData) {
                setDistricts(districtData.data)
                //fetch neighborhood
                if (district) {
                    if (!Array.isArray(district)) {
                        let findDistrict = districtData.data.find(v => v.districtName === district);
                        let res = await getNeighborhoodsByDistrictIdRequest(findDistrict.id);
                        if (res) {
                            setNeighborhoods(res.data)
                        }
                    } else {
                        let findDistrict = districtData.data.filter(v => v.districtName === district.map(v => v.districtName));
                        const matchingDistricts = districtData.data.filter(districtItem => district.includes(districtItem.districtName))

                        let res = await getMultipleNeighborhoodsByDistrictsIdListRequest({ provinceName: province, idList: matchingDistricts.map(v => v.id) });
                        if (res) {
                            setNeighborhoods(res.data)
                        }
                    }
                }
            }
        }
    }
}

// create confirm function
export const confirm = createConfirmation(ConfirmDialog);

// This is optional. But wrapping function makes it easy to use.
export function confirmWrapper(confirmation, options = {}) {
    return confirm({ confirmation, options });
}
