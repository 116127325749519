import { Avatar, ListItemIcon, MenuItem, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import CustomDataGrid from "../../../components/atoms/CustomDataGrid";
import PageLayout from "../../../components/templates/PageLayout";
import {
  getCustomersPageableRequest,
  deleteCustomerRequest,
} from "../../../api/controllers/customer-controller";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import CustomMenu from "../../../components/molecules/CustomMenu";
import { CheckRounded, CloseRounded, Info, Delete } from "@mui/icons-material";
import Search from "../../../components/molecules/Search";
import Filter from "../../../components/molecules/Filter";
import { Stack } from "@mui/system";
import { getManagersRequest } from "../../../api/controllers/manager-controller";
import FilterList from "../../../components/molecules/FilterList";
import { confirmWrapper } from "../../../components/Utils";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import ConfirmModal from "../../../components/atoms/DeleteConfirmModal";
import { getManagerPermissionsRequest } from "../../../api/controllers/manager-controller";

const Page = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageable, setPageable] = useState({ page: 0, size: 10, total: 0 });
  const [filter, setFilter] = useState({
    search: "",
    managerIdList: [],
  });
  const [managers, setManagers] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const deleteId = searchParams.get("deleteId");
  const { customerId } = useParams();
  const { role } = currentUser;
  const [managerPermission, setManagerPermission] = useState([]);

  useEffect(() => {
      (async () => {
        let res = await getManagerPermissionsRequest(currentUser?.id);
        if (res) {
          setManagerPermission(res.data);
        }
      })();
  }, []);

  const columns = [
    {
      field: "isActive",
      headerName: "Durum",
      sortable: false,
      renderCell: ({ value }) => (
        <Tooltip title={value ? "Onaylı" : "Onay bekliyor - Reddedildi"}>
          <Avatar
            sx={{
              width: 24,
              height: 24,
              fontSize: 14,
              bgcolor: value ? "var(--success_12)" : "var(--danger_12)",
            }}
          >
            {value ? (
              <CheckRounded htmlColor="var(--success)" fontSize="inherit" />
            ) : (
              <CloseRounded htmlColor="var(--danger)" fontSize="inherit" />
            )}
          </Avatar>
        </Tooltip>
      ),
    },
    {
      field: "title",
      headerName: "Firma Ünvanı",
      flex: 1,
      minWidth: 200,
      valueGetter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "firstName",
      headerName: "Yetkili",
      flex: 1,
      minWidth: 200,
      valueGetter: ({ value, row }) =>
        value ? row.firstName + " " + row.lastName : "-",
    },
    {
      field: "phone",
      headerName: "Yetkili Telefon",
      flex: 1,
      minWidth: 200,
      valueGetter: ({ value }) =>
        value.number ? value.areaCode + value.number : "-",
    },
    {
      field: "manager",
      headerName: "Atanmış Yönetici",
      flex: 1,
      minWidth: 200,
      valueGetter: ({ value }) =>
        value ? value.firstName + " " + value.lastName : "-",
    },
    {
      field: "id",
      headerName: "",
      sortable: false,
      align: "right",
      renderCell: ({ value, row }) => {
        const permissions = managerPermission.filter(
          (perm) =>
            perm.customer.id === value && perm.manager.id === currentUser.id
        );

        if (
          !permissions.some(
            (perm) => perm.managerPermissionType === "CUSTOMER_EDIT"
          )
        ) {
          return "";
        }

        return (
          <CustomMenu>
            <MenuItem
              onClick={() => navigate(`/app/musteriler/${value}/detay`)}
            >
              <ListItemIcon>
                <Info fontSize="small" />
              </ListItemIcon>
              Detaylar
            </MenuItem>
            {
              <MenuItem onClick={() => setSearchParams({ deleteId: value })}>
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                Sil
              </MenuItem>
            }
            {!row.isActive && currentUser.role === "ADMIN" && (
              <MenuItem onClick={() => handleConfirm(row.title, value)}>
                <ListItemIcon>
                  <CheckRounded fontSize="small" />
                </ListItemIcon>
                Onayla
              </MenuItem>
            )}
          </CustomMenu>
        );
      },
    },
  ];

  const getCustomers = async (type) => {
    if (type === "clear") {
      filter.managerIdList = [];
      setFilter(filter);
    }
    setIsTableLoading(true);
    try {
      let res = await getCustomersPageableRequest(pageable, filter);
      if (res) {
        setPageable({ ...pageable, total: res.data.totalElements });
        setData(res.data.content);
      }
    } catch (error) {}
    setIsTableLoading(false);
  };

  const handleConfirm = async (title, id) => {
    if (
      await confirmWrapper(
        `${title} isimli müşteriyi onaylamak istediğinize emin misiniz?`
      )
    ) {
      let res = await axios.put(`/account/updateIsActive?id=${id}`);
      if (res) {
        getCustomers();
        toast.success("Müşteri onaylandı");
      }
    }
  };

  const deleteCustomer = async (deleteId) => {
    if (!managerPermission?.includes("CUSTOMER_EDIT")) {
      toast("Bunu Yapmak için Yetkiniz Yok");
      return;
    }
    let res = await deleteCustomerRequest(deleteId);
    if (res) {
      setData(data.filter((item) => item.id?.toString() !== deleteId));
      setSearchParams(false);
      toast.success("Müşteri silindi");
    }
  };

  useEffect(() => {
    getCustomers();
  }, [pageable.page, pageable.size, filter.search]);

  useEffect(() => {
    currentUser.role === "ADMIN" &&
      (async () => {
        let res = await getManagersRequest();
        if (res) {
          setManagers(res.data);
        }
      })();
  }, []);

  return (
    <PageLayout
      title="Müşteriler"
      buttons={[
        {
          onClick: () => navigate("/app/musteriler/ekle"),
          variant: "contained",
          startIcon: <i className="bi bi-plus-lg"></i>,
          text: "Müşteri Ekle",
        },
      ]}
    >
      {deleteId && (
        <ConfirmModal
          open={deleteId}
          title={"Emin misiniz?"}
          setOpen={setSearchParams}
          message={"Müşteriyi silmek istediğinize emin misiniz?"}
          handleConfirm={() => deleteCustomer(deleteId)}
        />
      )}
      <Stack spacing={2}>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Search
            onChange={(e) => setFilter((prev) => ({ ...prev, search: e }))}
          />
          {currentUser.role === "ADMIN" && (
            <Filter
              isEmpty={filter.managerIdList.length === 0}
              onClear={() => getCustomers("clear")}
              onSubmit={getCustomers}
            >
              <FilterList
                title="Yöneticilere göre"
                data={managers}
                getLabel={(v) => v.firstName + " " + v.lastName}
                filter={filter}
                setFilter={setFilter}
                filterKey="managerIdList"
              />
            </Filter>
          )}
        </Stack>
        <CustomDataGrid
          data={data}
          columns={columns}
          loading={isTableLoading}
          rowId={(params) => params?.id}
          pageable={pageable}
          setPageable={setPageable}
        />
      </Stack>
    </PageLayout>
  );
};

export default Page;
