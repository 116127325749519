import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import {
  Box,
  Fade,
  IconButton,
  Slide,
  Typography,
  IconButtonProps,
} from "@mui/material";
import { usePlans } from "../../../context/PlansContext";
import { useOverTime } from "../../../context/OverTimeContext";
import { useStudy } from "../../../context/StudyContext";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { useAuth } from "../../../context/AuthContext";
import { getManagerCustomerPermissionsRequest } from "../../../api/controllers/manager-controller";
import { useParams } from "react-router-dom";

type Header = {
  iconButton?: {
    icon?: React.ReactNode;
    props?: IconButtonProps;
  };
  title?: {
    text?: string;
    props?: React.HTMLAttributes<HTMLParagraphElement>;
  };
  button?: {
    text?: string;
    props?: LoadingButtonProps;
  } | null;
};

type Footer = {
  buttons?: {
    text?: string;
    props?: LoadingButtonProps;
  }[];
};

type Props = {
  children: React.ReactNode;
  header?: Header;
  footer?: Footer;
  managerPermission?: any[];
  customerPermission?: any[];
};

type HeaderProps = {
  header?: Header;
  setShowSidebar: (prev: boolean) => void;
};

type FooterProps = {
  footer?: Footer;
};

//{ children, header = {   ,  }

const PlansSidebarLayout = ({
  children,
  header,
  footer,
  customerPermission,
}: Props) => {
  const plan = usePlans();
  const overTime = useOverTime();
  const study = useStudy();
  const { currentUser } = useAuth();
  const { role } = currentUser;
  const { customerId } = useParams();
  const [managerPermission, setManagerPermission] = useState([]);
  console.log(role, "role");
  useEffect(() => {
    if (customerId && role === "MANAGER") {
      (async () => {
        let res = await getManagerCustomerPermissionsRequest(
          currentUser.id,
          customerId
        );
        console.log(res);

        if (res) {
          setManagerPermission(res.data);
        }
      })();
    }
  }, [customerId]);

  let values: any;
  if (window.location.pathname.includes("guzergahlar")) {
    values = plan;
  } else if (window.location.pathname.includes("mesailer")) {
    values = overTime;
  } else if (window.location.pathname.includes("etutler")) {
    values = study;
  }

  const { showSidebar, setShowSidebar } = values as any;
  console.log(managerPermission, "her");

  return (role === "MANAGER" && managerPermission?.includes("PLAN_READ")) ||
    (role === "CUSTOMER" && customerPermission?.includes("ROUTE_READ")) ||
    role === "ADMIN" ? (
    <Slide
      direction="left"
      in={showSidebar}
      mountOnEnter
      unmountOnExit
      appear={false}
    >
      <Box component="aside" className={styles.container}>
        <Header header={header} setShowSidebar={setShowSidebar} />
        <Fade in={true} timeout={600}>
          <Box className={styles.body}>{children}</Box>
        </Fade>
        {footer && <Footer footer={footer} />}
      </Box>
    </Slide>
  ) : null;
};

export default React.memo(PlansSidebarLayout);

const Header = React.memo(({ header, setShowSidebar }: HeaderProps) => {
  return (
    <Box className={styles.header}>
      <Box className={styles.wrapper}>
        <Box className={styles.content}>
          <IconButton
            className={styles.close_button}
            onClick={() => setShowSidebar(false)}
            {...header?.iconButton?.props}
          >
            {header?.iconButton?.icon || <i className="bi bi-chevron-right" />}
          </IconButton>
          <Fade in={true} timeout={600}>
            <Typography className={styles.title} {...header?.title?.props}>
              {header?.title?.text || "Güzergahlar"}
            </Typography>
          </Fade>
        </Box>
        {header?.button && (
          <LoadingButton {...header?.button?.props}>
            {header?.button?.text}
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
});

const Footer = React.memo(({ footer }: FooterProps) => {
  return (
    <Box className={styles.footer}>
      {footer?.buttons &&
        footer.buttons.length > 0 &&
        footer.buttons.map(
          (v, i) =>
            v.text && (
              <LoadingButton key={i} {...v.props}>
                {v.text}
              </LoadingButton>
            )
        )}
    </Box>
  );
});
