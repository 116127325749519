import { TextField, Grid, Autocomplete, Box, Typography, MenuItem, ListItemIcon } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import CustomDataGrid from '../../../components/atoms/CustomDataGrid';
import CustomDialog from '../../../components/atoms/CustomDialog';
import PageLayout from '../../../components/templates/PageLayout';
import { getVehicleRequest, updateVehicleRequest } from '../../../api/controllers/vehicle-controller';
import { PatternFormat, NumericFormat } from 'react-number-format';
import { getDriversRequest } from '../../../api/controllers/driver-controller';
import { useParams, useNavigate } from 'react-router-dom';
import { getDocumentTypesOfVehicleRequest } from '../../../api/controllers/document-type-controller';
import DocumentUpload from '../../../components/atoms/DocumentUpload';
import axios from 'axios';
import { toast } from 'react-toastify';
import CustomMenu from '../../../components/molecules/CustomMenu';
import { DirectionsBusRounded, Info } from '@mui/icons-material';
import { getPlansByVehicleIdPageableRequest } from '../../../api/controllers/plan-controller';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';

const Page = () => {
    const navigate = useNavigate();
    const { vehicleId } = useParams();

    //States
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);
    const [pageable, setPageable] = useState({ page: 0, size: 10, total: 0 });

    const [updateModal, setUpdateModal] = useState(false);
    const [documentModal, setDocumentModal] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [vehicle, setVehicle] = useState({});


    //Dökümanları getirir
    const getDocumentTypes = async () => {
        let res = await getDocumentTypesOfVehicleRequest();
        if (res) {
            setDocumentTypes(res.data);
        }
    }


    //Aracı getirir
    const getVehicle = async () => {
        let res = await getVehicleRequest(vehicleId);
        if (res) {
            setVehicle(res.data)
        }
    }


    //Planları getirir
    const getPlans = async () => {
        setIsTableLoading(true);
        try {
            let res = await getPlansByVehicleIdPageableRequest(vehicleId, pageable);
            if (res) {
                setData(res.data.content)
                setPageable({ ...pageable, total: res.data.totalElements });
            }
        } catch (error) { }
        setIsTableLoading(false);
    }


    //Sürücüleri getirir
    const getDrivers = async () => {
        let res = await getDriversRequest();
        if (res) {
            setDrivers(res.data);
        }
    }


    //Tablo sütunları
    const columns = [
        {
            field: 'title',
            headerName: "Güzergah İsmi",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value || "-",
        },
        {
            field: 'vehicle',
            headerName: "Yolcu Sayısı (Mevcut/Kapasite)",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value.capacity : "-",
        },
        {
            field: 'distance',
            headerName: "Toplam Mesafe",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? `~ ${new Intl.NumberFormat('tr-TR').format(value)} metre` : "-",
        },
        {
            field: 'duration',
            headerName: "Tahmini Süre",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? `${moment.duration(value).hours() ? moment.duration(value).hours() + " saat " : ""}${moment.duration(value).minutes() + " dakika"}` : "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ row }) => <CustomMenu>
                <MenuItem onClick={() => navigate(`/app/guzergahlar?customerId=${row?.workTime?.customer?.id}&workTimeId=${row?.workTime?.id}&planId=${row?.id}`)}>
                    <ListItemIcon>
                        <Info fontSize="small" />
                    </ListItemIcon>
                    Detaylar
                </MenuItem>
            </CustomMenu>,
        },
    ]


    const Sidebar = () => {
        const sidebarItems = [
            {
                icon: <DirectionsBusRounded fontSize="inherit" />,
                label: "IMEI",
                value: vehicle?.imei || "-",
            },
            {
                icon: <DirectionsBusRounded fontSize="inherit" />,
                label: "Marka",
                value: vehicle?.brand || "-",
            },
            {
                icon: <DirectionsBusRounded fontSize="inherit" />,
                label: "Model",
                value: vehicle?.model ? vehicle?.model + " (" + vehicle?.capacity + " kişi)" : "-",
            },
            {
                icon: <DirectionsBusRounded fontSize="inherit" />,
                label: "Model Yılı",
                value: vehicle?.year || "-",
            },
            {
                icon: <DirectionsBusRounded fontSize="inherit" />,
                label: "KM (Toplam)",
                value: vehicle?.totalDistance ? new Intl.NumberFormat('tr-TR').format(vehicle?.totalDistance) : "-",
            },
            {
                icon: <i className="bi bi-person-fill" />,
                label: "Sürücü",
                value: vehicle?.driver ? vehicle?.driver?.firstName + " " + vehicle?.driver?.lastName : "-",
            },
        ]

        return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", mt: "2rem" }}>
                {
                    sidebarItems.map((item, i) =>
                        <Box key={i}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: ".2rem",
                                    fontSize: ".8rem",
                                    fontWeight: 500,
                                    opacity: .75,
                                }}
                            >
                                {item.icon} {item.label}
                            </Box>
                            <Box>{item.value || "-"}</Box>
                        </Box>
                    )
                }
            </Box>
        )
    }


    useEffect(() => {
        getVehicle();
        getDrivers();
        getDocumentTypes();
        getPlans();
    }, [])

    useEffect(() => {
        getPlans();
    }, [pageable.page, pageable.size])


    return (
        <PageLayout
            title={vehicle?.licensePlate}
            detailPage
            sidebar={<Sidebar />}
            menuItems={[
                {
                    onClick: () => setUpdateModal(true),
                    icon: null,
                    text: "Servisi Düzenle",
                },
            ]}
            buttons={[
                {
                    onClick: () => {
                        if (documentTypes.length > 0) {
                            setDocumentModal(true);
                        } else {
                            toast.warn(<p>Öncelikle <b>Belge Yönetimi</b> sayfasından belge tanımı ekleyin!</p>)
                        }
                    },
                    variant: "outlined",
                    color: "primary",
                    startIcon: <i className="bi bi-archive"></i>,
                    text: "Belgeler",
                },
            ]}>
            <Typography
                variant="h6"
                sx={{ marginBottom: "1.5rem" }}
            >| Servisin dahil olduğu güzergahlar</Typography>
            <CustomDataGrid
                data={data}
                columns={columns}
                loading={isTableLoading}
                rowId={(params) => params?.id}
                pageable={pageable}
                setPageable={setPageable}
            />

            {/* Güncelleme modalı */}
            {
                updateModal &&
                <UpdateVehicleModal
                    updateModal={updateModal}
                    setUpdateModal={setUpdateModal}
                    drivers={drivers}
                    getVehicle={getVehicle}
                    vehicle={vehicle}
                />
            }

            {/* Döküman modalı */}
            {
                documentModal &&
                <DocumentModal
                    documentModal={documentModal}
                    setDocumentModal={setDocumentModal}
                    documentTypes={documentTypes}
                    vehicleId={vehicleId}
                />
            }
        </PageLayout>
    )
}

export default Page;


const UpdateVehicleModal = ({ updateModal, setUpdateModal, drivers, getVehicle, vehicle }) => {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const formik = useFormik({
        initialValues: vehicle,
        onSubmit: () => onSubmit(),
    });

    const handleClose = () => {
        setUpdateModal(false);
    }

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await updateVehicleRequest(formik.values);
            if (res && res.status === 200) {
                handleClose();
                getVehicle();
                toast.success("Servis başarıyla güncellendi!")
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    useEffect(() => {
        formik.setValues(vehicle);
        return () => {
            formik.resetForm();
        }
    }, [])

    return (
        <CustomDialog
            open={updateModal}
            closeFunc={handleClose}
            title="Servisi Güncelle"
            onSubmit={formik.handleSubmit}
            buttons={[
                {
                    onClick: formik.handleSubmit,
                    type: "submit",
                    loading: isSubmitting,
                    variant: "contained",
                    text: "Kaydet",
                }
            ]}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="licensePlate"
                        label="Plaka"
                        placeholder="20 AA 2020"
                        fullWidth
                        onChange={e => formik.setFieldValue("licensePlate", e.target.value.toLocaleUpperCase())}
                        value={formik.values.licensePlate}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PatternFormat
                        format="###############"
                        required
                        name="imei"
                        label="IMEI"
                        placeholder="###############"
                        fullWidth
                        onValueChange={({ value }) => formik.setFieldValue("imei", value)}
                        value={formik.values.imei}
                        customInput={TextField}
                        error={formik.values.imei ? formik.values.imei?.length !== 15 : false}
                        helperText={formik.values.imei ? formik.values.imei?.length !== 15 && "Imei 15 haneli olmalıdır" : null}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="brand"
                        label="Marka"
                        placeholder="Mercedes"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.brand}
                    />
                </Grid>
                <Grid item xs={10} md={4}>
                    <TextField
                        required
                        name="model"
                        label="Model"
                        placeholder="Sprinter"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.model}
                    />
                </Grid>
                <Grid item xs={2} md={2}>
                    <NumericFormat
                        required
                        name="year"
                        label="Yıl"
                        placeholder="2020"
                        fullWidth
                        minLength={4}
                        maxLength={4}
                        onValueChange={({ floatValue }) => formik.setFieldValue("year", floatValue)}
                        value={formik.values.year}
                        customInput={TextField}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <NumericFormat
                        required
                        name="capacity"
                        label="Kapasite (Kişi)"
                        placeholder="18"
                        fullWidth
                        onValueChange={({ floatValue }) => formik.setFieldValue("capacity", floatValue)}
                        value={formik.values.capacity}
                        customInput={TextField}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <NumericFormat
                        required
                        name="totalDistance"
                        label="KM"
                        placeholder="120000"
                        fullWidth
                        thousandSeparator="."
                        decimalSeparator=","
                        onValueChange={({ floatValue }) => formik.setFieldValue("totalDistance", floatValue)}
                        value={formik.values.totalDistance}
                        customInput={TextField}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        name="driver"
                        options={drivers}
                        renderInput={(params) =>
                            <TextField
                                required
                                {...params}
                                label="Sürücü"
                                placeholder="Listeden seçin"
                                fullWidth
                            />}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        getOptionLabel={(option) => option.firstName + " " + option.lastName}
                        value={formik.values.driver}
                        onChange={(event, newValue) => formik.setFieldValue("driver", newValue ? newValue : null)}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <NumericFormat
                        required
                        name="lastMaintenanceDistance"
                        label="Son Bakım KM'si"
                        placeholder="120000"
                        fullWidth
                        thousandSeparator="."
                        decimalSeparator=","
                        onValueChange={({ floatValue }) => {
                            formik.setFieldValue("lastMaintenanceDistance", floatValue)
                        }}
                        value={formik.values.lastMaintenanceDistance}
                        customInput={TextField}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <NumericFormat
                        required
                        name="maintenanceInterval"
                        label="Periyodik bakım KM'si"
                        placeholder="120000"
                        fullWidth
                        thousandSeparator="."
                        decimalSeparator=","
                        onValueChange={({ floatValue }) => {
                            formik.setFieldValue("maintenanceInterval", floatValue)
                        }}
                        value={formik.values.maintenanceInterval}
                        customInput={TextField}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <DatePicker
                        label="Zorunlu Sigorta Tarihi"
                        value={formik.values.compulsoryInsuranceDate}
                        onChange={(newValue) => {
                            const newDate = new Date(newValue);
                            newDate.setHours(newDate.getHours() + 3);
                            formik.setFieldValue("compulsoryInsuranceDate", newValue ? newDate : null)
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                        minDate={new Date()}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <DatePicker
                        label="Muayene Tarihi"
                        value={formik.values.generalInspectionDate}
                        onChange={(newValue) => {
                            const newDate = new Date(newValue);
                            newDate.setHours(newDate.getHours() + 3);
                            formik.setFieldValue("generalInspectionDate", newValue ? newDate : null)
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                        minDate={new Date()}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <DatePicker
                        label="Kasko Tarihi"
                        value={formik.values.ownDamageInsuranceDate}
                        onChange={(newValue) => {
                            const newDate = new Date(newValue);
                            newDate.setHours(newDate.getHours() + 3);
                            formik.setFieldValue("ownDamageInsuranceDate", newValue ? newDate : null)
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                        minDate={new Date()}
                    />
                </Grid>
            </Grid>
        </CustomDialog >
    )
};


const DocumentModal = ({ documentModal, setDocumentModal, documentTypes, vehicleId }) => {

    const [documents, setDocuments] = useState([]);

    const getDocuments = async () => {
        let res = await axios.get(`/document/vehicle/${vehicleId}`);
        if (res) {
            setDocuments(res.data)
        }
    }

    const uploadDocument = async ({ acceptedFiles, documentTypeId }) => {
        const formData = new FormData();
        formData.append('result', acceptedFiles[0]);
        let res = await axios.post(`/document/vehicle?vehicleId=${vehicleId}&documentTypeId=${documentTypeId}`, formData)
        if (res) {
            getDocuments();
            toast.success("Belge yüklendi");
        }
    }

    const handleClose = () => {
        setDocumentModal(false);
        setDocuments([]);
    }

    useEffect(() => {
        if (vehicleId) {
            getDocuments();
        }
    }, [vehicleId])

    return (
        <CustomDialog
            open={documentModal}
            closeFunc={handleClose}
            title="Servise ait belgeler"
        >
            <Box display="flex" gap="1.5rem" flexWrap="wrap">
                {
                    documentTypes.map((val, i) =>
                        <DocumentUpload
                            key={i}
                            documentTypeId={val.id}
                            name={val.name}
                            document={documents?.find(v => v?.documentType?.id === val.id)}
                            getDocuments={getDocuments}
                            uploadDocument={uploadDocument}
                        />
                    )
                }
            </Box>
        </CustomDialog >
    )
};