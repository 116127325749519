import { useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import PhoneTextfield from '../../../../../atoms/PhoneTextfield';
import CustomDialog from '../../../../../atoms/CustomDialog';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { saveParentRequest } from '../../../../../../api/controllers/parent-controller';


function CreateParent({ createParentModal, setCreateParentModal, studentForm, getParents }) {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const formik = useFormik({
        validationSchema: yup.object({
            firstName: yup
                .string()
                .required('Bu alan gereklidir'),
            lastName: yup
                .string()
                .required('Bu alan gereklidir'),
            phone: yup.object({
                number: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .length(10, 'Telefon numarası 10 haneli olmalıdır'),
            }),
        }),
        initialValues: {
            email: "",
            firstName: "",
            lastName: "",
            phone: {
                areaCode: "+90",
                countryCode: "",
                number: ""
            },
            username: ""
        },
        onSubmit: () => onSubmit()
    });

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await saveParentRequest(formik.values);
            if (res) {
                toast.success("Veli kaydedildi");
                handleClose();
                (async () => {
                    await getParents();
                    studentForm.setFieldValue("parent", res.data);
                })()
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    const handleClose = () => {
        setCreateParentModal(false);
        formik.resetForm();
    };

    return (
        <CustomDialog
            size="sm"
            open={createParentModal}
            closeFunc={handleClose}
            title="Veli Ekle"
            onSubmit={formik.handleSubmit}
            buttons={[
                {
                    type: "submit",
                    loading: isSubmitting,
                    variant: "contained",
                    text: "Kaydet",
                }
            ]}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        name="firstName"
                        label="Veli İsmi"
                        placeholder="Velinin ismini yazın"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        error={formik.touched.firstName && formik.errors.firstName}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                </Grid>
                <Grid item xs={12}>

                    <TextField
                        name="lastName"
                        label="Veli Soyismi"
                        placeholder="Velinin soyismini yazın"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        error={formik.touched.lastName && formik.errors.lastName}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PhoneTextfield
                        name="phone.number"
                        phone={formik.values.phone}
                        onChange={({ value }) => formik.setFieldValue("phone.number", value)}
                        error={formik.touched.phone?.number && formik.errors.phone?.number}
                        helperText={formik.touched.phone?.number && formik.errors.phone?.number}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="email"
                        label="Mail Adresi "
                        placeholder="Velinin mail adresini yazın"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={formik.touched.email && formik.errors.email}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </Grid>
            </Grid>
        </CustomDialog >
    )
}

export default CreateParent;