import { Box, MenuItem, ListItemIcon, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomDataGrid from '../../../components/atoms/CustomDataGrid';
import PageLayout from '../../../components/templates/PageLayout';
import { getDriverRequest } from '../../../api/controllers/driver-controller';
import { useNavigate, useParams } from 'react-router-dom';
import { getDocumentTypesOfDriverRequest } from '../../../api/controllers/document-type-controller';
import { toast } from 'react-toastify';
import CustomMenu from '../../../components/molecules/CustomMenu';
import { DirectionsBusRounded, Info } from '@mui/icons-material';
import { getPlansByDriverIdPageableRequest } from '../../../api/controllers/plan-controller';
import moment from 'moment';
import UpdateDriverModal from './surucu-duzenle';
import DocumentModal from './surucu-dokuman';

const Page = () => {
    const navigate = useNavigate();
    const { driverId } = useParams();

    //States
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);
    const [pageable, setPageable] = useState({ page: 0, size: 10, total: 0 });

    const [updateModal, setUpdateModal] = useState(false);
    const [driver, setDriver] = useState({});
    const [documentTypes, setDocumentTypes] = useState([]);
    const [documentModal, setDocumentModal] = useState(false);


    //Döküman tiplerini getirir
    const getDocumentTypes = async () => {
        let res = await getDocumentTypesOfDriverRequest();
        if (res) {
            setDocumentTypes(res.data);
        }
    }


    //Planları getirir
    const getPlans = async () => {
        setIsTableLoading(true);
        try {
            let res = await getPlansByDriverIdPageableRequest(driverId, pageable);
            if (res) {
                setData(res.data.content)
                setPageable({ ...pageable, total: res.data.totalElements });
            }
        } catch (error) { }
        setIsTableLoading(false);
    }


    //Tablo sütunları
    const columns = [
        {
            field: 'title',
            headerName: "Güzergah İsmi",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value || "-",
        },
        {
            field: 'vehicle',
            headerName: "Yolcu Sayısı (Mevcut/Kapasite)",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value.capacity : "-",
        },
        {
            field: 'distance',
            headerName: "Toplam Mesafe",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? `~ ${new Intl.NumberFormat('tr-TR').format(value)} metre` : "-",
        },
        {
            field: 'duration',
            headerName: "Tahmini Süre",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? `${moment.duration(value).hours() ? moment.duration(value).hours() + " saat " : ""}${moment.duration(value).minutes() + " dakika"}` : "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ row }) => <CustomMenu>
                <MenuItem onClick={() => navigate(`/app/guzergahlar?customerId=${row?.workTime?.customer?.id}&workTimeId=${row?.workTime?.id}&planId=${row?.id}`)}>
                    <ListItemIcon>
                        <Info fontSize="small" />
                    </ListItemIcon>
                    Detaylar
                </MenuItem>
            </CustomMenu>,
        },
    ]


    //Sürücüleri getirir
    const getDriver = async () => {
        let res = await getDriverRequest(driverId);
        if (res) {
            setDriver(res.data)
        }
    }


    const Sidebar = () => {
        const sidebarItems = [
            {
                icon: <i className="bi bi-telephone-fill" />,
                label: "Telefon",
                value: driver?.phone?.number ? `${driver?.phone?.areaCode} ${driver?.phone?.number}` : "-",
            },
            {
                icon: <i className="bi bi-envelope-fill" />,
                label: "Email",
                value: driver?.email || "-",
            },
            {
                icon: <DirectionsBusRounded fontSize="inherit" />,
                label: "Kullandığı Araç",
                value: driver?.vehicle?.licensePlate || "-",
            },
        ]
        return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", mt: "2rem" }}>
                {
                    sidebarItems.map(item =>
                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: ".2rem",
                                    fontSize: ".8rem",
                                    fontWeight: 500,
                                    opacity: .75,
                                }}
                            >
                                {item.icon} {item.label}
                            </Box>
                            <Box>{item.value || "-"}</Box>
                        </Box>
                    )
                }
            </Box>
        )
    }

    useEffect(() => {
        getDriver();
        getDocumentTypes();
    }, [])

    useEffect(() => {
        getPlans();
    }, [pageable.page, pageable.size])

    return (
        <PageLayout
            title={driver?.firstName + " " + driver?.lastName}
            detailPage
            sidebar={< Sidebar />}
            menuItems={
                [
                    {
                        onClick: () => setUpdateModal(true),
                        icon: null,
                        text: "Sürücüyü Düzenle",
                    },
                ]}
            buttons={
                [
                    {
                        onClick: () => {
                            if (documentTypes.length > 0) {
                                setDocumentModal(true);
                            } else {
                                toast.warn(<p>Öncelikle <b>Belge Yönetimi</b> sayfasından belge tanımı ekleyin!</p>)
                            }
                        },
                        variant: "outlined",
                        color: "primary",
                        startIcon: <i className="bi bi-archive"></i>,
                        text: "Belgeler",
                    },
                ]} >
            <Typography
                variant="h6"
                sx={{ marginBottom: "1.5rem" }}
            >| Sürücünün dahil olduğu güzergahlar</Typography>
            <CustomDataGrid
                data={data}
                columns={columns}
                loading={isTableLoading}
                rowId={(params) => params?.id}
                pageable={pageable}
                setPageable={setPageable}
            />

            {/* Güncelleme modalı */}
            {
                updateModal &&
                <UpdateDriverModal
                    updateModal={updateModal}
                    setUpdateModal={setUpdateModal}
                    getDriver={getDriver}
                    driver={driver}
                />
            }

            {/* Döküman modalı */}
            {
                documentModal &&
                <DocumentModal
                    documentModal={documentModal}
                    setDocumentModal={setDocumentModal}
                    documentTypes={documentTypes}
                    selectedDriverId={driverId}
                />
            }
        </PageLayout >
    );
}

export default Page;