import { Button, ListItemIcon, MenuItem, Popover, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import PageLayout from '../../../components/templates/PageLayout';
import CustomDataGrid from '../../../components/atoms/CustomDataGrid';
import { getDailyTaskVehicleReportAll } from '../../../api/controllers/daily-task-controller';
import styles from './servisler.module.scss';
import { Box } from '@mui/system';
import moment from 'moment';
import { MonthPicker } from '@mui/x-date-pickers';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import CustomMenu from '../../../components/molecules/CustomMenu';
import { useNavigate } from 'react-router-dom';
import { Info } from '@mui/icons-material';

const Page = () => {
    const navigate = useNavigate()
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [month, setMonth] = useState(new Date())
    const [totalVoyagePrice, setTotalVoyagePrice] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const columns = [
        {
            field: 'licensePlate',
            headerName: "Araç Plakası",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value, row }) => row.vehicle.licensePlate || "-",
        },
        {
            field: 'voyageValue',
            headerName: "Gerçekleşen Sefer Sayısı",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value, row }) => row.totalVoyage || "0",
        },
        {
            field: 'voyagePrice',
            headerName: "Hakedilen Tutar",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value, row }) => row.totalVoyagePrice ? row.totalVoyagePrice + " ₺" : "0",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ value }) => <CustomMenu>
                <MenuItem onClick={() => navigate(`/app/servisler/hakediş/${value}/detay/${month}`)}>
                    <ListItemIcon>
                        <Info fontSize="small" />
                    </ListItemIcon>
                    Detaylar
                </MenuItem>
            </CustomMenu>,
        },
    ]

    const getReports = async () => {
        setIsTableLoading(true);
        try {
            let res = await getDailyTaskVehicleReportAll(moment(month).format("yyyy-MM-DD"));
            if (res) {
                setData(res.data.dailyTaskVehicleReport)
                setData2([...res.data.dailyTaskVehicleReport, { price: res.data.totalVoyagePrice, id: "priceTotal", driverConfirm: 'none' }]);
                setTotalVoyagePrice(res.data.totalVoyagePrice)
            }
        } catch (error) { }
        setIsTableLoading(false);
    }

    useEffect(() => {
        getReports();
    }, [month])

    //Excel Raporlama
    const dataExcel = data2.map((elt) => (
        { licensePlate: elt?.vehicle?.licensePlate || "", totalVoyage: elt.totalVoyage === 0 ? "0" : elt.totalVoyage, totalVoyagePrice: elt.totalVoyagePrice === 0 ? "0" : elt.totalVoyagePrice, price: elt.price === 0 ? "0" : elt.price }
    ))
    const headersExcel = [
        { label: "Araç Plakası", key: "licensePlate" },
        { label: "Gerçekleşen Sefer Sayısı", key: "totalVoyage" },
        { label: "Hakedilen Tutar", key: "totalVoyagePrice" },
        { label: "", key: "Space" },
        { label: "Toplam Hak Edilen Sefer Ücreti", key: "price" },
    ]

    return (
        <PageLayout
            title="Servis Bazlı Hakediş"
            buttons={[]}>
            <>
                <Box className={styles.layout}>
                    <Box className={styles.header}>
                        <Typography
                            variant="h6"
                            sx={{}}
                        >
                            | Raporlar
                        </Typography>
                        <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                            {moment(month).format("MMMM")}
                        </Button>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center"
                            }}
                        >
                            <MonthPicker
                                /* className={styles.picker} */
                                value={month}
                                onChange={(newValue) => setMonth(newValue)}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        size="small"
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: "GG.AA.YYYY"
                                        }}
                                    />
                                }
                            />
                        </Popover>
                    </Box>
                    <Box className={styles.right}>
                        <Box className={styles.priceLayout}>
                            Hak Edilen Sefer Ücreti = {totalVoyagePrice} ₺
                        </Box>
                        <CSVLink data={dataExcel} headers={headersExcel} separator={";"} filename={"hakediş.csv"}>
                            <Button startIcon={<DownloadIcon />} variant="outlined" sx={{ color: "#4CAF50", borderColor: "#4CAF50", minWidth: "100px" }} >Excel</Button>
                        </CSVLink>
                    </Box>
                </Box>
                <CustomDataGrid
                    data={data}
                    columns={columns}
                    loading={isTableLoading}
                    rowId={(params) => params?.id}
                />
            </>
        </PageLayout >
    );
}

export default Page;