import { useEffect, useState } from 'react';
import { Grid, Box, IconButton, Tab, TextField, Autocomplete, InputAdornment } from '@mui/material';
import styles from '../tabs.module.scss';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { updateCustomerRequest } from '../../../../api/controllers/customer-controller';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';
import PhoneTextfield from '../../../atoms/PhoneTextfield';
import { getAddress } from '../../../Utils';
import { useNavigate } from 'react-router';
import { useCustomer } from '../../../../context/CustomerContext';
import { getManagerCustomerPermissionsRequest} from "../../../../api/controllers/manager-controller"
import {useAuth} from "../../../../context/AuthContext"

export default function Edit() {
    const { customer, setCustomer } = useCustomer();
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [value, setValue] = useState('1');
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const { currentUser } = useAuth();
    const {role} = currentUser

    const [managerPermission, setManagerPermission] = useState([]);

  useEffect(() => {
    if (customer.id && role === "MANAGER") {
        (async () => {
        let res = await getManagerCustomerPermissionsRequest(
          currentUser?.id,
          customer.id
        );
        console.log(res);
        if (res) {
          setManagerPermission(res.data);
        }
      })();
    }
  }, [customer.id]);

  if(!managerPermission?.includes("CUSTOMER_EDIT") && role === "MANAGER") navigate(`/app/musteriler/${customer.id}/detay`)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const validationSchema = yup.object({
        title: yup
            .string()
            .required('Bu alan gereklidir'),
        username: yup
            .string()
            .required('Bu alan gereklidir'),
        taxNumber: yup
            .string()
            .max(11, 'Vergi numarası 11 haneden fazla olamaz')
            .min(10, 'Vergi numarası 10 haneden kısa olamaz'),
        firstName: yup
            .string()
            .required('Bu alan gereklidir'),
        lastName: yup
            .string()
            .required('Bu alan gereklidir'),
        password: yup
            .string()
            .min(6, 'Şifre en az 6 haneli olmalıdır'),
        rePassword: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Şifreler uyuşmuyor'),
        phone: yup.object({
            number: yup
                .string()
                .required('Bu alan gereklidir')
                .length(10, 'Telefon numarası 10 haneli olmalıdır'),
        }),
        email: yup
            .string()
            .required('Bu alan gereklidir')
            .email('Email adresini doğru formatta girin'),
        address: yup.object({
            province: yup
                .string()
                .required('Bu alan gereklidir')
                .nullable(),
            district: yup
                .string()
                .required('Bu alan gereklidir')
                .nullable(),
            neighborhood: yup
                .string()
                .required('Bu alan gereklidir')
                .nullable(),
            street: yup
                .string()
                .required('Bu alan gereklidir'),
            buildingInformation: yup
                .string()
                .required('Bu alan gereklidir'),
        }),
        voyagePrice: yup
            .number()
            .required('Bu alan gereklidir'),
        delayedCost: yup
            .number()
            .required('Bu alan gereklidir'),

    })

    const formik = useFormik({
        initialValues: customer,
        validationSchema: validationSchema,
        onSubmit: () => onSubmit(),
    });

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await updateCustomerRequest(formik.values);
            if (res && res.status === 200) {
                formik.resetForm();
                toast.success("Düzenleme kaydedildi");
                navigate(`/app/musteriler/${customer?.id}/detay`);
                setCustomer(res.data)
            }
        } catch (error) { }
        setIsSubmitting(false);
    }


    useEffect(() => {
        getAddress({ setProvinces, setDistricts, setNeighborhoods, province: formik.values.address?.province, district: formik.values.address?.district })
    }, [formik.values.address?.province, formik.values.address?.district])


    return (
        <form onSubmit={formik.handleSubmit}>
            <Box className={styles.edit_page}>
                <Box className={styles.edit_title}>
                    <Box display="flex" alignItems="center">
                        <IconButton type="button" onClick={() => navigate(`/app/musteriler/${customer?.id}/detay`)}>
                            <i className="bi bi-arrow-left-circle" />
                        </IconButton>
                        Düzenle
                    </Box>
                    <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                        Kaydet
                    </LoadingButton>
                </Box>
                <TabContext value={value}>
                    <Box sx={{ mb: "1rem", borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Temel Bilgiler" value="1" />
                            <Tab label="İletişim Bilgileri" value="2" />
                            <Tab label="Sefer Ücret Bilgileri" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Grid container spacing={3} >
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="title"
                                    label="Ünvan"
                                    placeholder="Şirket ünvanını girin"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.title}
                                    error={formik.touched.title && formik.errors.title}
                                    helperText={formik.touched.title && formik.errors.title}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="username"
                                    label="Kullanıcı Adı"
                                    placeholder="Kullanıcı adını girin"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.username}
                                    error={formik.touched.username && formik.errors.username}
                                    helperText={formik.touched.username && formik.errors.username}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NumericFormat
                                    name="taxNumber"
                                    label="Vergi Numarası"
                                    placeholder="Vergi numarasını girin"
                                    fullWidth
                                    onValueChange={({ value }) => formik.setFieldValue("taxNumber", value)}
                                    value={formik.values.taxNumber}
                                    customInput={TextField}
                                    error={formik.touched.taxNumber && formik.errors.taxNumber}
                                    helperText={formik.touched.taxNumber && formik.errors.taxNumber}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="taxAdministration"
                                    label="Vergi Dairesi"
                                    placeholder="Vergi dairesini girin"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.taxAdministration}
                                    error={formik.touched.taxAdministration && formik.errors.taxAdministration}
                                    helperText={formik.touched.taxAdministration && formik.errors.taxAdministration}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="firstName"
                                    label="Yetkili İsmi"
                                    placeholder="Yetkili ismini girin"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.firstName}
                                    error={formik.touched.firstName && formik.errors.firstName}
                                    helperText={formik.touched.firstName && formik.errors.firstName}

                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="lastName"
                                    label="Yetkili Soyismi"
                                    placeholder="Yetkili soyismini girin"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.lastName}
                                    error={formik.touched.lastName && formik.errors.lastName}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    type="password"
                                    name="password"
                                    label="Şifre"
                                    placeholder="Şifre girin"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    error={formik.touched.password && formik.errors.password}
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    type="password"
                                    name="rePassword"
                                    label="Şifre (Tekrar)"
                                    placeholder="Şifreyi tekrar girin"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.rePassword}
                                    error={formik.touched.rePassword && formik.errors.rePassword}
                                    helperText={formik.touched.rePassword && formik.errors.rePassword}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                        <Grid container spacing={3} >
                            <Grid item xs={12} md={6}>
                                <PhoneTextfield
                                    name="phone.number"
                                    phone={formik.values.phone}
                                    onChange={({ value }) => formik.setFieldValue("phone.number", value)}
                                    error={formik.touched.phone?.number && formik.errors.phone?.number}
                                    helperText={formik.touched.phone?.number && formik.errors.phone?.number}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="email"
                                    label="Email Adresi"
                                    placeholder="Email adresini girin"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    error={formik.touched.email && formik.errors.email}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    options={provinces.map(p => p?.provinceName)}
                                    value={formik.values.address?.province}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue("address.province", newValue);
                                        formik.setFieldValue("address.district", null);
                                        formik.setFieldValue("address.neighborhood", null);
                                    }}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label='İl'
                                            fullWidth
                                            error={formik.touched.address?.province && formik.errors.address?.province}
                                            helperText={formik.touched.address?.province && formik.errors.address?.province}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    disabled={!formik.values.address?.province}
                                    options={districts.map(d => d?.districtName)}
                                    value={formik.values.address?.district}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue("address.district", newValue);
                                        formik.setFieldValue("address.neighborhood", null);
                                    }}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label='İlçe'
                                            fullWidth
                                            error={formik.touched.address?.district && formik.errors.address?.district}
                                            helperText={formik.touched.address?.district && formik.errors.address?.district}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    disabled={!formik.values.address?.district}
                                    options={neighborhoods.map(n => n?.neighborhoodName)}
                                    value={formik.values.address?.neighborhood}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue("address.neighborhood", newValue)
                                    }}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label='Mahalle'
                                            fullWidth
                                            error={formik.touched.address?.neighborhood && formik.errors.address?.neighborhood}
                                            helperText={formik.touched.address?.neighborhood && formik.errors.address?.neighborhood}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="address.street"
                                    label="Cadde / Sokak"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.address?.street}
                                    error={formik.touched.address?.street && formik.errors.address?.street}
                                    helperText={formik.touched.address?.street && formik.errors.address?.street}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="address.buildingInformation"
                                    label="Bina Numarası"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.address?.buildingInformation}
                                    error={formik.touched.address?.buildingInformation && formik.errors.address?.buildingInformation}
                                    helperText={formik.touched.address?.buildingInformation && formik.errors.address?.buildingInformation}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="address.fullAddress"
                                    label="Açıklama"
                                    fullWidth
                                    placeholder="Eklemek istediklerinizi bu alana yazabilirsiniz"
                                    onChange={formik.handleChange}
                                    value={formik.values.address?.fullAddress}
                                    multiline
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="3">
                        <Grid container spacing={3} >
                            <Grid item xs={12} md={6}>
                                <NumericFormat
                                    name="voyagePrice"
                                    label="Sefer Ücreti (Tek Yön)"
                                    placeholder="Sefer Ücretini Giriniz (Tek Yön)"
                                    fullWidth
                                    onValueChange={({ floatValue }) =>
                                        formik.setFieldValue("voyagePrice", floatValue)
                                    }
                                    value={formik.values.voyagePrice}
                                    customInput={TextField}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <span>&#8378;</span>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={formik.touched.voyagePrice && formik.errors.voyagePrice}
                                    helperText={formik.touched.voyagePrice && formik.errors.voyagePrice}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NumericFormat
                                    name="delayedCost"
                                    label="Sefer Gecikme Ücreti"
                                    placeholder="Sefer Gecikme Ücretini Giriniz"
                                    fullWidth
                                    onValueChange={({ floatValue }) =>
                                        formik.setFieldValue("delayedCost", floatValue)
                                    }
                                    value={formik.values.delayedCost}
                                    customInput={TextField}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <span>&#8378;</span>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={formik.touched.delayedCost && formik.errors.delayedCost}
                                    helperText={formik.touched.delayedCost && formik.errors.delayedCost}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Box >
        </form>
    )
}
