import { ReactNode, useMemo } from 'react'
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import styles from './style.module.scss';

type IProps = {
    children: ReactNode,
    eventHandlers?: { click: () => void },
    position: any,
    isSelected?: boolean,
    isPassive?: boolean,
    /* props: any */
}

const TravelerMarker = (props: IProps) => useMemo(() => {
    const html = renderToStaticMarkup(
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28 12C28 18.6274 19.5 32 16 32C12.5 32 4 18.6274 4 12C4 5.37258 9.37258 0 16 0C22.6274 0 28 5.37258 28 12Z" fill={props.isSelected ? "var(--info)" : props.isPassive ? "var(--secondary)" : "var(--primary)"} />
            <rect x="7" y="3" width="18" height="18" rx="9" fill="#FEFEFE" />
            <path d="M12.25 16.5C12.25 16.5 11.5 16.5 11.5 15.75C11.5 15 12.25 12.75 16 12.75C19.75 12.75 20.5 15 20.5 15.75C20.5 16.5 19.75 16.5 19.75 16.5H12.25ZM16 12C16.5967 12 17.169 11.7629 17.591 11.341C18.0129 10.919 18.25 10.3467 18.25 9.75C18.25 9.15326 18.0129 8.58097 17.591 8.15901C17.169 7.73705 16.5967 7.5 16 7.5C15.4033 7.5 14.831 7.73705 14.409 8.15901C13.9871 8.58097 13.75 9.15326 13.75 9.75C13.75 10.3467 13.9871 10.919 14.409 11.341C14.831 11.7629 15.4033 12 16 12Z" fill={props.isSelected ? "var(--info)" : props.isPassive ? "var(--secondary)" : "var(--primary)"} />
        </svg>
    )

    const icon = new (L.divIcon as any)({
        iconAnchor: [16, 32],
        shadowAnchor: null,
        iconSize: [32, 32],
        className: styles.marker,
        html: html,
    });


    return (
        <Marker
            eventHandlers={props.eventHandlers || {}}
            icon={icon}
            position={props.position}
        /* {...props} */
        >
            {props.children}
        </Marker>
    )
}, [props.isSelected, props.isPassive, JSON.stringify(props.position)])

export default TravelerMarker;