import { Tooltip } from 'react-leaflet';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { deleteStationEmployeeRequest } from '../../api/controllers/station-employee-controller';
import TravelerMarker from '../../components/atoms/TravelerMarker';
import { usePlans } from '../../context/PlansContext';
import { Traveler, UpdateForm } from '../../types/PlansContext';
import { createStationForEmployeeRequest } from '../../api/controllers/station-controller';

type IProps = {
    traveler: Traveler;
}

const StudentTravelerMarker = ({ traveler }: IProps) => {
    const { updateForm, setUpdateForm, getTravelers, stationForm, setStationForm, } = usePlans();
    const { workTimeId, planType } = useParams();

    const handleClick = async (traveler: Traveler) => {
        const station = await updateForm.plan.stationList?.find(v => v.travelers[0]?.id === traveler.id);
        if (station) {
            let res = await deleteStationEmployeeRequest(station.id);
            if (res) {
                setUpdateForm((prev: UpdateForm) => ({ ...prev, plan: { ...prev.plan, stationList: prev.plan.stationList?.filter(v => v.id !== station.id) } }));
                getTravelers(Number(workTimeId));
            }
        } else {
            let res = await createStationForEmployeeRequest(updateForm.plan.id, {
                ...stationForm,
                sequence: null,
                title: `${traveler.firstName || ""} ${traveler.lastName || ""}`,
                lat: traveler.address.position.latitude,
                lng: traveler.address.position.longitude,
                travelerIds: [traveler]
            });
            if (res) {
                setUpdateForm((prev: UpdateForm) => ({ ...prev, plan: { ...prev.plan, stationList: [...prev.plan.stationList, res.data] } }));
                getTravelers(Number(workTimeId));
            }
        }
    }

    if (window.location.pathname.includes("duzenle") && traveler?.address?.position) {
        const isSelected = updateForm.plan.stationList?.some(v => v.travelers[0]?.id === traveler.id);
        return (
            <TravelerMarker
                position={[traveler.address.position.latitude, traveler.address.position.longitude]}
                eventHandlers={{
                    click: () => (traveler[planType as keyof Pick<Traveler, "arrival" | "departure">]?.enable || isSelected) ? handleClick(traveler) : toast.warning("Seçtiğiniz yolcu bir plana bağlıdır!"),
                }}
                isSelected={isSelected}
                isPassive={!traveler[planType as keyof Pick<Traveler, "arrival" | "departure">]?.enable}
            >
                <Tooltip direction="bottom">{`${traveler.firstName || ""} ${traveler.lastName || ""}`}</Tooltip>
            </TravelerMarker>
        )
    } else if (traveler?.address?.position) {
        return (
            <TravelerMarker
                position={[traveler?.address?.position.latitude, traveler?.address?.position.longitude]}
                isPassive={!traveler[planType as keyof Pick<Traveler, "arrival" | "departure">]?.enable}
            >
                <Tooltip direction="bottom">{`${traveler.firstName || ""} ${traveler.lastName || ""}`}</Tooltip>
            </TravelerMarker>
        )
    } else return null
}

export default StudentTravelerMarker;