import React from 'react'
import styles from './style.module.scss';
import { Box, IconButton, Tooltip } from '@mui/material';
import { CheckCircleRounded, DeleteRounded } from '@mui/icons-material';
import { deleteBgImageRequest, setSelectedBgImageRequest } from '../../../api/controllers/background-controller';
import { BASE_URL } from '../../../api/ApiProvider';
import { toast } from 'react-toastify';

function View(props) {

    const handleDelete = async () => {
        const confirm = await window.confirm("Silmek istediğinize emin misiniz?");
        if (confirm) {
            let res = await deleteBgImageRequest(props.image.id);
            if (res) {
                props.getBgImages();
                toast.success("Resim silindi")
            }
        }
    }

    const handleUpdate = async () => {
        let res = await setSelectedBgImageRequest(props.image.id);
        if (res) {
            props.getBgImages();
            toast.success("Giriş yap sayfası arkaplanı değiştirildi")
        }
    }

    return (
        <Box className={`${styles.view_wrapper} ${props.image.selected ? styles.selected : ""}`}>
            <Box className={styles.overlay}>
                <Tooltip title="Sil">
                    <IconButton onClick={handleDelete} className={styles.button}>
                        <DeleteRounded className={styles.delete} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Aktif et">
                    <IconButton onClick={handleUpdate} className={styles.button}>
                        <CheckCircleRounded className={styles.active} />
                    </IconButton>
                </Tooltip>
            </Box>
            <img src={`${BASE_URL}${props.image.url}`} alt="" />
        </Box>
    )
}

export default View