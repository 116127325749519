import { CheckRounded, CloseRounded, } from '@mui/icons-material';
import { Typography, TextField, Button, Popover, Tooltip, Avatar, } from '@mui/material';
import { Box } from '@mui/system';
import { MonthPicker } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getDailyTaskCustomerReport } from '../../../../api/controllers/daily-task-controller';
import { useCustomer } from '../../../../context/CustomerContext';
import CustomDataGrid from '../../../atoms/CustomDataGrid';
import styles from './style.module.scss';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from 'react-csv';

export default function Reports() {
    const { customer } = useCustomer();
    const navigate = useNavigate();

    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [month, setMonth] = useState(new Date())
    const [totalVoyagePrice, setTotalVoyagePrice] = useState(null)

    const getData = async () => {
        setIsTableLoading(true);
        try {
            let res = await getDailyTaskCustomerReport(customer?.id, moment(month).format("yyyy-MM-DD"));
            if (res) {
                setData(res.data.dailyTasks)
                setData2([...res.data.dailyTasks, { price: res.data.totalVoyagePrice, id: "price", driverConfirm: 'none' }]);
                setTotalVoyagePrice(res.data.totalVoyagePrice)
            }
        } catch (error) { }
        setIsTableLoading(false);
    }

    useEffect(() => {
        getData();
    }, [month])

    //Tablo sütunları
    const columns = [
        {
            field: 'driverConfirm',
            headerName: "Durum",
            sortable: false,
            renderCell: ({ value }) => (
                <Tooltip title={value ? "Tamamlandı" : "Tamamlanmayı Bekliyor"}>
                    <Avatar sx={{ width: 24, height: 24, fontSize: 14, bgcolor: value ? "var(--success_12)" : "var(--danger_12)" }}                >
                        {value ? <CheckRounded htmlColor="var(--success)" fontSize="inherit" /> : <CloseRounded htmlColor="var(--danger)" fontSize="inherit" />}
                    </Avatar>
                </Tooltip>
            ),
        },
        {
            field: 'planTitle',
            headerName: "Sefer Başlığı",
            flex: 1,
            minWidth: 150,
            renderCell: ({ row }) => <Tooltip title={row.planTitle || "-"} >
                <p>
                    {row.planTitle || "-"}
                </p>
            </Tooltip>
        },
        {
            field: 'date',
            headerName: "Tarih",
            flex: 1,
            minWidth: 120,
            valueGetter: ({ value }) => value ? value : "-",
        },
        {
            field: 'workTime',
            headerName: "Zaman",
            flex: 1,
            minWidth: 100,
            valueGetter: ({ value, row }) => row.startTime.slice(0, 5) || "-",
        },
        {
            field: 'dailyTaskType',
            headerName: "Sefer Tipi",
            flex: 1,
            minWidth: 150,
            valueGetter: ({ value }) => value ? value === 'REGULAR' ? 'Tek Yön' : value === 'DELAYED' ? 'Tek Yön Ertelenmiş' : value === 'OVERTIME' ? 'Ek Mesai' : value === 'VOYAGE' ? 'Ek Mesai' : value : "-",
        },
        {
            field: 'voyagePrice',
            headerName: "Ücreti",
            flex: 1,
            minWidth: 120,
            valueGetter: ({ value }) => value ? value + " ₺" : "-",
        },
        /*  {
             field: 'id',
             headerName: "",
             sortable: false,
             align: 'right',
             renderCell: ({ value }) => <CustomMenu>
                 <MenuItem onClick={() => navigate(`/app/musteriler/${value}/detay`)}>
                     <ListItemIcon>
                         <Info fontSize="small" />
                     </ListItemIcon>
                     Detaylar
                 </MenuItem>
             </CustomMenu>,
         }, */
    ]

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [value, setValue] = React.useState(null);

    //Excel Raporlama
    const dataExcel = data2.map((elt) => (
        { planTitle: elt.planTitle || "", date: elt.date || "", time: elt.startTime?.slice(0, 5) || "", dailyTaskType: elt.dailyTaskType === 'REGULAR' ? "Tek Yön" : elt.dailyTaskType === 'DELAYED' ? 'Tek Yön Ertelenmiş' : elt.dailyTaskType === 'OVERTIME' ? 'Ek Mesai' : elt.dailyTaskType === 'VOYAGE' ? 'Ek Mesai' : "", voyagePrice: elt.voyagePrice || "", driverConfirm: elt.driverConfirm === true ? 'Tamamlandı' : elt.driverConfirm === false ? 'Tamamlanmadı' : elt.driverConfirm === 'none' ? "" : "-", totalVoyagePrice: elt.price === 0 ? "0" : elt.price || "", space: "" }
    ))
    const headersExcel = [
        { label: "Tarih", key: "date" },
        { label: "Zaman", key: "time" },
        { label: "Açıklama", key: "planTitle" },
        { label: "Sefer Tipi", key: "dailyTaskType" },
        { label: "Ücreti", key: "voyagePrice" },
        { label: "Durum", key: "driverConfirm" },
        { label: "", key: "space" },
        { label: "Toplam Hak Edilen Sefer Ücreti", key: "totalVoyagePrice" }
    ]

    return (
        <>
            <Box className={styles.layout}>
                <Box className={styles.header}>
                    <Typography
                        variant="h6"
                        sx={{}}
                    >
                        | Raporlar
                    </Typography>
                    <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                        {moment(month).format("MMMM")}
                    </Button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}
                    >
                        <MonthPicker
                            /* className={styles.picker} */
                            value={month}
                            onChange={(newValue) => setMonth(newValue)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    size="small"
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: "GG.AA.YYYY"
                                    }}
                                />
                            }
                        />
                    </Popover>
                </Box>
                <Box className={styles.right}>
                    <Box className={styles.priceLayout}>
                        Hak Edilen Sefer Ücreti = {totalVoyagePrice} ₺
                    </Box>
                    <CSVLink data={dataExcel} headers={headersExcel} separator={";"} filename={"hakediş.csv"}>
                        <Button startIcon={<DownloadIcon />} variant="outlined" sx={{ color: "#4CAF50", borderColor: "#4CAF50", minWidth: "100px" }} >Excel</Button>
                    </CSVLink>
                </Box>
            </Box>
            <CustomDataGrid
                data={data}
                columns={columns}
                loading={isTableLoading}
                rowId={(params) => params?.id}
            />
        </>
    )
}