import { useEffect, useState } from 'react';
import { Grid, Box, Typography, TextField, Autocomplete } from '@mui/material';
import styles from '../../tabs.module.scss';
import { useFormik } from 'formik';
import PhoneTextfield from '../../../../atoms/PhoneTextfield';
import CustomDialog from '../../../../atoms/CustomDialog';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { updateStudentRequest } from '../../../../../api/controllers/student-controller';
import { getWorkTimesByCustomerIdRequest } from '../../../../../api/controllers/work-time-controller';
import { PatternFormat } from 'react-number-format';
import ActionPageTitle from '../../../../atoms/ActionPageTitle';
import { getParentsByCustomerRequest, getParentsRequest, saveParentRequest } from '../../../../../api/controllers/parent-controller';
import AddressModal from '../../../../molecules/AddressModal';
import { useNavigate } from 'react-router-dom';

export default function UpdateStudent({ student, customer }) {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [createParentModal, setCreateParentModal] = useState(false);
    const [workTimes, setWorkTimes] = useState([]);
    const [parents, setParents] = useState([]);
    const navigation = useNavigate()

    const formik = useFormik({
        validationSchema: yup.object({
            firstName: yup
                .string()
                .required('Bu alan gereklidir'),
            lastName: yup
                .string()
                .required('Bu alan gereklidir'),
            idNum: yup
                .string()
                .max(11, 'Kimlik numarası 11 haneli olmalıdır')
                .nullable(),
            parent: yup
                .object()
                .required('Bu alan gereklidir')
                .nullable(),
            workTime: yup
                .object()
                .required('Bu alan gereklidir')
                .nullable(),
            address: yup.object({
                province: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                district: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                neighborhood: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                street: yup
                    .string()
                    .required('Bu alan gereklidir'),
                buildingInformation: yup
                    .string()
                    .required('Bu alan gereklidir'),
            }),
        }),
        initialValues: student,
        onSubmit: () => onSubmit()
    });

    const getParents = async (filter) => {
        let res = await getParentsByCustomerRequest(filter || "", customer.id);
        if (res) {
            setParents(res.data);
        }
    }

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await updateStudentRequest(formik.values);
            if (res) {
                toast.success("Öğrenci güncellendi");
                navigation.navigate(``)
            }
        } catch (error) { }
        setIsSubmitting(false);
    }


    useEffect(() => {
        getParents();

        (async () => {
            let res = await getWorkTimesByCustomerIdRequest(customer.id);
            if (res) {
                setWorkTimes(res.data);
            }
        })();
    }, [])

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <ActionPageTitle
                    title="Yolcu Düzenle"
                    buttonText="Kaydet"
                    buttonProps={{
                        loading: isSubmitting,
                        type: "submit",
                        variant: "contained",
                    }}
                />

                <Typography variant="h6" my="1rem">
                    | Öğrenciye ait bilgileri girin
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="firstName"
                            label="İsim"
                            placeholder="Öğrenci ismini girin"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            error={formik.touched.firstName && formik.errors.firstName}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="lastName"
                            label="Soyisim"
                            placeholder="Öğrenci soyismini girin"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            error={formik.touched.lastName && formik.errors.lastName}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PatternFormat
                            format="###########"
                            name="idNum"
                            label="T.C. Kimlik Numarası"
                            placeholder="T.C. kimlik numarasını girin"
                            fullWidth
                            onValueChange={({ value }) => formik.setFieldValue("idNum", value)}
                            value={formik.values.idNum}
                            customInput={TextField}
                            error={formik.touched.idNum && formik.errors.idNum}
                            helperText={formik.touched.idNum && formik.errors.idNum}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="classRoom"
                            label="Sınıf"
                            placeholder="Öğrenci sınıfını girin"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.classRoom}
                            error={formik.touched.classRoom && formik.errors.classRoom}
                            helperText={formik.touched.classRoom && formik.errors.classRoom}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={parents}
                            value={formik.values.parent}
                            onChange={(event, newValue) => formik.setFieldValue("parent", newValue || null)}
                            getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onBlur={() => getParents()}
                            onInputChange={(event, newValue) => getParents(newValue)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label='Veli'
                                    fullWidth
                                    placeholder="İsim veya telefon numarası ile arama yapabilirsiniz"
                                    error={formik.touched.parent && formik.errors.parent}
                                    helperText={formik.touched.parent && formik.errors.parent}
                                />}
                        />
                        <Box display="flex">
                            <button
                                type="button"
                                onClick={() => setCreateParentModal(true)}
                                className={styles.input_add_new}>+ Yeni veli ekle</button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={workTimes}
                            value={formik.values.workTime}
                            onChange={(event, newValue) => formik.setFieldValue("workTime", newValue)}
                            getOptionLabel={option => `${option.title} (${option.startTime.slice(0, 5)} - ${option.endTime.slice(0, 5)})`}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label='Vardiya'
                                    fullWidth
                                    error={formik.touched.workTime && formik.errors.workTime}
                                    helperText={formik.touched.workTime && formik.errors.workTime}
                                />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AddressModal formik={formik} />
                    </Grid>
                </Grid>
            </form >
            <CreateParent
                createParentModal={createParentModal}
                setCreateParentModal={setCreateParentModal}
                studentForm={formik}
                getParents={getParents}
            />
        </>
    )
}


function CreateParent({ createParentModal, setCreateParentModal, studentForm, getParents }) {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const formik = useFormik({
        validationSchema: yup.object({
            firstName: yup
                .string()
                .required('Bu alan gereklidir'),
            lastName: yup
                .string()
                .required('Bu alan gereklidir'),
            phone: yup.object({
                number: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .length(10, 'Telefon numarası 10 haneli olmalıdır'),
            }),
        }),
        initialValues: {
            email: "",
            firstName: "",
            lastName: "",
            phone: {
                areaCode: "+90",
                countryCode: "",
                number: ""
            },
            username: ""
        },
        onSubmit: () => onSubmit()
    });

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await saveParentRequest(formik.values);
            if (res) {
                toast.success("Veli kaydedildi");
                handleClose();
                getParents();
                studentForm.setFieldValue("parent", res.data);
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    const handleClose = () => {
        setCreateParentModal(false);
        formik.resetForm();
    };

    return (
        <CustomDialog
            size="sm"
            open={createParentModal}
            closeFunc={handleClose}
            title="Veli Ekle"
            onSubmit={formik.handleSubmit}
            buttons={[
                {
                    type: "submit",
                    loading: isSubmitting,
                    variant: "contained",
                    text: "Kaydet",
                }
            ]}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        name="firstName"
                        label="Veli İsmi"
                        placeholder="Velinin ismini yazın"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        error={formik.touched.firstName && formik.errors.firstName}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                </Grid>
                <Grid item xs={12}>

                    <TextField
                        name="lastName"
                        label="Veli Soyismi"
                        placeholder="Velinin soyismini yazın"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        error={formik.touched.lastName && formik.errors.lastName}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PhoneTextfield
                        name="phone.number"
                        phone={formik.values.phone}
                        onChange={({ value }) => formik.setFieldValue("phone.number", value)}
                        error={formik.touched.phone?.number && formik.errors.phone?.number}
                        helperText={formik.touched.phone?.number && formik.errors.phone?.number}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="email"
                        label="Mail Adresi "
                        placeholder="Velinin mail adresini yazın"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={formik.touched.email && formik.errors.email}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </Grid>
            </Grid>
        </CustomDialog >
    )
}