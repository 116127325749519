import { useEffect, useRef, useState } from 'react'
import CustomDialog from '../../atoms/CustomDialog'
import { Autocomplete, Grid, TextField } from '@mui/material'
import { getAddress } from '../../Utils';
import { MAP_DEFAUTLS } from '../../atoms/Leaflet';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { toast } from 'react-toastify';
import { getGeocodeRequest } from '../../../api/controllers/addresses-controller';
import L from 'leaflet';
import travelerMarker from '../../../assets/markers/traveler.svg';
import customerMarker from '../../../assets/markers/customer.svg';

function AddressModal(props) {
    const mapRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const address = props.formik.values.address;
    const [isDragged, setIsDragged] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const icon = new L.Icon({
        iconUrl: props.customer ? customerMarker : travelerMarker,
        iconRetinaUrl: props.customer ? customerMarker : travelerMarker,
        iconAnchor: [16, 32],
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: [32, 32],
    });

    useEffect(() => {
        getAddress({ setProvinces, setDistricts, setNeighborhoods, province: address.province, district: address.district });
    }, [address.province, address.district])

    useEffect(() => {
        if (!isDragged) {
            setTimeout(async () => {
                try {
                    let res = await getGeocodeRequest(address);
                    if (res) {
                        if (!Object.values(res.data).includes(null)) {
                            props.formik.setFieldValue("address.position", res.data);
                        }
                    }
                } catch (error) {
                    toast.error("Konum haritada gösterilemedi!");
                }
            }, 500);
        }
    }, [JSON.stringify(Object.values(address))]);

    useEffect(() => {
        if (mapRef.current && address.position.latitude && address.position.longitude) {
            mapRef.current.flyTo([address.position.latitude, address.position.longitude], 17)
        }
    }, [mapRef.current, JSON.stringify(Object.values(address.position))]);


    return (
        <>
            <TextField
                label="Adres"
                placeholder="Adresi girin"
                fullWidth
                value={`${address.buildingInformation ? address.buildingInformation + ", " : ""} ${address.street ? address.street + " - " : ""} ${address.neighborhood ? address.neighborhood + "/" : ""}${address.district ? address.district + "/" : ""}${address.province || ""}`}
                onClick={() => setOpen(true)}
                error={props.formik.errors?.address || null}
                helperText={props.formik.errors?.address && "Adresi kontrol edin!"}
                InputProps={{
                    readOnly: true,
                }}
            />
            <CustomDialog
                size="md"
                open={open}
                closeFunc={handleClose}
                title="Adres"
                buttons={[
                    {
                        type: "button",
                        onClick: handleClose,
                        variant: "contained",
                        text: "Kaydet",
                    }
                ]}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <MapContainer
                            ref={mapRef}
                            style={{ width: "100%", height: "100%", minHeight: "20rem", borderRadius: "1rem", overflow: "hidden" }}
                            {...MAP_DEFAUTLS}
                            minZoom={6}
                            doubleClickZoom={false}
                        >
                            <TileLayer
                                url='https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            />
                            {
                                !Object.values(address.position).includes(null) &&
                                <Marker
                                    position={[address.position.latitude, address.position.longitude]}
                                    draggable={address.province && address.district && address.neighborhood ? true : false}
                                    icon={icon}
                                    eventHandlers={{
                                        dragend: (e) => {
                                            props.formik.setFieldValue("address.position", { latitude: e.target._latlng.lat, longitude: e.target._latlng.lng });
                                            setIsDragged(true);
                                        }
                                    }}
                                />
                            }
                        </MapContainer>
                    </Grid>
                    <Grid container spacing={3} item xs={12} md={6}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={provinces.map(p => p?.provinceName)}
                                value={address?.province}
                                onChange={(event, newValue) => {
                                    setIsDragged(false);
                                    props.formik.setFieldValue("address.province", newValue);
                                    props.formik.setFieldValue("address.district", null);
                                    props.formik.setFieldValue("address.neighborhood", null);
                                }}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label='İl'
                                        fullWidth
                                        error={props.formik.errors?.address?.province || null}
                                        helperText={props.formik.errors?.address?.province || null}
                                    />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                disabled={!address?.province}
                                options={districts.map(d => d?.districtName)}
                                value={address?.district}
                                onChange={(event, newValue) => {
                                    setIsDragged(false);
                                    props.formik.setFieldValue("address.district", newValue);
                                    props.formik.setFieldValue("address.neighborhood", null);
                                }}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label='İlçe'
                                        fullWidth
                                        error={props.formik.errors?.address?.district || null}
                                        helperText={props.formik.errors?.address?.district || null}
                                    />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                disabled={!address?.district}
                                options={neighborhoods.map(n => n?.neighborhoodName)}
                                value={address?.neighborhood}
                                onChange={(event, newValue) => {
                                    setIsDragged(false);
                                    props.formik.setFieldValue("address.neighborhood", newValue)
                                }}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label='Mahalle'
                                        fullWidth
                                        error={props.formik.errors?.address?.neighborhood || null}
                                        helperText={props.formik.errors?.address?.neighborhood || null}
                                    />}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                name="address.street"
                                label="Cadde / Sokak"
                                fullWidth
                                onChange={props.formik.handleChange}
                                value={address?.street}
                                error={props.formik.errors?.address?.street || null}
                                helperText={props.formik.errors?.address?.street || null}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                name="address.buildingInformation"
                                label="Bina No"
                                fullWidth
                                onChange={props.formik.handleChange}
                                value={address?.buildingInformation}
                                error={props.formik.errors?.address?.buildingInformation || null}
                                helperText={props.formik.errors?.address?.buildingInformation || null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="address.fullAddress"
                                label="Adres Açıklaması"
                                fullWidth
                                helperText="İsteğe bağlı"
                                placeholder="Eklemek istediklerinizi bu alana yazabilirsiniz"
                                onChange={props.formik.handleChange}
                                value={address?.fullAddress || ""}
                                multiline
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </CustomDialog>
        </>

    )
}

export default AddressModal;