import { RouteRounded, Close } from '@mui/icons-material';
import { Popup } from 'react-leaflet'
import { useNavigate } from 'react-router';
import styles from './style.module.scss';
import moment from 'moment';

const MapVehiclePopup = ({ vehicle }) => {
    const navigate = useNavigate();
    return (
        <Popup
            closeButton={false}
            closeOnClick={false}
        >
            <div className={styles.map_popup} >
                <button
                    className={styles.close_button}
                    onClick={() => navigate("/app/servisler")}
                >
                    <Close />
                </button>
                <p className={styles.title}>{`${vehicle.brand && vehicle.brand} ${vehicle.model && vehicle.model} - ${vehicle.year && vehicle.year}`}</p>
                <div className={styles.info_box}>
                    <span>
                        <i className="bi bi-speedometer"></i>
                        <span>{vehicle.lastLog.speedKmh && vehicle.lastLog.speedKmh.toFixed(0)} km/h</span>
                    </span>
                    <span>
                        <RouteRounded />
                        <span>
                            {vehicle.dailyDistance && new Intl.NumberFormat('tr-TR').format(vehicle.dailyDistance)} km
                        </span>
                    </span>
                </div>
                <p style={{ marginBottom: ".25rem" }}>
                    <i className="bi bi-clock" style={{ marginRight: ".25rem" }}></i>
                    {moment(vehicle.lastLog.mqttLogDateTime).format("LLL")}
                </p>
                <p>
                    <i className="bi bi-geo-alt-fill" style={{ marginRight: ".25rem" }}></i>
                    {vehicle.lastLog?.address?.fullAddress}
                </p>
            </div>
        </Popup >
    )
}

export default MapVehiclePopup;