import axios from "axios";

export const getProvincesRequest = () => axios.get(`/addresses/province`);

export const getDistrictsByProvinceIdRequest = (provinceId) => axios.get(`/addresses/province/${provinceId}/district`);

export const getNeighborhoodsByDistrictIdRequest = (districtId) => axios.get(`/addresses/district/${districtId}/neighborhood`);

export const getMultipleNeighborhoodsByDistrictsIdListRequest = (form) => axios.get(`/addresses/districts?provinceName=${form.provinceName}&idList=${form.idList}`);

export const getGeocodeRequest = (form) => axios.post(`/addresses/geocode`, form);