import axios from "axios";

export const getStationByIdRequest = (id) => axios.get(`/station/${id}`);

export const getStationByPlanIdRequest = (planId) => axios.get(`/station/plan/${planId}`);

export const createStationForEmployeeRequest = (planId, form) => {
    const parsed = form.travelerIds.map(v => v.id);
    form = { ...form, travelerIds: parsed };
    return axios.post(`/station/plan/${planId}`, form);
}