import styles from './style.module.scss';
import { Box, Divider, Button } from '@mui/material';
import { useParams, } from 'react-router-dom';
import MapDisplayButton from '../../atoms/MapDisplayButton';
import traveler_icon from '../../../assets/markers/traveler.svg';
import traveler_gray_icon from '../../../assets/markers/traveler_gray.svg'
import station_icon from '../../../assets/markers/station.svg';
import { MenuRounded, RouteRounded } from '@mui/icons-material';
import { useAuth } from '../../../context/AuthContext';
import moment from 'moment';
import { useOverTime } from '../../../context/OverTimeContext';
import FilterArea from '../FilterArea';

const PlannerNavigation = ({ setShowSidebar }) => {
    const { currentUser } = useAuth();
    const { role } = currentUser;
    const { travelers, plan, plans, filterMarker, setFilterMarker, overTime, setAreaPolygon } = useOverTime();
    const { planType, planUuid } = useParams()

    return (
        <Box component="nav" className={styles.navbar}>
            <Box className={styles.content}>
                <Box className={styles.item}>
                    {overTime?.customer?.title}
                </Box>
                <Box className={styles.item}>
                    {moment(overTime?.startDate).format('LL') + " - " + moment(overTime?.endDate).format('LL')}
                </Box>
                <Box className={styles.item}>
                    {overTime?.workTime?.startTime.slice(0, 5) + " - " + overTime?.workTime?.endTime.slice(0, 5)}
                </Box>
                {
                    (role === "ADMIN" || role === "MANAGER") &&
                    <MapDisplayButton
                        icon={<img src={traveler_icon} alt="" />}
                        count={Array.isArray(travelers) ? travelers.filter(v => v[planType + "Enable"]).length : 0}
                        filterFunc={() => setFilterMarker(prev => ({ ...prev, traveler: { ...prev.traveler, available: !prev.traveler.available } }))}
                        show={!filterMarker.traveler.available}
                    />
                }
                {
                    (role === "ADMIN" || role === "MANAGER") &&
                    <MapDisplayButton
                        icon={<img src={traveler_gray_icon} alt="" />}
                        count={Array.isArray(travelers) ? travelers.filter(v => !v[planType + "Enable"]).length : 0}
                        filterFunc={() => setFilterMarker(prev => ({ ...prev, traveler: { ...prev.traveler, unavailable: !prev.traveler.unavailable } }))}
                        show={!filterMarker.traveler.unavailable}
                    />
                }
                {
                    (role === "ADMIN" || role === "MANAGER") && planUuid &&
                    <MapDisplayButton
                        icon={<img src={station_icon} alt="" />}
                        count={plan?.stationList?.length || 0}
                        filterFunc={() => setFilterMarker(prev => ({ ...prev, station: !prev.station }))}
                        show={!filterMarker.station}
                    />
                }
                {
                    !planUuid &&
                    <MapDisplayButton
                        icon={<RouteRounded fontSize="inherit" />}
                        count={plans[planType]?.length || 0}
                        filterFunc={() => setFilterMarker(prev => ({ ...prev, routes: !prev.routes }))}
                        show={!filterMarker.routes}
                    />
                }
                {
                    (role === "ADMIN" || role === "MANAGER") &&
                    <Divider orientation="vertical" flexItem />
                }
                {
                    (role === "ADMIN" || role === "MANAGER") &&
                    <FilterArea setAreaPolygon={setAreaPolygon} />
                }
            </Box>
            <Button startIcon={<MenuRounded fontSize="inherit" />} variant="contained" onClick={() => setShowSidebar(true)}>
                Menü
            </Button>
        </Box>
    )
}

export default PlannerNavigation;