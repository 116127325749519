import { DataGrid, GRID_DEFAULT_LOCALE_TEXT } from '@mui/x-data-grid'
import { IconButton, Menu } from "@mui/material"
import { MoreVert } from "@mui/icons-material"
import { useState } from 'react'
import { withStyles } from '@mui/styles'

let trLocale = GRID_DEFAULT_LOCALE_TEXT
trLocale.noRowsLabel = "Sonuç Bulunamadı"
trLocale.noResultsOverlayLabel = "Sonuç Bulunamadı"
trLocale.filterPanelColumns = "Sütunlar"
trLocale.filterPanelOperators = "Operatörler"
trLocale.filterPanelInputLabel = "Ara"
trLocale.filterPanelInputPlaceholder = "Tabloda arama yapabilirsiniz"
trLocale.toolbarExportLabel = "Çıktı Al"
trLocale.toolbarExport = "Çıktı Al"
trLocale.toolbarExportCSV = "Csv Olarak Çıktı Al"
trLocale.footerRowSelected = number => `${number} satır seçildi`
trLocale.MuiTablePagination.labelRowsPerPage = "Gösterilen sonuç"
trLocale.MuiTablePagination.labelDisplayedRows = ({ from, to, count }) => `${from}-${to} / ${count} `;

export const TableMenu = ({ menuItems }: any) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVert />
            </IconButton>
            <Menu
                elevation={2}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {menuItems}
            </Menu>
        </div>
    )
}

const StyledDataGrid = withStyles({
    root: {
        "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important",
        },
        "& .MuiDataGrid-cell": {
            lineHeight: "unset !important",
            maxHeight: "none !important",
            whiteSpace: "normal",
        },
        "& .MuiDataGrid-row": {
            maxHeight: "none !important",
        }
    }
})(DataGrid);

type IProps = {
    data: any[],
    columns: any[],
    rowId: any,
    small?: any,
    loading: any,
    pageable: any,
    setPageable: any,
    selectedRows?: any,
    setSelectedRows?: any,
}

const CustomDataGrid = ({ data, columns, rowId, small, loading, pageable, setPageable, selectedRows, setSelectedRows }: IProps) => {
    return (
        <StyledDataGrid
            rows={data}
            columns={columns}
            loading={loading}
            sx={{
                minHeight: small ? "60vh" : "65vh"
            }}
            getRowId={rowId && rowId}
            checkboxSelection={selectedRows ? true : false}
            onSelectionModelChange={setSelectedRows}
            selectionModel={selectedRows}
            localeText={trLocale}
            disableColumnMenu
            pagination
            paginationMode={pageable ? "server" : "client"}
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowCount={pageable && pageable.total}
            page={pageable && pageable.page}
            onPageChange={val => pageable && !loading && setPageable({ ...pageable, page: val })}
            pageSize={pageable && pageable.size}
            onPageSizeChange={val => pageable && setPageable({ ...pageable, size: val })}
        />
    )


}



export default CustomDataGrid;