import axios from "axios";

export const deleteParentRequest = (id) => axios.delete(`/parent/${id}`)

export const getParentsRequest = (filter) => axios.get(`/parent/filter?filter=${filter}`)

export const getParentsByCustomerRequest = (filter, customerId) => axios.get(`/parent/customer/${customerId}/filter?filter=${filter}`)

export const getParentRequest = (id) => axios.get(`/parent/${id}`)

export const saveParentRequest = (form) => axios.post(`/parent`, form)