import axios from "axios";

export const deleteTravelerRequest = (id) => axios.delete(`/traveler/${id}`)

export const getTravelersRequest = (query) => axios.get(`/traveler${query ? `?query=${query}` : ""}`)

export const getTravelersPageableRequest = (pageable, filter) => {
    let search_query = `firstName-lastName=like='${filter.search}'`;
    let customer_filter_query = `workTime.customer.id=in=${filter.customerIdList?.length > 0 ? `(${filter.customerIdList})` : "''"}`;
    let department_filter_query = `department.id=in=${filter.departmentIdList?.length > 0 ? `(${filter.departmentIdList})` : "''"}`;
    let workTime_filter_query = `workTime.id=in=${filter.workTimeIdList?.length > 0 ? `(${filter.workTimeIdList})` : "''"}`;
    let query = `${search_query};${customer_filter_query};${workTime_filter_query};${department_filter_query}`;
    return axios.get(`/traveler/pageable?page=${pageable.page}&size=${pageable.size}&query=${query}`)
}

export const getTravelersByCustomerIdRequest = ({ pageable, customerId }) => axios.get(`/traveler/customer/${customerId}?page=${pageable.page}&size=${pageable.size}`)

export const getTravelersByQueryRequest = (query) => axios.get(`/traveler?query=${query}`)

export const getTravelersByIdListRequest = (idList) => axios.post(`/traveler/getIds`, idList)