import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react'
import { getDocumentTypesOfDriverRequest, saveDocumentTypeRequest } from '../../../api/controllers/document-type-controller';
import PageLayout from '../../../components/templates/PageLayout';
import CustomDataGrid from '../../../components/atoms/CustomDataGrid';
import CustomDialog from '../../../components/atoms/CustomDialog';

const Page = () => {
    const [createModal, setCreateModal] = useState(false);

    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);

    const initialValues = {
        name: "",
        documentCategory: "DRIVER"
    }

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: () => onSubmit()
    });

    const columns = [
        {
            field: 'name',
            headerName: "Belge İsmi",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value : "-",
        },
    ]

    const getDocumentTypes = async () => {
        setIsTableLoading(true);
        try {
            let res = await getDocumentTypesOfDriverRequest();
            if (res) {
                setData(res.data)
            }
        } catch (error) { }
        setIsTableLoading(false);
    }

    const onSubmit = async () => {
        let res = await saveDocumentTypeRequest(formik.values);
        if (res && res.status === 200) {
            setCreateModal(false);
            formik.resetForm();
            getDocumentTypes();
        }
    }

    useEffect(() => {
        getDocumentTypes();
    }, [])

    return (
        <PageLayout
            title="Sürücü Belge Yönetimi"
            buttons={[
                {
                    onClick: () => setCreateModal(true),
                    variant: "contained",
                    startIcon: <i className="bi bi-plus-lg"></i>,
                    text: "Belge Tanımı Ekle",
                }
            ]}>
            <CustomDataGrid
                data={data}
                columns={columns}
                loading={isTableLoading}
                rowId={(params) => params?.id}
            />

            {/* Create Modal */}
            <CustomDialog
                open={createModal}
                setOpen={setCreateModal}
                title="Belge Tanımı Ekle"
                onSubmit={formik.handleSubmit}
                buttons={[
                    {
                        onClick: formik.handleSubmit,
                        variant: "contained",
                        disabled: formik.values.name.length < 1,
                        text: "Kaydet",
                    }
                ]}
                size="sm"
            >
                <TextField
                    required
                    name="name"
                    label="Belge İsmi"
                    placeholder="Ehliyet"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.name}
                />
            </CustomDialog>
        </PageLayout >
    );
}

export default Page;