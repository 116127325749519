import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { usePlans } from '../../../../context/PlansContext';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

function UpdatePlan() {
    const navigate = useNavigate();
    const { updateForm } = usePlans();
    const { step, plan } = updateForm;

    useEffect(() => {
        if (!plan?.id) {
            navigate(window.location.pathname.split("/duzenle")[0])
        }
    }, [plan?.id])

    return (
        <>
            {
                step === 1 &&
                <Step1 />
            }
            {
                step === 2 &&
                <Step2 />
            }
            {
                step === 3 &&
                <Step3 />
            }
        </>
    )
}

export default React.memo(UpdatePlan);