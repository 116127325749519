import axios from "axios";

export const deleteManagerRequest = (id) => axios.delete(`/manager/${id}`)

export const getManagersRequest = () => axios.get(`/manager`)

export const getManagersPageableRequest = (pageable, filter) => {
    let search_query = `firstName=like="",lastName=like="${filter.search}"`;
    let query = `${search_query}`;
    return axios.get(`/manager/pageable?page=${pageable.page}&size=${pageable.size}&query=${query}`)
}

export const getManagerRequest = (id) => axios.get(`/manager/${id}`)

export const saveManagerRequest = (form) => axios.post(`/manager`, form)

export const updateManagerRequest = (form) => axios.put(`/manager/${form.id}`, form)

export const getAllManagerPermissionsRequest = () => axios.get(`/manager/permissions/all`)

export const getManagerCustomerPermissionsRequest = (managerId,customerId) => axios.get(`/manager/permissions/bymanager?managerId=${managerId}&customerId=${customerId}`)

export const getManagerPermissionsRequest = (managerId,customerId) => axios.get(`/manager/permissions/bymanager/all?managerId=${managerId}`)

export const updateManagerPermissionRequest = (managerId, customerId, permission) => axios.put(`/manager/permissions/update?managerId=${managerId}&customerId=${customerId}&managerPermissionType=${permission}`)
