import axios from "axios";

export const employeeDeleteRequest = (id) => axios.delete(`/employee/${id}`)

export const getEmployeesRequest = (pageable) => axios.get(`/employee?page=${pageable.page}&size=${pageable.size}`)

export const getEmployeeByIdRequest = (id) => axios.get(`/employee/${id}`)

export const uploadEmployeesExcelRequest = (file) => axios.post(`/employee/excel`, file)

export const saveEmployeeListRequest = (employeeList) => axios.post(`/employee/excel/save`, employeeList)

export const getEmployeesByWorkTimeIdRequest = (workTimeId) => axios.get(`/employee/workTime/${workTimeId}`)

export const getEmployeesByWorkTimeIdPageableRequest = (workTimeId, pageable) => axios.get(`/employee/workTime/${workTimeId}?page=${pageable.page}&size=${pageable.size}`)

export const saveEmployeeRequest = (customerId, form) => {
    let data = { ...form, department: null, departmentId: form.department.id || null, workTime: null, workTimeId: form.workTime.id || null }
    return axios.post(`/employee/${customerId}`, data);
}

export const updateEmployeeRequest = (form) => {
    let data = { ...form, department: null, departmentId: form.department.id || null, workTime: null, workTimeId: form.workTime.id || null }
    return axios.put(`/employee/${data.id}`, data);
}

export const updateEmployeeWorkTimeByIdListRequest = (workTimeId, idList) => axios.put(`/employee/changeWorkTime?workTimeId=${workTimeId}`, idList)
