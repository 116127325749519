import { Autocomplete, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { getDepartmentsByCustomerIdRequest } from '../../../api/controllers/department-controller';
import { getEmployeeByIdRequest, updateEmployeeRequest } from '../../../api/controllers/employee-controller';
import { getStudentByIdRequest } from '../../../api/controllers/student-controller';
import { getWorkTimesByCustomerIdRequest } from '../../../api/controllers/work-time-controller';
import CustomDialog from '../../../components/atoms/CustomDialog';
import PhoneTextfield from '../../../components/atoms/PhoneTextfield';
import AddressModal from '../../../components/molecules/AddressModal';

function PersonelDuzenle({ customer, open, setOpen, getTravelers }) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [workTimes, setWorkTimes] = useState([]);
    const [departments, setDepartments] = useState([])
    const [traveller, setTraveller] = useState({
        id: null,
        firstName: "",
        lastName: "",
        username: "",
        workTime: null,
        phone: null,
        address: {
            country: "",
            province: "",
            district: "",
            neighborhood: "",
            street: "",
            buildingInformation: "",
            fullAddress: "",
            position: {
                latitude: null,
                longitude: null,
            },
        },
        arrival: { enable: false },
        departure: { enable: false },
        isActive: false,
        department: null,
        receiveDateTime: "",
    })

    const formik = useFormik({
        validationSchema: yup.object({
            firstName: yup
                .string()
                .required('Bu alan gereklidir'),
            lastName: yup
                .string()
                .required('Bu alan gereklidir'),
            phone: yup.object({
                number: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .length(10, 'Telefon numarası 10 haneli olmalıdır'),
            }),
            workTime: yup
                .object()
                .required('Bu alan gereklidir')
                .nullable(),
            address: yup.object({
                province: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                district: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                neighborhood: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                street: yup
                    .string()
                    .required('Bu alan gereklidir'),
                buildingInformation: yup
                    .string()
                    .required('Bu alan gereklidir'),
            }),
        }),
        initialValues: traveller,
        onSubmit: () => onSubmit()
    });

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await updateEmployeeRequest(formik.values);
            if (res) {
                toast.success("Personel güncellendi");
                setSearchParams(false)
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    useEffect(() => {
        (async () => {
            let res = await getWorkTimesByCustomerIdRequest(customer.id);
            if (res) {
                setWorkTimes(res.data);
            }
            let rex = await getDepartmentsByCustomerIdRequest(customer.id);
            if (rex) {
                setDepartments(rex.data);
            }
        })()
    }, [])

    const getTraveller = async () => {
        let res
        if (customer.customer.customerType === "School") {
            res = await getStudentByIdRequest(customer.editId);
        } else if (customer.customer.customerType === "Company") {
            res = await getEmployeeByIdRequest(customer.editId);
        }
        if (res) {
            setTraveller(res.data);
            formik.setValues(res.data)
        }
    };

    useEffect(() => {
        if (customer.editId) {
            getTraveller();
        }
    }, [customer.editId])

    return (
        <>
            <CustomDialog
                title="Yolcu Düzenle"
                open={open}
                setOpen={setOpen}
                onSubmit={formik.handleSubmit}
                buttons={[
                    {
                        type: "submit",
                        variant: "contained",
                        text: "Kaydet",
                    }
                ]}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="firstName"
                            label="İsim"
                            placeholder="Personel ismini girin"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            error={formik.touched.firstName && formik.errors.firstName}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="lastName"
                            label="Soyisim"
                            placeholder="Personel soyismini girin"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            error={formik.touched.lastName && formik.errors.lastName}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PhoneTextfield
                            name="phone.number"
                            phone={formik.values.phone}
                            onChange={({ value }) => formik.setFieldValue("phone.number", value)}
                            error={formik.touched.phone?.number && formik.errors.phone?.number}
                            helperText={formik.touched.phone?.number && formik.errors.phone?.number}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={workTimes}
                            value={formik.values.workTime}
                            onChange={(event, newValue) => formik.setFieldValue("workTime", newValue)}
                            getOptionLabel={option => `${option.title} (${option.startTime.slice(0, 5)} - ${option.endTime.slice(0, 5)})`}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label='Vardiya'
                                    fullWidth
                                    error={formik.touched.workTime && formik.errors.workTime}
                                    helperText={formik.touched.workTime && formik.errors.workTime}
                                />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={departments}
                            value={formik.values.department}
                            onChange={(event, newValue) => formik.setFieldValue("department", newValue || null)}
                            getOptionLabel={option => `${option.title}`}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label='Departman'
                                    fullWidth
                                    error={formik.touched.department && formik.errors.department}
                                    helperText={formik.touched.department && formik.errors.department}
                                />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AddressModal formik={formik} />
                    </Grid>
                </Grid>
            </CustomDialog>
        </ >
    )
}

export default PersonelDuzenle;