import { useState } from 'react'
import styles from './style.module.scss'
import { Box, Grid, Divider, Typography, TextField, Alert, } from '@mui/material'
import PlansSidebarLayout from '../../../../templates/PlansSidebarLayout';
import { AirlineSeatReclineNormalRounded, ArrowBackRounded, DirectionsBusRounded, RouteRounded } from '@mui/icons-material';
import DetailTag from '../../../../atoms/DetailTag';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router';
import { useOverTime } from '../../../../../context/OverTimeContext';
import { updateOverTimePlanRequest } from '../../../../../api/controllers/overtime-controller';
import Cost from '../../../../atoms/Cost';
import { CostContext } from '../../../../../pages/app/Mesailer/mesai-detay';
import { useContext } from 'react';
import turkish_lira from '../../../../../assets/icons/turkish-lira.svg'
import CustomDialog from '../../../../atoms/CustomDialog';

function Step3() {
    const navigate = useNavigate();
    const { mesaiId } = useParams();
    const { updateForm, setUpdateForm, setPlan, getOverTime } = useOverTime();
    const { plan } = updateForm;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const cost = useContext(CostContext)
    const [conflictVehicleModal, setConflictVehicleModal] = useState(false)

    const handleSubmit = async (isForced) => {
        setIsSubmitting(true);
        try {
            let res = await updateOverTimePlanRequest(mesaiId, plan.id, { ...plan, stationIds: plan.stations.map(v => v.id), vehicleId: plan?.vehicle?.id }, isForced)
            if (res) {
                getOverTime(mesaiId)
                setPlan(res.data)
                navigate(window.location.pathname.split(`/duzenle`)[0])
            }
        } catch (error) {
            if (!error.response.data.message) {
                openConflictVehicleModal()
            }
        }
        setIsSubmitting(false);
    }

    const openConflictVehicleModal = () => {
        setConflictVehicleModal(true)
    }

    const closeConflictVehicleModal = () => {
        setConflictVehicleModal(false)
    }

    return (
        <PlansSidebarLayout
            header={{
                iconButton: {
                    icon: <ArrowBackRounded fontSize="inherit" />,
                    props: {
                        onClick: () => setUpdateForm(prev => ({ ...prev, step: prev.step - 1 })),
                    }
                },
                title: {
                    text: plan?.title || "Güzergahı Düzenle"
                },
            }}
            footer={{
                buttons: [
                    {
                        text: "Geri dön",
                        props: {
                            onClick: () => setUpdateForm(prev => ({ ...prev, step: prev.step - 1 })),
                            variant: "outlined",
                            color: "secondary",
                        }
                    },
                    {
                        text: "Kaydet",
                        props: {
                            disabled: !(plan.startTime && plan.endTime),
                            loading: isSubmitting,
                            onClick: () => handleSubmit(false),
                            variant: "contained",
                        }
                    },
                ]
            }}
        >
            <Box className={styles.container}>
                <Box className={styles.header}>
                    <DetailTag
                        icon={<DirectionsBusRounded fontSize="inherit" />}
                        text={plan.vehicle?.licensePlate || "Bilinmiyor"}
                    />
                    <DetailTag
                        icon={<i className="bi bi-person-fill" />}
                        text={plan.vehicle?.driver ? `${plan.vehicle.driver?.firstName || ""} ${plan.vehicle.driver?.lastName || ""}` : "Bilinmiyor"}
                    />
                    <DetailTag
                        icon={<AirlineSeatReclineNormalRounded fontSize="inherit" />}
                        text={plan.vehicle?.capacity || "Bilinmiyor"}
                    />
                    <DetailTag
                        icon={<i className="bi bi-stopwatch-fill" />}
                        text={plan.duration ? `~ ${moment.duration(plan.duration).hours() ? moment.duration(plan.duration).hours() + " saat " : ""}${moment.duration(plan.duration).minutes() + " dakika"}` : "Bilinmiyor"}
                    />
                    <DetailTag
                        icon={<RouteRounded fontSize="inherit" />}
                        text={plan.distance ? `~ ${new Intl.NumberFormat('tr-TR').format(plan.distance)} metre` : "Bilinmiyor"}
                    />
                    <DetailTag
                        icon={<> <img src={turkish_lira} alt="" style={{ width: "12px", height: "12px" }} /></>}
                        text={cost.costKm && plan.distance ? <> {((cost.costKm * plan.distance) / 1000).toFixed(2)}</> : "Bilinmiyor"}
                        tooltip={cost && <Cost cost={cost} />}
                    />
                </Box>
                <Divider flexItem />
                <Box className={styles.element}>
                    <Box className={styles.header}>
                        <Typography variant="h6">
                            | Güzergah Zamanlaması
                        </Typography>
                    </Box>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TimePicker
                            label="Başlama Zamanı"
                            value={plan.startTime ? new Date(0, 0, 0, plan.startTime.slice(0, 2), plan.startTime.slice(3, 5)) : null}
                            onChange={(newValue) => {
                                setUpdateForm(prev => ({ ...prev, plan: { ...prev.plan, startTime: moment(newValue).format("HH:mm:ss") } }))
                                plan?.duration && setUpdateForm(prev => ({ ...prev, plan: { ...prev.plan, endTime: moment(moment(newValue).add(plan.duration, 'minutes')).format("HH:mm:ss") } }))
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TimePicker
                            label="Bitiş Zamanı"
                            value={plan.endTime ? new Date(0, 0, 0, plan.endTime.slice(0, 2), plan.endTime.slice(3, 5)) : null}
                            onChange={(newValue) => setUpdateForm(prev => ({ ...prev, plan: { ...prev.plan, endTime: moment(newValue).format("HH:mm:ss") } }))}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                </Grid>
                {/*  <Grid container>
                    <Grid item xs={12}>
                        <Box className={styles.element}>
                            <Box className={styles.header}>
                                <Typography variant="h6">
                                    | Ek Seçenekler
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            checked={duplicateOption}
                            onChange={e => setDuplicateOption(e.target.checked)}
                            control={<Checkbox />}
                            label={<Typography variant="body2">{`Değişiklikleri ${planType === "departure" ? "dönüş" : "gidiş"} güzergahına da uygula`}</Typography>}
                            labelPlacement="end"
                        />
                    </Grid>
                </Grid> */}
            </Box >

            <CustomDialog
                size="sm"
                open={conflictVehicleModal}
                closeFunc={closeConflictVehicleModal}
                title="Bilgi"
                buttons={[
                    {
                        type: "button",
                        onClick: closeConflictVehicleModal,
                        variant: "text",
                        text: "Vazgeç",
                    },
                    {
                        type: "button",
                        onClick: () => handleSubmit(true),
                        variant: "contained",
                        text: "Devam Et",
                    },
                ]}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Alert severity='info' >
                            Seçtiğiniz araç bu vardiya zamanında başka bir mesaide kullanılmakta. Yine de devam etmek ister misiniz?
                        </Alert>
                    </Grid>
                </Grid>
            </CustomDialog>

        </PlansSidebarLayout >
    )
}

export default Step3;