import React, { useState, useEffect } from "react";
import no_data from "../../../../assets/no-data.svg";
import { Box, Button, Typography } from "@mui/material";
import { AddRounded } from "@mui/icons-material";
import { usePlans } from "../../../../context/PlansContext";
import PlansSidebarLayout from "../../../templates/PlansSidebarLayout";
import PlanCard from "../../../molecules/PlanCard";
import { toast } from "react-toastify";
import styles from "./style.module.scss";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { getCustomerPermissionsRequest } from "../../../../api/controllers/customer-controller";
import { getManagerCustomerPermissionsRequest } from "../../../../api/controllers/manager-controller";
import { useCustomer } from "../../../../context/CustomerContext";

function ListPlans() {
  const navigate = useNavigate();
  const { workTimeId, planType, customerId } = useParams();
  const { currentUser } = useAuth();
  const { role } = currentUser;
  const { plans } = usePlans();
  const [managerPermission, setManagerPermission] = useState([]);
  const { customerPermission, setCustomerPermission } = useCustomer();

  useEffect(() => {
    if (customerId && role === "MANAGER") {
      (async () => {
        let res = await getManagerCustomerPermissionsRequest(
          currentUser?.id,
          customerId
        );
        console.log(res);
        if (res) {
          setManagerPermission(res.data);
        }
      })();
    }
  }, [customerId]);

  useEffect(() => {
    if (customerId) {
      (async () => {
        let res = await getCustomerPermissionsRequest(customerId);
        if (res) {
          setCustomerPermission(res.data);
        }
      })();
    }
  }, [customerId]);


  return (
    <PlansSidebarLayout
      header={
        role === "MANAGER" || role === "CUSTOMER" || role === "ADMIN" 
          ? {
              button:
                role === "ADMIN" || (role === "MANAGER" && managerPermission.includes("PLAN_EDIT"))
                  ? {
                      text: "Oluştur",
                      props: {
                        onClick: () => {
                          if (!workTimeId) {
                            toast.warn(
                              "Öncelikle vardiya seçmelisiniz! Eğer yoksa oluşturmalısınız!"
                            );
                          } else {
                            navigate("olustur");
                          }
                        },
                        startIcon: <AddRounded fontSize="inherit" />,
                        variant: "contained",
                      },
                    }
                  : null,
            }
          : null
      }
      managerPermission={managerPermission}
      customerPermission={customerPermission}
    >
      {plans[planType]?.length === 0 ? (
        <Empty
          navigate={navigate}
          workTimeId={workTimeId}
          role={role}
          managerPermission={managerPermission}
        />
      ) : (
        <Box className={styles.list}>
          {plans[planType]?.map((v) => (
            <Link
              key={v.id}
              style={{ textDecoration: "none" }}
              to={`plan/${v.uuid}`}
            >
              <PlanCard plan={v} />
            </Link>
          ))}
        </Box>
      )}
    </PlansSidebarLayout>
  );
}

export default React.memo(ListPlans);

const Empty = ({ navigate, workTimeId, role, managerPermission }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="1rem"
      p="1rem"
    >
      <img src={no_data} alt="" />
      <Typography>Henüz güzergah bulunmuyor!</Typography>
      {role === "ADMIN" || (role ==="MANAGER" && managerPermission?.includes("PLAN_EDIT")) ? (
        <Button
          onClick={() => {
            if (!workTimeId) {
              toast.warn(
                "Öncelikle vardiya seçmelisiniz! Eğer yoksa oluşturmalısınız!"
              );
            } else {
              navigate("olustur");
            }
          }}
          variant="outlined"
          sx={{ bgcolor: "var(--primary_12)" }}
          startIcon={<AddRounded fontSize="inherit" />}
        >
          Yeni güzergah oluştur
        </Button>
      ) : null}
    </Box>
  );
};
