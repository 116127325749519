import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  TextField,
  Typography,
  InputAdornment,
  Alert,
  Grid,
  Autocomplete,
} from "@mui/material";
import styles from "./style.module.scss";
import {
  getAvailableVehiclesForOverTimeRequest,
  getVehiclesRequest,
  updateVehicleRequest,
} from "../../../../../api/controllers/vehicle-controller";
import VehicleCard from "../../../../molecules/VehicleCard";
import PlansSidebarLayout from "../../../../templates/PlansSidebarLayout";
import { ArrowBackRounded } from "@mui/icons-material";
import { getDriversRequest } from "../../../../../api/controllers/driver-controller";
import { toast } from "react-toastify";
import CustomDialog from "../../../../atoms/CustomDialog";
import { useNavigate, useParams } from "react-router";
import { useOverTime } from "../../../../../context/OverTimeContext";
import { Driver, Vehicle } from "../../../../../types/OverTimeContext";

function Step1() {
  const navigate = useNavigate();
  const { planType } = useParams();
  const { updateForm, setUpdateForm, resetUpdateForm, overTime } =
    useOverTime();
  const { plan } = updateForm;
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [searchText, setSearchText] = useState("");
  const [assignVehicleModal, setAssignVehicleModal] = useState(false);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);
  const [selectedDriver, setSelectedDriver] = useState<Driver | null>(null);

  const filteredVehicles = vehicles.filter((v) =>
    v.licensePlate.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
  );

  useEffect(() => {
    (async () => {
      getVehicles();
      getDrivers();
    })();
  }, []);

  //Araçları getirir
  const getVehicles = async () => {
    let res = await getVehiclesRequest(/* {
      startDate: overTime?.startDate,
      endDate: overTime?.endDate,
      time:
        planType === "departure"
          ? overTime?.workTime.startTime
          : planType === "arrival"
            ? overTime?.workTime.endTime
            : null,
    } */);
    if (res) {
      setVehicles(res.data);
    }
  };

  //Sürücüleri getirir
  const getDrivers = async () => {
    let res = await getDriversRequest();
    if (res) {
      setDrivers(res.data);
    }
  };

  const selectVehicle = (vehicle: Vehicle) => {
    if (vehicle.driver) {
      setUpdateForm((prev) => ({
        ...prev,
        plan: prev.plan && { ...prev.plan, vehicle: vehicle },
      }));
    } else {
      openAssignVehicleModal();
      setSelectedVehicle(vehicle);
    }
  };

  const openAssignVehicleModal = () => {
    setAssignVehicleModal(true);
  };

  const closeAssignVehicleModal = () => {
    setAssignVehicleModal(false);
  };

  const assignDriverToVehicle = async () => {
    // setIsSubmitting(true);
    let res = await updateVehicleRequest({
      ...selectedVehicle,
      driver: selectedDriver,
    });
    if (res && res.status === 200) {
      closeAssignVehicleModal();
      getVehicles();
      toast.success("Araca sürücü başarıyla atandı!");
    }
    // setIsSubmitting(false);
  };

  return (
    <>
      <PlansSidebarLayout
        header={{
          iconButton: {
            icon: <ArrowBackRounded fontSize="inherit" />,
            props: {
              onClick: () => {
                navigate(window.location.pathname.split("/duzenle")[0]);
                resetUpdateForm();
              },
            },
          },
          title: {
            text: plan?.title || "Güzergahı Düzenle",
          },
        }}
        footer={{
          buttons: [
            {
              text: "İptal et",
              props: {
                onClick: () => {
                  navigate(window.location.pathname.split("/duzenle")[0]);
                  resetUpdateForm();
                },
                variant: "outlined",
                color: "secondary",
              },
            },
            {
              text: "Devam et",
              props: {
                onClick: () =>
                  setUpdateForm((prev) => ({ ...prev, step: prev.step + 1 })),
                variant: "contained",
              },
            },
          ],
        }}
      >
        <Box className={styles.container}>
          <Box className={styles.header}>
            <Typography variant="h6">
              | Güzergah için isim belirleyin
            </Typography>
            <TextField
              fullWidth
              size="small"
              placeholder="Güzergah ismini yazın"
              value={plan?.title || ""}
              onChange={(e) =>
                setUpdateForm((prev) => ({
                  ...prev,
                  plan: prev.plan && { ...prev.plan, title: e.target.value },
                }))
              }
            />
            <Divider flexItem />
            <Typography variant="h6">| Güzergah için servis seçin</Typography>
            <TextField
              variant="standard"
              className={styles.search}
              fullWidth
              size="small"
              placeholder="Servisler içerisinde arayın..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <i className="bi bi-search" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box className={styles.list}>
            {filteredVehicles.length > 0 ? (
              filteredVehicles.map((v, i) => (
                <VehicleCard
                  key={i}
                  vehicle={v}
                  onClick={() => selectVehicle(v)}
                  isActive={plan?.vehicle?.id === v.id}
                />
              ))
            ) : (
              <Alert severity="warning">Araç Bulunamadı!</Alert>
            )}
          </Box>
        </Box>
      </PlansSidebarLayout>
      <CustomDialog
        size="sm"
        open={assignVehicleModal}
        closeFunc={closeAssignVehicleModal}
        title="Bilgi."
        buttons={[
          {
            type: "button",
            onClick: assignDriverToVehicle,
            variant: "contained",
            text: "Kaydet",
          },
        ]}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Alert severity="info">
              Seçtiğiniz aracın sürüsü bulunmamaktadır, aşağıdan sürücüsünü
              atayabilirsiniz.
            </Alert>
          </Grid>
          <Grid item xs={12} md={12}>
            <Autocomplete
              options={drivers}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Sürücü"
                  placeholder="Listeden seçin"
                  fullWidth
                />
              )}
              getOptionLabel={(option: Driver) =>
                option.firstName + " " + option.lastName
              }
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              value={selectedDriver as any}
              onChange={(event, newValue: any) => setSelectedDriver(newValue)}
            />
          </Grid>
        </Grid>
      </CustomDialog>
    </>
  );
}

export default Step1;
