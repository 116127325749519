import { useEffect, useState } from 'react';
import PageLayout from '../../components/templates/PageLayout';
import { Grid, Box, Alert, TextField, Typography, Divider, Button } from '@mui/material';
import { useFormik } from 'formik';
import PhoneTextfield from '../../components/atoms/PhoneTextfield';
import DocumentUpload from '../../components/atoms/DocumentUpload';
import CustomDialog from '../../components/atoms/CustomDialog';
import { getCompanyRequest, updateCompanyRequest } from '../../api/controllers/company-controller';
import { getDocumentTypesOfAdminRequest, saveDocumentTypeRequest } from '../../api/controllers/document-type-controller';
import { toast } from 'react-toastify';
import axios from 'axios';

function Page() {

    const [documentTypes, setDocumentTypes] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [createModal, setCreateModal] = useState(false);

    const formik = useFormik({
        initialValues: {
            title: "",
            phone: {
                areaCode: "+90",
                countryCode: "",
                number: ""
            },
            taxAdministration: "",
            taxNumber: "",
            address: "",
        },
        onSubmit: () => onSubmit(),
    })

    const getData = async () => {
        let res = await getCompanyRequest();
        if (res) {
            formik.setValues(res.data);
        }
    }

    const getDocumentTypes = async () => {
        let res = await getDocumentTypesOfAdminRequest();
        if (res) {
            setDocumentTypes(res.data);
        }
    }

    const getDocuments = async () => {
        let res = await axios.get(`/document/admin/getAll`)
        if (res) {
            setDocuments(res.data);
        }
    }

    const onSubmit = async () => {
        let res = await updateCompanyRequest(formik.values);
        if (res) {
            toast.success("Bilgiler güncellendi");
            getData();
        }
    }

    const uploadDocument = async ({ acceptedFiles, documentTypeId }) => {
        const formData = new FormData();
        formData.append('result', acceptedFiles[0]);
        let res = await axios.post(`/document/admin?documentTypeId=${documentTypeId}`, formData)
        if (res) {
            getDocuments();
            toast.success("Belge yüklendi");
        }
    }



    useEffect(() => {
        getData();
        getDocumentTypes();
        getDocuments();
    }, []);


    return (
        <PageLayout
            title="Şirket Yönetimi"
        >
            <Alert
                severity="info"
                color="info"
                sx={{ mb: "1rem" }}
            >
                Yaptığınız değişiklikler otomatik olarak kaydedilecektir.
            </Alert>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <form onBlur={formik.handleSubmit}>
                        <Grid container item xs={12} md={10} lg={8} spacing={3}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="title"
                                    label="Şirket Ünvanı"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.title}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PhoneTextfield
                                    name="phone.number"
                                    phone={formik.values.phone}
                                    onChange={({ value }) => formik.setFieldValue("phone.number", value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="taxAdministration"
                                    label="Vergi Dairesi"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.taxAdministration}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="taxNumber"
                                    label="Vergi Numarası"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.taxNumber}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    name="address.fullAddress"
                                    label="Adres"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.address?.fullAddress || null}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">| Belgeler</Typography>
                            <Button onClick={() => setCreateModal(true)} variant="contained">Yeni belge tanımı ekle</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" gap="1.5rem" flexWrap="wrap" mt="1rem">
                            {
                                documentTypes.length > 0
                                    ?
                                    documentTypes.map((val, i) =>
                                        <DocumentUpload
                                            key={i}
                                            documentTypeId={val.id}
                                            name={val.name}
                                            document={documents?.find(v => v?.documentType?.id === val.id)}
                                            getDocuments={getDocuments}
                                            uploadDocument={uploadDocument}
                                        />
                                    )
                                    :
                                    <Alert severity="warning">Belge tanımı bulunamadı!</Alert>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {
                createModal &&
                <CreateDocumentType
                    createModal={createModal}
                    setCreateModal={setCreateModal}
                    getDocumentTypes={getDocumentTypes}
                />
            }
        </PageLayout>
    )
}

export default Page;


const CreateDocumentType = ({ createModal, setCreateModal, getDocumentTypes }) => {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const formik = useFormik({
        initialValues: {
            documentCategory: "ADMIN",
            name: ""
        },
        onSubmit: () => onSubmit(),
    })

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await saveDocumentTypeRequest(formik.values);
            if (res) {
                await getDocumentTypes();
                setCreateModal(false);
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    useEffect(() => {
        return () => {
            formik.resetForm();
        }
    }, [])


    return (
        <CustomDialog
            open={createModal}
            setOpen={setCreateModal}
            title="Belge Tanımı Ekle"
            onSubmit={formik.handleSubmit}
            buttons={
                [
                    {
                        variant: "contained",
                        disabled: formik.values.name.length < 1,
                        type: "submit",
                        loading: isSubmitting,
                        text: "Kaydet",
                    }
                ]}
            size="sm"
        >
            <TextField
                required
                name="name"
                label="Belge İsmi"
                placeholder="Belge ismi"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.name}
            />
        </CustomDialog>
    )
}