import { BrowserRouter, Routes, Route } from "react-router-dom";

import PrivateRouter from "./PrivateRouter";

//LOGIN
import Login_Page from "../pages/giris-yap";

//NOT FOUND
import NotFound_Page from "../pages/sayfa-bulunamadi";

//SERVISLER
import Vehicles_Page from "../pages/app/Servisler/servisler-map";
import Vehicles_Document_Management_Page from "../pages/app/Servisler/belge-yonetimi";
import Vehicles_Hakediş_Page from "../pages/app/Servisler/hakediş";
import Vehicles_Hakediş_Detail_Page from "../pages/app/Servisler/hakediş-detay";
import Vehicles_List_Page from "../pages/app/Servisler/servisler-liste";
import Vehicle_Detail_Page from "../pages/app/Servisler/servis-detayi";

//GUZERGAHLAR
import Plans_Page from "../pages/app/Guzergahlar";
import ListPlans from "./organisms/PlansSidebar/ListPlans";
import CreatePlan from "./organisms/PlansSidebar/CreatePlan";
import ShowPlan from "./organisms/PlansSidebar/ShowPlan";
import UpdatePlan from "./organisms/PlansSidebar/UpdatePlan";
import UpdateStation from "./organisms/PlansSidebar/UpdateStation";
import CreateStation from "./organisms/PlansSidebar/CreateStation";

//MUSTERILER
import Customers_Page from "../pages/app/Musteriler";
import Customer_Create_Page from "../pages/app/Musteriler/musteri-ekle";
import Customer_Detail_Page from "../pages/app/Musteriler/musteri-detayi";

//YONETICILER
import Managers_Page from "../pages/app/Yoneticiler";
import Manager_Detail_Page from "../pages/app/Yoneticiler/yoneticiler-detayi";
import Manager_Permissions_Page from "../pages/app/Yoneticiler/yonetici-izinleri";

//YOLCULAR
import Travelers_Page from "../pages/app/Yolcular";
import Customer_MyRequests_Page from "../pages/app/Yolcular/MyRequests";

//SURUCULER
import Drivers_Page from "../pages/app/Suruculer";
import Drivers_Document_Management_Page from "../pages/app/Suruculer/belge-yonetimi";
import Driver_Detail_Page from "../pages/app/Suruculer/surucu-detayi";

//MESAILER
import OverTime_Page from "../pages/app/Mesailer";
import OverTimePlans_Page from "../pages/app/Mesailer/mesai-detay";
import ListPlansForOverTime from "./organisms/OverTimeSidebar/ListPlans";
import CreatePlanForOverTime from "./organisms/OverTimeSidebar/CreatePlan";
import ShowPlanForOverTime from "./organisms/OverTimeSidebar/ShowPlan";
import UpdatePlanForOverTime from "./organisms/OverTimeSidebar/UpdatePlan";
import UpdateStationForOverTime from "./organisms/OverTimeSidebar/UpdateStation";
import CreateStationForOverTime from "./organisms/OverTimeSidebar/CreateStation";
import CreateStationForOverTimeForNeighborhood from "./organisms/OverTimeSidebar/CreateStationForNeighborhood";

//ETUTLER
import Study_Page from "../pages/app/Etutler";
import StudyPlans_Page from "../pages/app/Etutler/etut-detay";
import ListPlansForStudy from "./organisms/StudySidebar/ListPlans";
import CreatePlanForStudy from "./organisms/StudySidebar/CreatePlan";
import ShowPlanForStudy from "./organisms/StudySidebar/ShowPlan";
import UpdatePlanForStudy from "./organisms/StudySidebar/UpdatePlan";
import UpdateStationForStudy from "./organisms/StudySidebar/UpdateStation";
import CreateStationForStudy from "./organisms/StudySidebar/CreateStation";
import CreateStationForStudyForNeighborhood from "./organisms/StudySidebar/CreateStationForNeighborhood";

//GEZILER
import Trip_Page from "../pages/app/Geziler";
import TripPlans_Page from "../pages/app/Geziler/gezi-detay";
import ListPlansForTrip from "./organisms/TripSidebar/ListPlans";
import CreatePlanForTrip from "./organisms/TripSidebar/CreatePlan";
import ShowPlanForTrip from "./organisms/TripSidebar/ShowPlan";
import UpdatePlanForTrip from "./organisms/TripSidebar/UpdatePlan";
import UpdateStationForTrip from "./organisms/TripSidebar/UpdateStation";
import CreateStationForTrip from "./organisms/TripSidebar/CreateStation";

//CUSTOMER DETAIL
import Customer_Info_Component from "./organisms/CustomerDetail/Info";
import Customer_Travelers_Component from "./organisms/CustomerDetail/Travelers";
import Customer_Edit_Component from "./organisms/CustomerDetail/Info/Edit";
import Customer_Traveler_Create_Component from "./organisms/CustomerDetail/Travelers/CreateTraveler";
import Customer_WorkTimes_Component from "./organisms/CustomerDetail/WorkTimes";
import Customer_Departments_Component from "./organisms/CustomerDetail/Departments";
import Customer_DailtTasks_Component from "./organisms/CustomerDetail/DailyTasks";
import Customer_Plans_Component from "./organisms/CustomerDetail/Plans";
import Customer_Reports_Component from "./organisms/CustomerDetail/Reports";
import Customer_Traveler_Edit_Component from "./organisms/CustomerDetail/Travelers/UpdateTraveler";

//SIRKET YONETIMI
import Admin_Company_Management_Page from "../pages/app/sirket-yonetimi";

//TICARI BILGILER
import Admin_Company_View_Page from "../pages/app/ticari-bilgiler";

//AYARLAR
import Admin_Settings_Page from "../pages/app/ayarlar";

//PRIVACY
import Privacy_Policy from "../pages/Privacy_Policy";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Login_Page />} />
        <Route exact path="/app" element={<PrivateRouter />}>
          <Route index element={<Vehicles_Page />} />

          {/* Servisler */}
          <Route path="servisler">
            <Route index element={<Vehicles_Page />} />
            <Route path=":vehicleId" element={<Vehicles_Page />} />
            <Route path="liste">
              <Route index element={<Vehicles_List_Page />} />
              <Route
                path="detay/:vehicleId"
                element={<Vehicle_Detail_Page />}
              />
            </Route>
            <Route
              path="belge-yonetimi"
              element={<Vehicles_Document_Management_Page />}
            />
            <Route path="hakediş">
              <Route index element={<Vehicles_Hakediş_Page />} />
              <Route
                path=":vehicleId/detay/:date"
                element={<Vehicles_Hakediş_Detail_Page />}
              />
            </Route>
          </Route>

          {/* Güzergahlar */}
          <Route path="guzergahlar" element={<Plans_Page />}>
            <Route path="customer/:customerId">
              <Route path="workTime/:workTimeId">
                <Route path=":planType">
                  <Route index element={<ListPlans />} />
                  <Route path="olustur" element={<CreatePlan />} />
                  <Route path="plan/:planUuid">
                    <Route index element={<ShowPlan />} />
                    <Route path="duzenle">
                      <Route index element={<UpdatePlan />} />
                      <Route path="station">
                        <Route path="olustur" element={<CreateStation />} />
                        <Route path=":stationId" element={<UpdateStation />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          {/* Müşteriler */}
          <Route path="musteriler">
            <Route index element={<Customers_Page />} />
            <Route path="ekle" element={<Customer_Create_Page />} />
            <Route path=":customerId" element={<Customer_Detail_Page />}>
              <Route path="detay">
                <Route index element={<Customer_Info_Component />} />
                <Route path="duzenle" element={<Customer_Edit_Component />} />
              </Route>
              <Route path="yolcular">
                <Route index element={<Customer_Travelers_Component />} />
                <Route
                  path="ekle"
                  element={<Customer_Traveler_Create_Component />}
                />
                <Route
                  path="duzenle/:travelerId"
                  element={<Customer_Traveler_Edit_Component />}
                />
              </Route>
              <Route
                path="vardiyalar"
                element={<Customer_WorkTimes_Component />}
              />
              <Route
                path="departmanlar"
                element={<Customer_Departments_Component />}
              />
              <Route
                path="gunluk-gorevler"
                element={<Customer_DailtTasks_Component />}
              />
              <Route
                path="guzergahlar"
                element={<Customer_Plans_Component />}
              />
              <Route path="raporlar" element={<Customer_Reports_Component />} />
            </Route>
          </Route>

          {/* Yöneticiler */}
          <Route path="yoneticiler">
            <Route index element={<Managers_Page />} />
            <Route path="detay/:managerId" element={<Manager_Detail_Page />} />
            <Route path="izinler" element={<Manager_Permissions_Page />} />
          </Route>

          {/* Yolcular */}
          <Route path="yolcular">
            <Route index element={<Travelers_Page />} />
            <Route path="mesailer" element={<Customer_MyRequests_Page />} />
            <Route path="etutler" element={<Customer_MyRequests_Page />} />
          </Route>

          {/* Sürücüler */}
          <Route path="suruculer">
            <Route index element={<Drivers_Page />} />
            <Route path="detay/:driverId" element={<Driver_Detail_Page />} />
            <Route
              path="belge-yonetimi"
              element={<Drivers_Document_Management_Page />}
            />
          </Route>

          {/* Mesailer */}
          <Route path="mesailer">
            <Route index element={<OverTime_Page />} />
            <Route path="harita" element={<OverTimePlans_Page />}>
              <Route path=":mesaiId/:planType">
                <Route index element={<ListPlansForOverTime />} />
                <Route path="olustur" element={<CreatePlanForOverTime />} />
                <Route path="plan/:planUuid">
                  <Route index element={<ShowPlanForOverTime />} />
                  <Route path="duzenle">
                    <Route index element={<UpdatePlanForOverTime />} />
                    <Route path="station">
                      <Route
                        path="olustur"
                        element={<CreateStationForOverTime />}
                      />
                      <Route
                        path="mahalle-olustur"
                        element={<CreateStationForOverTimeForNeighborhood />}
                      />
                      <Route
                        path=":stationId"
                        element={<UpdateStationForOverTime />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>

          {/* Etütler */}
          <Route path="etutler">
            <Route index element={<Study_Page />} />
            <Route path="harita" element={<StudyPlans_Page />}>
              <Route path=":etutId/:planType">
                <Route index element={<ListPlansForStudy />} />
                <Route path="olustur" element={<CreatePlanForStudy />} />
                <Route path="plan/:planUuid">
                  <Route index element={<ShowPlanForStudy />} />
                  <Route path="duzenle">
                    <Route index element={<UpdatePlanForStudy />} />
                    <Route path="station">
                      <Route
                        path="olustur"
                        element={<CreateStationForStudy />}
                      />
                      <Route
                        path="mahalle-olustur"
                        element={<CreateStationForStudyForNeighborhood />}
                      />
                      <Route
                        path=":stationId"
                        element={<UpdateStationForStudy />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>

          {/* Geziler */}
          <Route path="geziler">
            <Route index element={<Trip_Page />} />
            <Route path="harita" element={<TripPlans_Page />}>
              <Route path=":geziId/:planType">
                <Route index element={<ListPlansForTrip />} />
                <Route path="olustur" element={<CreatePlanForTrip />} />
                <Route path="plan/:planUuid">
                  <Route index element={<ShowPlanForTrip />} />
                  <Route path="duzenle">
                    <Route index element={<UpdatePlanForTrip />} />
                    <Route path="station">
                      <Route
                        path="olustur"
                        element={<CreateStationForTrip />}
                      />
                      <Route
                        path=":stationId"
                        element={<UpdateStationForTrip />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>

          <Route
            path="sirket-yonetimi"
            element={<Admin_Company_Management_Page />}
          />
          <Route path="ticari-bilgiler" element={<Admin_Company_View_Page />} />
          <Route path="ayarlar" element={<Admin_Settings_Page />} />
        </Route>
        <Route path="privacy" element={<Privacy_Policy />} />
        <Route path="*" element={<NotFound_Page />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
