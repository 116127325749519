import {
    Grid,
    InputAdornment,
    TextField
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import * as yup from "yup";
import { getAdminSettingsRequest, updateAdminSettingsRequest } from "../../../../api/controllers/settings-controller";
import { useAuth } from "../../../../context/AuthContext";

export const AdminSettingsForms = () => {

    const { settings, setSettings, getData } = useAuth()

    const formik = useFormik({
        validationSchema: yup.object({
            maxWalkingDistance: yup.number().required("Bu alan gereklidir"),
        }),
        initialValues: {
            maxWalkingDistance: 0,
        },
        onSubmit: () => onSubmit(),
    });

    const onSubmit = async () => {
        try {
            let res = await updateAdminSettingsRequest(formik.values);
            if (res) {
                setSettings(res.data)
                toast.success("Bilgiler güncellendi");
                getData(formik);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getData(formik);
    }, []);

    return (
        <>
            <form onBlur={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <NumericFormat
                            name="maxWalkingDistance"
                            label="Durağa Max Yürüme Mesafesi"
                            placeholder="Durağa Max Yürüme Mesafesi Giriniz"
                            fullWidth
                            onValueChange={({ floatValue }) =>
                                formik.setFieldValue("maxWalkingDistance", floatValue)
                            }
                            customInput={TextField}
                            value={formik.values.maxWalkingDistance}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <span>M</span>
                                    </InputAdornment>
                                ),
                            }}
                            error={ 
                                formik.touched.maxWalkingDistance &&
                                formik.errors.maxWalkingDistance
                            }
                            helperText={
                                formik.touched.maxWalkingDistance &&
                                formik.errors.maxWalkingDistance
                            }
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    );
};
