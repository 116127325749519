import React from 'react'
import TravelerMarker from '../../components/atoms/TravelerMarker';
import { Tooltip } from 'react-leaflet';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { useOverTime } from '../../context/OverTimeContext';

function EmployeeTravelerMarkerForOverTime({ traveler }) {
    const { stationForm, setStationForm } = useOverTime();
    const { planType } = useParams();
    const isSelected = stationForm.travelersId.some(v => v.id === traveler.id);
    const some = stationForm.travelersId.some(v => v.id === traveler.id);

    
    const handleClick = () => {
        if (traveler[`${planType}Enable`] || isSelected) {
            if (some) {
                setStationForm(prev => ({ ...prev, travelersId: prev.travelersId.filter(v => v.id !== traveler.id) }));
            } else {
                setStationForm(prev => ({ ...prev, travelersId: [...prev.travelersId, traveler] }));
            }
        } else (
            toast.warning("Seçtiğiniz yolcu bir plana bağlıdır!")
        )
    }

    if (window.location.pathname.includes("station")) {
        return (
            <TravelerMarker
                position={[traveler.address.position.latitude, traveler.address.position.longitude]}
                eventHandlers={{
                    click: () => handleClick(),
                }}
                isSelected={isSelected}
                isPassive={!traveler[`${planType}Enable`]}
            >
                <Tooltip direction="bottom">{traveler?.firstName + " " + traveler?.lastName}</Tooltip>
            </TravelerMarker >
        )
    } else {
        return (
            <TravelerMarker
                position={[traveler.address.position.latitude, traveler.address.position.longitude]}
                isPassive={!traveler[`${planType}Enable`]}
            >
                <Tooltip direction="bottom">{traveler?.firstName + " " + traveler?.lastName}</Tooltip>
            </TravelerMarker>
        )
    }
}

export default EmployeeTravelerMarkerForOverTime;