import { useEffect, useState } from "react";
import {
  getManagersPageableRequest,
  getAllManagerPermissionsRequest,
  updateManagerPermissionRequest,
} from "../../../api/controllers/manager-controller";
import PageLayout from "../../../components/templates/PageLayout";
import CustomDataGrid from "../../../components/atoms/CustomDataGrid";
import { useNavigate } from "react-router";
import Search from "../../../components/molecules/Search";
import { Stack } from "@mui/system";
import { useSearchParams } from "react-router-dom";
import { getCustomersPageableRequest } from "../../../api/controllers/customer-controller";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import CustomerEdit from "../../../assets/icons/company-edit.svg";
import CustomerSee from "../../../assets/icons/company-see.svg";
import RouteSee from "../../../assets/icons/route-see.svg";
import RouteEdit from "../../../assets/icons/route-edit.svg";

function Page() {
  //States
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageable, setPageable] = useState({ page: 0, size: 10, total: 0 });
  const [customers, setCustomers] = useState([]);
  const [managerPermissions, setManagerPermissions] = useState([]);
  const [filter, setFilter] = useState({
    search: "",
  });

  function hasPermission(managerId, customerId, permissionType) {
    return managerPermissions.some(
      (permission) =>
        permission.manager.id === managerId &&
        permission.customer.id === customerId &&
        permission.managerPermissionType === permissionType
    );
  }

  const [isPermissionsLoaded, setIsPermissionsLoaded] = useState(false);

  async function handlePermission(permission, managerId, customerId) {
    let managerPermisson = "";

    switch (permission) {
      case "PLAN_READ":
        managerPermisson = "PLAN_READ";
        try {
          let res = await updateManagerPermissionRequest(
            managerId,
            customerId,
            managerPermisson
          );
        } catch (err) {}
        break;
      case "PLAN_EDIT":
        managerPermisson = "PLAN_EDIT";
        try {
          let res = await updateManagerPermissionRequest(
            managerId,
            customerId,
            managerPermisson
          );
        } catch (err) {}
        break;
      case "CUSTOMER_READ":
        managerPermisson = "CUSTOMER_READ";
        try {
          let res = await updateManagerPermissionRequest(
            managerId,
            customerId,
            managerPermisson
          );
        } catch (err) {}
        break;
      case "CUSTOMER_EDIT":
        managerPermisson = "CUSTOMER_EDIT";
        try {
          let res = await updateManagerPermissionRequest(
            managerId,
            customerId,
            managerPermisson
          );
        } catch (err) {}
        break;
      default:
        break;
    }
  }
  //Tablo sütunları
  const columns = [
    {
      field: "title",
      headerName: "Müşteriler",
      flex: 1,
      minWidth: 200,
      valueGetter: ({ value, row }) => {
        return value;
      },
    },
    ...data.map((manager, index) => ({
      field: manager.firstName + manager.lastName,
      headerName: manager.firstName + " " + manager.lastName,
      flex: 1,
      minWidth: 240,
      key: index,
      renderCell: ({ value, row }) => {
        return (
          <>
            <CustomCheckbox
              checked={hasPermission(manager.id, row.id, "CUSTOMER_READ")}
              onChange={() =>
                handlePermission("CUSTOMER_READ", manager.id, row.id)
              }
              tooltip="Firma Görme"
              icon={CustomerSee}
            />
            <CustomCheckbox
              checked={hasPermission(manager.id, row.id, "CUSTOMER_EDIT")}
              onChange={() =>
                handlePermission("CUSTOMER_EDIT", manager.id, row.id)
              }
              tooltip="Firma Düzenle"
              icon={CustomerEdit}
            />
            <CustomCheckbox
              checked={hasPermission(manager.id, row.id, "PLAN_READ")}
              onChange={() => handlePermission("PLAN_READ", manager.id, row.id)}
              tooltip="Güzergah Görme"
              icon={RouteSee}
            />
            <CustomCheckbox
              checked={hasPermission(manager.id, row.id, "PLAN_EDIT")}
              onChange={() => handlePermission("PLAN_EDIT", manager.id, row.id)}
              tooltip="Güzergah Düzenle"
              icon={RouteEdit}
            />
          </>
        );
      },
    })),
  ];

  const getManagerPermissions = async () => {
    setIsTableLoading(true);
    try {
      let res = await getAllManagerPermissionsRequest(pageable, filter);
      if (res) {
        setManagerPermissions(res.data);
        setIsPermissionsLoaded(true);
      }
    } catch (error) {}
    setIsTableLoading(false);
  };

  //Yöneticileri getirir
  const getManagers = async () => {
    setIsTableLoading(true);
    try {
      let res = await getManagersPageableRequest(pageable, filter);
      if (res) {
        setData(res.data.content);
        setPageable({ ...pageable, total: res.data.totalElements });
      }
    } catch (error) {}
    setIsTableLoading(false);
  };

  const getCustomers = async () => {
    setIsTableLoading(true);
    try {
      let res = await getCustomersPageableRequest(pageable, filter);
      if (res) {
        setCustomers(res.data.content);
      }
    } catch (error) {}
    setIsTableLoading(false);
  };

  useEffect(() => {
    if (isPermissionsLoaded) {
      getManagers();
      getCustomers();
    }
  }, [pageable.page, pageable.size, filter.search, isPermissionsLoaded]);

  useEffect(() => {
    getCustomers();
    getManagerPermissions();
  }, []);

  return (
    <PageLayout title="Yöneticiler">
      <Stack spacing={2}>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Search
            onChange={(e) => setFilter((prev) => ({ ...prev, search: e }))}
          />
        </Stack>
        <CustomDataGrid
          data={customers}
          columns={columns}
          loading={isTableLoading}
          rowId={(params) => params?.id}
          pageable={pageable}
          setPageable={setPageable}
        />
      </Stack>
    </PageLayout>
  );
}

export default Page;

function CustomCheckbox({ onChange, tooltip, checked, icon }) {
  const [checkedState, setCheckedState] = useState(checked);

  const handleChange = () => {
    setCheckedState(!checkedState);
    onChange(!checkedState);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        justifyItems: "center",
      }}
    >
      <Tooltip title={tooltip}>
        <img src={icon} alt="Icon" width={32} height={32} />
      </Tooltip>
      <Checkbox checked={checkedState} onChange={handleChange} />
    </div>
  );
}
