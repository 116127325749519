import axios from "axios";

export const driverDeleteRequest = (id) => axios.delete(`/driver/${id}`)

export const getDriversPageableRequest = (pageable, filter) => {
    let search_query = `firstName-lastName=like='${filter.search}'`;
    let query = `${search_query}`;
    return axios.get(`/driver/pageable?page=${pageable.page}&size=${pageable.size}&query=${query}`)
}

export const getDriversRequest = () => axios.get(`/driver/sort?sort=firstName,asc`)

export const getDriversNotPageableAndUnassignedRequest = () => axios.get(`/driver/getUnAssigned?sort=firstName,asc`)

export const getDriverRequest = (id) => axios.get(`/driver/${id}`)

export const saveDriverRequest = (form) => axios.post(`/driver`, form)

export const updateDriverRequest = (form) => axios.put(`/driver/${form.id}`, form)
