import TravelerMarker from '../../components/atoms/TravelerMarker';
import { Tooltip } from 'react-leaflet';
import { toast } from 'react-toastify';
import { usePlans } from '../../context/PlansContext';
import { useParams } from 'react-router';

function EmployeeTravelerMarker({ traveler }) {
    const { stationForm, setStationForm } = usePlans();
    const { planType } = useParams();

    const handleClick = () => {
        const some = stationForm.travelerIds.some(v => v.id === traveler.id);
        if (some) {
            setStationForm(prev => ({ ...prev, travelerIds: prev.travelerIds.filter(v => v.id !== traveler.id) }));
        } else {
            setStationForm(prev => ({ ...prev, travelerIds: [...prev.travelerIds, traveler] }));
        }
    }

    if (window.location.pathname.includes("station")) {
        const isSelected = stationForm.travelerIds.some(v => v.id === traveler.id);
        return (
            <TravelerMarker
                position={[traveler.address.position.latitude, traveler.address.position.longitude]}
                eventHandlers={{
                    click: () => traveler[planType]?.enable || isSelected ? handleClick() : toast.warning("Seçtiğiniz yolcu bir plana bağlıdır!"),
                }}
                isSelected={isSelected}
                isPassive={!traveler[planType]?.enable}
            >
                <Tooltip direction="bottom">{traveler?.firstName + " " + traveler?.lastName}</Tooltip>
            </TravelerMarker>
        )
    } else {
        return (
            <TravelerMarker
                position={[traveler?.address?.position?.latitude, traveler?.address?.position?.longitude]}
                isPassive={!traveler[planType]?.enable}
            >
                <Tooltip direction="bottom">{traveler?.firstName + " " + traveler?.lastName}</Tooltip>
            </TravelerMarker>
        )
    }
}

export default EmployeeTravelerMarker;