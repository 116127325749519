import { ThemeProvider, createTheme } from '@mui/material/styles';

function MuiThemeProvider({ children }) {
    const MuiTheme = createTheme({
        palette: {
            primary: {
                main: "#f89f27",
                contrastText: "#fefefe",
            },
            secondary: {
                main: "#718086",
                contrastText: "#fefefe"
            },
            text: {
                primary: "#718086",
                secondary: "#718086",
                disabled: "#71808650",
            },
        },
        shape: {
            borderRadius: ".5rem"
        },
        components: {
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                },
                styleOverrides: {
                    root: {
                        flexShrink: 0
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        borderRadius: "1rem"
                    }
                }
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: "1rem 1.5rem"
                    }
                },
            },
            MuiTabPanel: {
                styleOverrides: {
                    root: {
                        padding: "1.5rem 0"
                    }
                }
            },
            MuiAutocomplete: {
                defaultProps: {
                    noOptionsText: "Veri bulunamadı!"
                }
            },
            MuiAlert: {
                styleOverrides: {
                    message: {
                        color: "inherit"
                    }
                }
            },
        },
        typography: {
            fontFamily: "Rubik",
            button: {
                textTransform: "none",
                fontWeight: 400,
            }
        },
    });
    return (
        <ThemeProvider theme={MuiTheme}>
            {children}
        </ThemeProvider>
    )
}

export default MuiThemeProvider