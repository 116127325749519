import { Box, Fade } from '@mui/material'

const SuspenseFallback = () => {
    return (
        <Fade in={true} >
            <Box style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <img src="/assets/images/logo.svg" alt="" />
            </Box>
        </Fade>
    )
}

export default SuspenseFallback;
