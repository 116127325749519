import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import CustomDialog from '../../../components/atoms/CustomDialog';
import Label from '../../../components/atoms/Label';


const DetailModal = React.memo(({ queryId, data, searchParams, setSearchParams, role }) => {
    const navigate = useNavigate();
    const traveler = data.find(v => v.id == queryId)

    const handleClose = () => {
        if (searchParams.has('id')) {
            searchParams.delete('id');
            setSearchParams(searchParams);
        }
    }

    const labels = [
        {
            title: "İsim",
            value: traveler?.firstName,
        },
        {
            title: "Soyisim",
            value: traveler?.lastName,
        },
        {
            title: "Kurum",
            value: traveler?.workTime?.customer?.title,
        },
        {
            title: "Yolcu Tipi",
            value: traveler?.workTime?.customer?.customerType === "Company" ? "Personel" : traveler?.workTime?.customer?.customerType === "School" ? "Öğrenci" : "",
        },
        {
            title: traveler?.workTime?.customer?.customerType === "Company" ? "Telefon" : traveler?.workTime?.customer?.customerType === "School" ? "Veli Telefon" : "",
            value: traveler?.workTime?.customer?.customerType === "Company" ? traveler?.phone?.areaCode + traveler?.phone?.number : traveler?.workTime?.customer?.customerType === "School" ? traveler?.parent?.phone?.areaCode + traveler?.parent?.phone?.number : "",
        },
        {
            title: "Adres",
            value: ` ${traveler?.address?.neighborhood || "-"} 
            ${traveler?.address?.street || "-"} Cd/Sk. 
            No: ${traveler?.address?.buildingInformation || "-"} 
            ${traveler?.address?.district || "-"}/ 
            ${traveler?.address?.province || "-"}`,
        },
    ]

    return (
        <CustomDialog
            size="md"
            open={queryId ? true : false}
            closeFunc={handleClose}
            title="Yolcu Detayı"
            buttons={role === 'ADMIN' || role === 'MANAGER' || role === "CUSTOMER" ? [
                {
                    onClick: () => navigate(`/app/musteriler/${traveler?.workTime?.customer?.id}/yolcular/duzenle/${traveler?.id}`),
                    type: "submit",
                    variant: "contained",
                    text: "Düzenle",
                }
            ] : []}
        >
            <Grid container spacing={3}>
                {
                    data.length > 0 &&
                    labels.map((v, i) =>
                        <Grid key={i} item xs={12} md={6}>
                            <Label
                                title={v.title}
                                value={v.value}
                            />
                        </Grid>
                    )
                }
            </Grid>
        </CustomDialog>
    )
})

export default DetailModal;