import { useEffect, useState } from 'react';
import { Typography, Grid, List, ListItem, IconButton, ListItemText, Divider, Box, Autocomplete, TextField } from '@mui/material';
import { getTravelersByIdListRequest } from '../../../../api/controllers/traveler-controller';
import { DeleteRounded } from '@mui/icons-material';
import CustomDialog from '../../../atoms/CustomDialog';
import { toast } from 'react-toastify';
import { updateStudentWorkTimeByIdListRequest } from '../../../../api/controllers/student-controller';
import { updateEmployeeWorkTimeByIdListRequest } from '../../../../api/controllers/employee-controller';

const ChangeWorkTime = (props) => {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [data, setData] = useState([]);
    const [workTime, setWorkTime] = useState(null);

    const handleClose = () => {
        props.setChangeWorkTimeModal(false);
        setWorkTime(null);
    }

    const getTravelers = async () => {
        let res = await getTravelersByIdListRequest(props.selectedRows);
        if (res) {
            setData(res.data);
        }
    }

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res;
            if (props.customer.customerType === "Company") {
                res = await updateEmployeeWorkTimeByIdListRequest(workTime.id, props.selectedRows)
            } else if (props.customer.customerType === "School") {
                res = await updateStudentWorkTimeByIdListRequest(workTime.id, props.selectedRows)
            }
            if (res) {
                handleClose();
                props.getData();
                props.setSelectedRows([]);
                toast.success("Değişiklik kaydedildi");
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    const handleReset = () => {
        props.setSelectedRows([]);
        handleClose();
    }

    useEffect(() => {
        props.selectedRows.length > 0 && props.changeWorkTimeModal &&
            getTravelers();
    }, [props.selectedRows, props.changeWorkTimeModal])

    return (
        <CustomDialog
            size="sm"
            open={props.changeWorkTimeModal}
            closeFunc={handleClose}
            title="Vardiya Değiştir"
            buttons={[
                {
                    onClick: handleReset,
                    type: "button",
                    variant: "outlined",
                    text: "İptal Et",
                },
                {
                    onClick: handleSubmit,
                    type: "submit",
                    loading: isSubmitting,
                    variant: "contained",
                    text: "Kaydet",
                }
            ]}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ bgcolor: "var(--bg)", p: "1rem", borderRadius: ".5rem" }}>
                        <Typography variant="overline">
                            | SEÇİLEN YOLCULAR LİSTESİ {data.length > 0 ? `(${data.length} KİŞİ)` : ""}
                        </Typography>
                        <List
                            sx={{ width: "100%", maxWidth: "100%", maxHeight: 200, overflowY: "auto" }}
                        >
                            {
                                data.map((traveler, i) => (
                                    <>
                                        <ListItem
                                            key={i}
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor: "var(--secondary_12)",
                                                }
                                            }}
                                            secondaryAction={
                                                <IconButton
                                                    edge="end"
                                                    size="small"
                                                    color="secondary"
                                                    onClick={() => props.setSelectedRows(prev => prev.filter(v => v !== traveler.id))}
                                                >
                                                    <DeleteRounded />
                                                </IconButton>
                                            }
                                            disablePadding
                                        >
                                            <ListItemText id={i} primary={`${traveler.firstName} ${traveler.lastName}`} />
                                        </ListItem>
                                        {
                                            data.length !== i &&
                                            <Divider component="li" />
                                        }
                                    </>
                                ))
                            }
                        </List>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        placeholder="Atanacak yeni vardiyayı seçin"
                        options={props.workTimes}
                        value={workTime}
                        onChange={(event, newValue) => setWorkTime(newValue || null)}
                        getOptionLabel={option => `${option.title} (${option.startTime.slice(0, 5)} - ${option.endTime.slice(0, 5)})`}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label='Yeni Vardiya'
                                fullWidth
                            />}
                    />
                </Grid>
            </Grid>
        </CustomDialog >
    )
};

export default ChangeWorkTime;