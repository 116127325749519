import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { getAddress } from '../Utils';
import CustomPopup from './CustomPopup';
import { mapSearchListRequest } from '../../api/controllers/map-controller';
import { useOverTime } from '../../context/OverTimeContext';

function FilterAreaForOverTimeStation() {

    const { areaPolygon, setAreaPolygon, stationForm, setStationForm } = useOverTime();

    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [form, setForm] = useState({
        province: "Denizli",
        country: "Türkiye",
        district: null,
        neighborhood: ""
    })

    console.log('form',

    )

    const getPolygon = async () => {
        let res = await mapSearchListRequest([{
            districtName: form.district,
            neighbourhoodNames: [form.neighborhood]
        }], form.province);
        if (res) {
            if (res.data.length > 0) {
                const list = [{ text: form.neighborhood, coordinates: res.data[0].map(v => ([v.lat, v.lng])) }]
                setAreaPolygon(list);
            }
        }
    }

    useEffect(() => {
        setStationForm({ ...stationForm, province: form.province, district: form.district, neighborhood: form.neighborhood })
        if (form.neighborhood) {
            getPolygon();
        } else {
            setAreaPolygon([]);
        }
    }, [form.province, form.district, JSON.stringify(form.neighborhood)])

    useEffect(() => {
        getAddress({ setProvinces, setDistricts, setNeighborhoods, province: form.province, district: form.district })
    }, [form.province, form.district])

    return (
        <>
            <Autocomplete
                options={provinces.map(p => p?.provinceName)}
                value={form.province}
                onChange={(event, newValue) => {
                    form.province = newValue;
                    form.district = null;
                    form.neighborhood = "";
                    setForm({ ...form })
                }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label='İl'
                    />}
            />
            <Autocomplete
                disabled={!form.province}
                options={districts.map(d => d?.districtName)}
                value={form.district}
                onChange={(event, newValue) => {
                    form.district = newValue;
                    form.neighborhood = "";
                    setForm({ ...form })
                }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label='İlçe'
                        fullWidth
                    />}
            />
            <Autocomplete
                disabled={!form.district}
                options={neighborhoods.map(n => n?.neighborhoodName)}
                value={form.neighborhood}
                onChange={(event, newValue) => {
                    setForm({ ...form, neighborhood: newValue })
                }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label='Mahalle'
                        fullWidth
                    />}
            />
        </>
    )
}

export default FilterAreaForOverTimeStation;