import { useEffect, useState } from 'react';
import { getManagerRequest, updateManagerRequest } from '../../../api/controllers/manager-controller';
import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import CustomDialog from '../../../components/atoms/CustomDialog';
import { toast } from 'react-toastify';
import PhoneTextfield from '../../../components/atoms/PhoneTextfield';

const UpdateManagerModal = ({ updateModal, setUpdateModal, getManagers, selectedManagerId, setSelectedManagerId }) => {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const getManager = async () => {
        let res = await getManagerRequest(selectedManagerId);
        if (res) {
            formik.setValues(res.data);
        }
    }

    const formik = useFormik({
        initialValues: {
            email: "",
            firstName: "",
            lastName: "",
            password: "",
            phone: {
                areaCode: "",
                countryCode: "",
                number: ""
            },
            username: ""
        },
        onSubmit: values => {
            onSubmit(values);
        },
    });

    const handleClose = () => {
        setUpdateModal(false);
        setSelectedManagerId(null);
        formik.resetForm();
    }

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await updateManagerRequest(formik.values);
            if (res && res.status === 200) {
                handleClose();
                getManagers();
                toast.success("Yönetici başarıyla güncellendi!")
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    useEffect(() => {
        if (selectedManagerId) {
            getManager();
        }
    }, [selectedManagerId])

    return (
        <CustomDialog
            open={updateModal}
            closeFunc={handleClose}
            title="Yönetici Detayları"
            onSubmit={formik.handleSubmit}
            buttons={[
                {
                    onClick: formik.handleSubmit,
                    type: "submit",
                    loading: isSubmitting,
                    variant: "contained",
                    text: "Kaydet",
                }
            ]}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="firstName"
                        label="İsim"
                        placeholder="Mehmet"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="lastName"
                        label="Soyisim"
                        placeholder="Yılmaz"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PhoneTextfield
                        name="phone.number"
                        phone={formik.values.phone}
                        onChange={({ value }) => formik.setFieldValue("phone.number", value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="email"
                        label="Mail Adresi"
                        placeholder="mehmet@yilmaz.com"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="username"
                        label="Kullanıcı Adı"
                        placeholder="myilmaz"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.username}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="password"
                        label="Şifre"
                        fullWidth
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="rePassword"
                        label="Şifre (Tekrar)"
                        fullWidth
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.rePassword}
                    />
                </Grid>
            </Grid>
        </CustomDialog >
    )
};

export default UpdateManagerModal;