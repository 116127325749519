import Router from "./components/Router";
import ApiProvider from "./api/ApiProvider";
import "moment/locale/tr";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Providers from "./context";
import MuiThemeProvider from "./components/MuiThemeProvider";

function App() {
  return (
    <Providers>
      <MuiThemeProvider>
        <ApiProvider>
          <ToastContainer position="bottom-left" autoClose={2000} />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Router />
          </LocalizationProvider>
        </ApiProvider>
      </MuiThemeProvider>
    </Providers>
  );
}

export default App;
