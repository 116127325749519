import { forwardRef, useMemo } from 'react'
import { Marker, useMapEvents } from 'react-leaflet';
import L, { Icon } from 'leaflet';
import station from "../../../assets/markers/station.svg";
import station_red from "../../../assets/markers/station_red.svg";
import { useOverTime } from '../../../context/OverTimeContext';

type typeplan = "arrival" | "departure";

const CreateStationMarkerForOverTimeForNeighborhood = ({ planType }: { planType: typeplan }) => {
    const { stationForm, setStationForm } = useOverTime();

    const icon = new L.Icon({
        iconUrl: planType === "departure" ? station : station_red,
        iconRetinaUrl: planType === "departure" ? station : station_red,
        iconAnchor: [16, 32],
        popupAnchor: undefined,
        shadowUrl: undefined,
        shadowSize: undefined,
        shadowAnchor: undefined,
        iconSize: [32, 32],
    });

    useMapEvents({
        click(e) {
            if (!stationForm.position.latitude || !stationForm.position.longitude) {
                setStationForm(prev => ({ ...prev, position: { latitude: e.latlng.lat, longitude: e.latlng.lng } }));
            }
        },
    })

    const eventHandlers = useMemo(
        () => ({
            dragend(e: any) {
                setStationForm((prev) => ({ ...prev, position: { latitude: e.target._latlng.lat, longitude: e.target._latlng.lng } }));
            },
        }),
        [],
    )

    return (
        stationForm.position.latitude && stationForm.position.longitude ?
            <MemoizedMarker
                eventHandlers={eventHandlers}
                position={[stationForm.position.latitude, stationForm.position.longitude]}
                icon={icon}
            /> : <></>
    )

}

export default CreateStationMarkerForOverTimeForNeighborhood;

type Ref = L.Marker

const MemoizedMarker = forwardRef<Ref, { eventHandlers: any, position: any, icon: Icon, }>((props, ref) => useMemo(() => {

    return (
        <Marker
            ref={ref}
            draggable
            eventHandlers={props.eventHandlers}
            position={props.position}
            icon={props.icon}
        />
    )
}, [props.position[0], props.position[1]]))