import axios from "axios";

export const deleteBgImageRequest = (id) => axios.delete(`/backgroundphoto/${id}`)

export const getBgImagesRequest = () => axios.get(`/backgroundphoto/getAll`)

export const getBgImageRequest = () => axios.get(`/backgroundphoto/selected`)

export const createBgImageRequest = (file) => axios.post(`/backgroundphoto/`, file)

export const setSelectedBgImageRequest = (id) => axios.put(`/backgroundphoto/${id}/select`)