import { useContext } from "react";
import styles from "./style.module.scss";
import { Box, Button, Divider, Typography } from "@mui/material";
import PlansSidebarLayout from "../../../templates/PlansSidebarLayout";
import {
  AirlineSeatReclineNormalRounded,
  ArrowBackRounded,
  DirectionsBusRounded,
  EditRounded,
  RouteRounded,
} from "@mui/icons-material";
import DetailTag from "../../../atoms/DetailTag";
import no_data from "../../../../assets/no-data.svg";
import moment from "moment";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate, useParams } from "react-router";
import { useOverTime } from "../../../../context/OverTimeContext";
import StationCardForOverTime from "../../../molecules/StationCardForOverTime";
import Cost from "../../../atoms/Cost";
import turkish_lira from "../../../../assets/icons/turkish-lira.svg";
import { CostContext } from "../../../../pages/app/Mesailer/mesai-detay";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";

function ShowPlan() {
  const navigate = useNavigate();
  const { planUuid, mesaiId } = useParams();
  const { currentUser } = useAuth();
  const { role } = currentUser;
  const { setUpdateForm, plan, getOverTime, resetUpdateForm } = useOverTime();
  const cost: any = useContext(CostContext);

  // EXCEL EXPORTING
  const headersExcel = [
    { label: "İsim", key: "title" },
    { label: "Araç", key: "vehicle" },
    { label: "Sürücü", key: "driver" },
    { label: "Mesafe", key: "distance" },
    { label: "Başlangıç Zamanı", key: "startTime" },
    { label: "Bitiş Zamanı", key: "endTime" },
    { label: "Duraklar", key: "stationList" },
    { label: "Yolcular", key: "travelers" },
  ];
  const firstRow = [
    {
      title: plan?.title || " - ",
      vehicle: plan?.vehicle?.licensePlate || " - ",
      driver:
        plan?.vehicle?.driver?.firstName +
        " " +
        plan?.vehicle?.driver?.lastName || " - ",
      distance: plan?.distance + " metre" || " - ",
      stationList: "",
      startTime: plan?.startTime || " - ",
      endTime: plan?.endTime || " - ",
    },
  ];
  const secondRow = plan?.stations.map((v, i) => ({
    title: "",
    vehicle: "",
    driver: "",
    distance: "",
    stationList: v?.title,
    startTime: "",
    endTime: "",
    travelers: v?.travelers.map(
      (c, l) => " " + c?.firstName + " " + c?.lastName || " - "
    ),
  }));
  const data = secondRow ? firstRow.concat(secondRow) : firstRow;
  // -- //

  return (
    <PlansSidebarLayout
      header={{
        iconButton: {
          icon: <ArrowBackRounded fontSize="inherit" />,
          props: {
            onClick: () => {
              navigate(window.location.pathname.split(`/plan/${planUuid}`)[0]);
              resetUpdateForm();
              getOverTime(Number(mesaiId))
            },
          },
        },
        title: {
          text: plan?.title || "Güzergah Detayı",
        },
        button:
          role === "ADMIN" || role === "MANAGER"
            ? {
              text: "Düzenle",
              props: {
                onClick: async () => {
                  await setUpdateForm((prev: any) => ({
                    ...prev,
                    plan: plan,
                  }));
                  navigate("duzenle");
                },
                startIcon: <EditRounded fontSize="inherit" />,
                variant: "outlined",
                color: "secondary",
              },
            }
            : null,
      }}
    >
      <Box className={styles.container}>
        <Box className={styles.header}>
          <Box className={styles.tags}>
            <DetailTag
              icon={<DirectionsBusRounded fontSize="inherit" />}
              text={plan?.vehicle?.licensePlate || "Bilinmiyor"}
            />
            <DetailTag
              icon={<i className="bi bi-person-fill" />}
              text={
                plan?.vehicle?.driver
                  ? `${plan?.vehicle.driver?.firstName || ""} ${plan?.vehicle.driver?.lastName || ""
                  }`
                  : "Bilinmiyor"
              }
            />
            <DetailTag
              icon={<AirlineSeatReclineNormalRounded fontSize="inherit" />}
              text={plan?.vehicle?.capacity.toString() || "Bilinmiyor"}
            />
            <DetailTag
              icon={<i className="bi bi-stopwatch-fill" />}
              text={
                plan?.duration
                  ? `~ ${moment.duration(plan?.duration).hours()
                    ? moment.duration(plan?.duration).hours() + " saat "
                    : ""
                  }${moment.duration(plan?.duration).minutes() + " dakika"}`
                  : "Bilinmiyor"
              }
            />
            <DetailTag
              icon={<RouteRounded fontSize="inherit" />}
              text={
                plan?.distance
                  ? `~ ${new Intl.NumberFormat("tr-TR").format(
                    plan?.distance
                  )} metre`
                  : "Bilinmiyor"
              }
            />
            <DetailTag
              icon={
                <>
                  {" "}
                  <img
                    src={turkish_lira}
                    alt=""
                    style={{ width: "12px", height: "12px" }}
                  />
                </>
              }
              text={
                cost?.costKm && plan?.distance
                  ? ((cost.costKm * plan.distance) / 1000).toFixed(2).toString()
                  : "Bilinmiyor"
              }
              tooltip={cost && <Cost cost={cost} />}
            />
          </Box>
          <Divider flexItem />
          <Typography variant="h6">| Güzergah için seçilen duraklar</Typography>
        </Box>
        <Box className={styles.list}>
          {plan?.stations && plan.stations.length > 0 ? (
            plan?.stations?.map((station, index) => (
              <StationCardForOverTime
                key={station.id}
                station={station}
                index={index}
              />
            ))
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="1rem"
              p="1rem"
            >
              <img src={no_data} alt="" />
              <Typography>Durak bulunamadı!</Typography>
            </Box>
          )}
        </Box>

        <CSVLink
          data={data}
          headers={headersExcel}
          separator={";"}
          filename={`${plan?.title}.csv`}
        >
          <Button
            fullWidth
            startIcon={<DownloadIcon />}
            variant="outlined"
            sx={{ color: "#4CAF50", borderColor: "#4CAF50", minWidth: "100px" }}
          >
            Excel
          </Button>
        </CSVLink>
      </Box>
    </PlansSidebarLayout>
  );
}

export default ShowPlan;
