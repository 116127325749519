import axios from "axios";

export const getStudentsRequest = (pageable) => axios.get(`/student?page=${pageable.page}&size=${pageable.size}`)

export const getStudentByIdRequest = (id) => axios.get(`/student/${id}`)

export const uploadStudentsExcelRequest = (file) => axios.post(`/student/excel`, file)

export const saveStudentListRequest = (studentList) => axios.post(`/student/excel/save`, studentList)

export const getStudentsByWorkTimeIdRequest = (workTimeId) => axios.get(`/student/workTime/${workTimeId}`)

export const getStudentsByWorkTimeIdPageableRequest = (workTimeId, pageable) => axios.get(`/student/workTime/${workTimeId}?page=${pageable.page}&size=${pageable.size}`)

export const saveStudentRequest = (form) => {
    let data = { ...form, parent: null, workTime: null, workTimeId: form.workTime.id || null };
    return axios.post(`/student/${form.parent.id}`, data);
}

export const updateStudentRequest = (form) => {
    let data = { ...form, parent: null, parentId: form.parent.id || null, workTime: null, workTimeId: form.workTime.id || null }
    return axios.put(`/student/${data.id}`, data);
}

export const addParentInStudentRequest = (id, parentId) => axios.put(`/student/${id}/${parentId}`) //eklenmiş parentı studenta ekleme isteği

export const updateStudentWorkTimeByIdListRequest = (workTimeId, idList) => axios.put(`/student/changeWorkTime?workTimeId=${workTimeId}`, idList)
