import { useEffect, useState } from 'react';
import { Grid, TextField, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import PhoneTextfield from '../../../components/atoms/PhoneTextfield';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { saveEmployeeRequest } from '../../../api/controllers/employee-controller';
import { useNavigate } from 'react-router-dom';
import { getWorkTimesByCustomerIdRequest } from '../../../api/controllers/work-time-controller';
import AddressModal from '../../../components/molecules/AddressModal';
import CustomDialog from '../../../components/atoms/CustomDialog';
import { getDepartmentsByCustomerIdRequest } from '../../../api/controllers/department-controller';

function PersonelEkle({ customer, open, setOpen, getTravelers }) {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [workTimes, setWorkTimes] = useState([]);
    const [departments, setDepartments] = useState([])

    const formik = useFormik({
        validationSchema: yup.object({
            firstName: yup
                .string()
                .required('Bu alan gereklidir'),
            lastName: yup
                .string()
                .required('Bu alan gereklidir'),
            phone: yup.object({
                number: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .length(10, 'Telefon numarası 10 haneli olmalıdır'),
            }),
            workTime: yup
                .object()
                .required('Bu alan gereklidir')
                .nullable(),
            department: yup
                .object()
                .required('Bu alan gereklidir')
                .nullable(),
            address: yup.object({
                province: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                district: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                neighborhood: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                street: yup
                    .string()
                    .required('Bu alan gereklidir'),
                buildingInformation: yup
                    .string()
                    .required('Bu alan gereklidir'),
            }),
        }),
        initialValues: {
            address: {
                buildingInformation: "",
                country: "",
                district: null,
                fullAddress: "",
                neighborhood: null,
                position: {
                    latitude: null,
                    longitude: null
                },
                province: "Denizli",
                street: ""
            },
            email: "",
            firstName: "",
            lastName: "",
            phone: {
                areaCode: "+90",
                countryCode: "",
                number: ""
            },
            workTime: null,
            department: null,
        },
        onSubmit: () => onSubmit()
    });

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await saveEmployeeRequest(customer.id, formik.values);
            if (res) {
                toast.success("Yolcu kaydedildi");
                formik.resetForm();
                setOpen(false)
                getTravelers()
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    useEffect(() => {
        (async () => {
            let res = await getWorkTimesByCustomerIdRequest(customer.id);
            if (res) {
                setWorkTimes(res.data);
            }
            let rex = await getDepartmentsByCustomerIdRequest(customer.id);
            if (rex) {
                setDepartments(rex.data);
            }
        })()
    }, [])



    return (
        <>
            <CustomDialog
                title="Yolcu Ekle"
                open={open}
                setOpen={setOpen}
                onSubmit={formik.handleSubmit}
                buttons={[
                    {
                        type: "submit",
                        variant: "contained",
                        text: "Kaydet",
                    }
                ]}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="firstName"
                            label="İsim"
                            placeholder="Personel ismini girin"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            error={formik.touched.firstName && formik.errors.firstName}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="lastName"
                            label="Soyisim"
                            placeholder="Personel soyismini girin"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            error={formik.touched.lastName && formik.errors.lastName}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PhoneTextfield
                            name="phone.number"
                            phone={formik.values.phone}
                            onChange={({ value }) => formik.setFieldValue("phone.number", value)}
                            error={formik.touched.phone?.number && formik.errors.phone?.number}
                            helperText={formik.touched.phone?.number && formik.errors.phone?.number}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={workTimes}
                            value={formik.values.workTime}
                            onChange={(event, newValue) => formik.setFieldValue("workTime", newValue || null)}
                            getOptionLabel={option => `${option.title} (${option.startTime.slice(0, 5)} - ${option.endTime.slice(0, 5)})`}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label='Vardiya'
                                    fullWidth
                                    error={formik.touched.workTime && formik.errors.workTime}
                                    helperText={formik.touched.workTime && formik.errors.workTime}
                                />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AddressModal formik={formik} />
                    </Grid>
                </Grid>

            </CustomDialog>
        </ >
    )
}

export default PersonelEkle;