import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

type Props = {
  children: React.ReactNode;
  open: boolean;
  setOpen?: (value: boolean) => void;
  closeFunc?: () => void;
  title: string;
  buttons?: Array<{ text: string } & LoadingButtonProps>;
  onSubmit?: () => void;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
};

const CustomDialog = ({
  children,
  open,
  setOpen,
  closeFunc,
  title,
  buttons,
  onSubmit,
  size,
}: Props) => {
  const handleClose = () => {
    setOpen && setOpen(false);
  };

  return (
    <Dialog
      onClose={closeFunc || handleClose}
      open={open}
      fullWidth
      maxWidth={size || "md"}
      scroll="paper"
    >
      <form onSubmit={onSubmit}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {title}
          <IconButton
            onClick={closeFunc || handleClose}
            style={{ padding: ".5rem" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          style={!buttons ? { borderBottom: "none" } : undefined}
        >
          {children}
        </DialogContent>
        <DialogActions>
          {buttons &&
            buttons.length > 0 &&
            buttons.map((v, index) => {
              const { text, ...props } = v;
              return (
                <LoadingButton key={index} {...props}>
                  {text}
                </LoadingButton>
              );
            })}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CustomDialog;
