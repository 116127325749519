import { useMemo } from 'react'
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import styles from './style.module.scss';
import customer from '../../../assets/markers/customer.svg';

const CustomerMarker = ({ position }) => useMemo(() => {
    return (
        <Marker
            position={position}
            icon={markerIcon}
        />
    )
}, [position])

export default CustomerMarker;

const icon = renderToStaticMarkup(
    <img src={customer} alt="" />
)

const markerIcon = new L.divIcon({
    iconAnchor: [16, 32],
    shadowAnchor: null,
    iconSize: [32, 32],
    className: styles.marker,
    html: icon,
});