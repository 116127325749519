import { useState } from 'react';
import styles from './style.module.scss';
import { Box, Button, Divider, Typography, Alert } from '@mui/material';
import PlansSidebarLayout from '../../../../templates/PlansSidebarLayout';
import { AddRounded, AirlineSeatReclineNormalRounded, ArrowBackRounded, DirectionsBusRounded, RouteRounded } from '@mui/icons-material';
import DetailTag from '../../../../atoms/DetailTag';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from 'moment';
import { getOptimizedRouteRequest } from '../../../../../api/controllers/route-controller';
import { useCustomer } from '../../../../../context/CustomerContext';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router';
import { useOverTime } from '../../../../../context/OverTimeContext';
import StationCardForOverTime from '../../../../molecules/StationCardForOverTime';
import { deleteStationRequest } from '../../../../../api/controllers/overtime-controller';
import Cost from '../../../../atoms/Cost';
import { CostContext } from '../../../../../pages/app/Mesailer/mesai-detay';
import { useContext } from 'react';
import turkish_lira from '../../../../../assets/icons/turkish-lira.svg'

function Step2() {
    const navigate = useNavigate();
    const { planType, planUuid, mesaiId } = useParams();
    const { updateForm, setPlan, setUpdateForm, overTime, getOverTime, setTravelers, } = useOverTime();
    const { plan } = updateForm;
    const { customer } = useCustomer();
    const [isDragged, setIsDragged] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const cost = useContext(CostContext)

    const getOptimizedStations = async () => {
        setIsSubmitting(true);
        const driver_position = plan?.vehicle?.driver?.position ? { lat: plan.vehicle.driver.position.latitude, lng: plan.vehicle.driver.position.longitude } : null;
        const stations_position = plan?.stations.map(v => ({ lat: v.position.latitude, lng: v.position.longitude }));
        const customer_position = customer?.address?.position ? { lat: customer.address.position.latitude, lng: customer.address.position.longitude } : null;
        const list = (planType === "departure" ? [driver_position, ...stations_position, customer_position] : [customer_position, ...stations_position, driver_position]);

        if (!driver_position) {
            toast.warn("Servis sürücüsünün adresi bulunamadı!")
        } else if (!customer_position) {
            toast.warn("Müşteri adresi bulunamadı!")
        } else {
            try {
                let res = await getOptimizedRouteRequest(list);
                if (res) {
                    let list = res.data.wayPoints.map((val, i) => {
                        if (i !== 0 || i !== res.data.wayPoints.length - 1) {
                            return (plan.stations[val.oldIndex - 1])
                        }
                    })

                    setUpdateForm(prev => ({ ...prev, plan: { ...prev.plan, stations: list.filter(v => v) } }));
                    setIsDragged(false);
                }
            } catch (error) { }
        }
        setIsSubmitting(false);
    }

    const onDragEnd = ({ source, destination }) => {
        if (source && destination) {
            const element = plan.stations.splice(source.index, 1)[0];
            plan.stations.splice(destination.index, 0, element);
            setUpdateForm(prev => ({ ...prev, plan: plan }));
            setIsDragged(true);
        }
    }

    const deleteStation = async (stationId) => {
        const confirm = window.confirm("Silmek istediğinize emin misiniz?");
        if (confirm) {
            let index = overTime?.plans?.findIndex((v) => v.uuid === planUuid && v.planDirectionType === planType.toUpperCase())
            let res = await deleteStationRequest(mesaiId, plan.id, stationId)
            if (res) {
                const overtime = await getOverTime(mesaiId)
                setPlan(overtime.plans[index])
                setTravelers(overtime.travelers)
                setUpdateForm((prev) => ({ ...prev, step: 2, plan: { ...plan, stations: overtime.plans[index].stations } }))
            }
        }
    }

    return (
        <PlansSidebarLayout
            header={{
                iconButton: {
                    icon: <ArrowBackRounded fontSize="inherit" />,
                    props: {
                        onClick: () => setUpdateForm(prev => ({ ...prev, step: prev.step - 1 })),
                    }
                },
                title: {
                    text: plan?.title || "Güzergahı Düzenle"
                },
            }}
            footer={{
                buttons: [
                    {
                        text: "Geri dön",
                        props: {
                            onClick: () => setUpdateForm(prev => ({ ...prev, step: prev.step - 1 })),
                            variant: "outlined",
                            color: "secondary",
                        }
                    },
                    {
                        text: "Devam et",
                        props: {
                            onClick: () => setUpdateForm(prev => ({ ...prev, step: prev.step + 1 })),
                            variant: "contained",
                        }
                    },
                ]
            }}
        >
            <Box className={styles.container}>
                <Box className={styles.header}>
                    <Box className={styles.tags}>
                        <DetailTag
                            icon={<DirectionsBusRounded fontSize="inherit" />}
                            text={plan.vehicle?.licensePlate || "Bilinmiyor"}
                        />
                        <DetailTag
                            icon={<i className="bi bi-person-fill" />}
                            text={plan.vehicle?.driver ? `${plan.vehicle.driver?.firstName || ""} ${plan.vehicle.driver?.lastName || ""}` : "Bilinmiyor"}
                        />
                        <DetailTag
                            icon={<AirlineSeatReclineNormalRounded fontSize="inherit" />}
                            text={plan.vehicle?.capacity || "Bilinmiyor"}
                        />
                        <DetailTag
                            icon={<i className="bi bi-stopwatch-fill" />}
                            text={plan.duration ? `~ ${moment.duration(plan.duration).hours() ? moment.duration(plan.duration).hours() + " saat " : ""}${moment.duration(plan.duration).minutes() + " dakika"}` : "Bilinmiyor"}
                        />
                        <DetailTag
                            icon={<RouteRounded fontSize="inherit" />}
                            text={plan.distance ? `~ ${new Intl.NumberFormat('tr-TR').format(plan.distance)} metre` : "Bilinmiyor"}
                        />
                        <DetailTag
                            icon={<> <img src={turkish_lira} alt="" style={{ width: "12px", height: "12px" }} /></>}
                            text={cost.costKm && plan.distance ? <> {((cost.costKm * plan.distance) / 1000).toFixed(2)}</> : "Bilinmiyor"}
                            tooltip={cost && <Cost cost={cost} />}
                        />
                    </Box>
                    <Divider flexItem />
                    <Box className={styles.sub_header}>
                        <Typography variant="h6">
                            | Duraklar
                        </Typography>
                        <Box display="flex" gap=".5rem">
                            {
                                isDragged &&
                                <LoadingButton loading={isSubmitting} onClick={getOptimizedStations} size="small" variant="contained">
                                    Optimize Et
                                </LoadingButton>
                            }
                            {
                                customer.customerType === "Company" &&
                                <Button onClick={() => navigate("station/olustur")} size="small" variant="contained" color="secondary" startIcon={<AddRounded fontSize="inherit" />}>
                                    Durak Ekle
                                </Button>
                            }
                        </Box>
                    </Box>
                    {
                        customer.customerType === "Company" &&
                        <Button onClick={() => navigate("station/mahalle-olustur")} size="small" variant="contained" color="secondary" startIcon={<AddRounded fontSize="inherit" />}>
                            Mahalle İçindekilere Toplu Durak Atama
                        </Button>
                    }
                </Box>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" >
                        {(provided) => (
                            <Box className={styles.list}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {
                                    plan?.stations?.length > 0 ?
                                        plan?.stations.map((station, index) => (
                                            <Draggable key={station.id} draggableId={`${station.id}`} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <StationCardForOverTime
                                                            station={station}
                                                            index={index}
                                                            deleteOnClick={() => deleteStation(station.id)}
                                                            updateOnClick={() => navigate(`station/${station.id}`)}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                        :
                                        <Alert severity="warning">
                                            {
                                                customer.customerType === "Company" &&
                                                "Henüz durak seçilmedi!"
                                            }
                                        </Alert>
                                }
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        </PlansSidebarLayout >
    )
}

export default Step2;