import React from "react";
import no_data from "../../../../assets/no-data.svg";
import { Box, Button, Typography } from "@mui/material";
import { AddRounded } from "@mui/icons-material";
import PlansSidebarLayout from "../../../templates/PlansSidebarLayout";
import PlanCard from "../../../molecules/PlanCard";
import styles from "./style.module.scss";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useStudy } from "../../../../context/StudyContext";
import { createDailyTaskforStudy } from "../../../../api/controllers/daily-task-controller";
import { PlanType } from "../../../../types/CommonTypes";
import { Plans } from "../../../../types/StudyContext";

function ListPlans() {
  const navigate = useNavigate();
  const { planType, etutId } = useParams<{
    planType: PlanType;
    etutId: string;
  }>();

  const { currentUser } = useAuth();
  const { role } = currentUser;
  const { plans, study } = useStudy();

  console.log(study);

  const createDailyTask = async () => {
    let rex = await createDailyTaskforStudy(etutId);
    if (rex) {
      navigate("/app/etutler");
    }
  };

  const isHasArrival = study?.plans?.some((v: any) =>
    v?.planDirectionType.includes("ARRIVAL")
  );
  const isHasDeparture = study?.plans?.some((v: any) =>
    v?.planDirectionType.includes("DEPARTURE")
  );
  const isHasBothWays = isHasArrival && isHasDeparture;


  return (
    <PlansSidebarLayout
      header={{
        button:
          role === "ADMIN" || role === "MANAGER"
            ? {
              text: "Oluştur",
              props: {
                onClick: () => {
                  navigate("olustur");
                },
                startIcon: <AddRounded fontSize="inherit" />,
                variant: "contained",
              },
            }
            : null,
      }}
      footer={{
        buttons: [
          {
            text: "Planlamayı Tamamla",
            props: {
              onClick: () => createDailyTask(),
              variant: "contained",
              disabled:
                study?.customerRequestStatus === "DONE" ||
                (study?.plans && study.plans.length < 1) ||
                (isHasBothWays
                  ? study?.travelers
                    ?.map((v) => v.arrivalEnable)
                    .includes(true) ||
                    study?.travelers
                    ?.map((v) => v.departureEnable)
                    .includes(true)
                  : isHasDeparture
                    ? study?.travelers
                      ?.map((v) => v.departureEnable)
                      .includes(true)
                    : isHasArrival
                      ? study?.travelers
                        ?.map((v) => v.arrivalEnable)
                        .includes(true)
                      : false),
            },
          },
        ],
      }}
    >
      {plans[planType as keyof Plans]?.length === 0 ? (
        <Empty />
      ) : (
        <Box className={styles.list}>
          {plans[planType as keyof Plans]?.map((v, i) => (
            <Link
              key={i}
              style={{ textDecoration: "none" }}
              to={`plan/${v.uuid}`}
            >
              <PlanCard plan={v} />
            </Link>
          ))}
        </Box>
      )}
    </PlansSidebarLayout>
  );
}

export default React.memo(ListPlans);

const Empty = () => {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="1rem"
      p="1rem"
    >
      <img src={no_data} alt="" />
      <Typography>Henüz güzergah bulunmuyor!</Typography>
      <Button
        onClick={() => {
          navigate("olustur");
        }}
        variant="outlined"
        sx={{ bgcolor: "var(--primary_12)" }}
        startIcon={<AddRounded fontSize="inherit" />}
      >
        Yeni güzergah oluştur
      </Button>
    </Box>
  );
};
