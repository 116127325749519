import { useState } from 'react';
import styles from './style.module.scss';
import { Popper, Button, ClickAwayListener, Paper } from '@mui/material';


const CustomPopup = ({ buttonProps, buttonText, children }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={styles.search_area}>
                <Button onClick={handleClick} className={`${styles.area_button} ${anchorEl ? styles.active : ""}`} {...buttonProps}>
                    {
                        buttonText
                    }
                </Button>

                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    sx={{ zIndex: 1001 }}>
                    <Paper className={styles.area_paper}>
                        {children}
                    </Paper>
                </Popper>
            </div>
        </ClickAwayListener>
    )
}

export default CustomPopup;