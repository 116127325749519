import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import CustomDialog from '../atoms/CustomDialog';
import MuiThemeProvider from '../MuiThemeProvider';

const ConfirmDialog = ({ show, proceed, confirmation, options }) => (
    <MuiThemeProvider>
        <CustomDialog
            size="xs"
            open={show}
            closeFunc={() => proceed(false)}
            title="İşlemi onaylıyor musunuz?"
            buttons={[
                {
                    type: "button",
                    onClick: () => proceed(false),
                    variant: "outlined",
                    color: "secondary",
                    text: "İptal et",
                },
                {
                    type: "button",
                    onClick: () => proceed(true),
                    variant: "contained",
                    color: "primary",
                    text: "Onayla",
                },
            ]}
        >
            {confirmation}
        </CustomDialog >
    </MuiThemeProvider>
)

ConfirmDialog.propTypes = {
    show: PropTypes.bool,
    proceed: PropTypes.func,
    confirmation: PropTypes.string,
    options: PropTypes.object
}

export default confirmable(ConfirmDialog);
