import { useEffect, useState } from 'react';
import { getManagerRequest, updateManagerRequest } from '../../../api/controllers/manager-controller';
import PageLayout from '../../../components/templates/PageLayout';
import { Grid, Box, TextField, MenuItem, ListItemIcon, Typography } from '@mui/material';
import { useFormik } from 'formik';
import CustomDataGrid from '../../../components/atoms/CustomDataGrid';
import CustomDialog from '../../../components/atoms/CustomDialog';
import { toast } from 'react-toastify';
import PhoneTextfield from '../../../components/atoms/PhoneTextfield';
import CustomMenu from '../../../components/molecules/CustomMenu';
import { Info } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import styles from './style.module.scss';
import { getCustomersByManagerIdPageableRequest } from '../../../api/controllers/customer-controller';

function Page() {
    const navigate = useNavigate();
    const { managerId } = useParams();

    //States
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);
    const [pageable, setPageable] = useState({ page: 0, size: 10, total: 0 });

    const [updateModal, setUpdateModal] = useState(false);
    const [manager, setManager] = useState({});


    //Tablo sütunları
    const columns = [
        {
            field: 'title',
            headerName: "Ünvan",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value || "-",
        },
        {
            field: 'fullName',
            headerName: "Yetkili",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => `${row.firstName || ""} ${row.lastName || ""}` || "-",
        },
        {
            field: 'phone',
            headerName: "Yetkili Telefon",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? `${value.areaCode || ""} ${value.number || ""}` : "-",
        },
        {
            field: 'email',
            headerName: "Yetkili Mail Adresi",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value : "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ value }) => <CustomMenu>
                <MenuItem onClick={async () => navigate(`/app/musteriler/${value}/detay`)}>
                    <ListItemIcon>
                        <Info fontSize="small" />
                    </ListItemIcon>
                    Detaylar
                </MenuItem>
            </CustomMenu >,
        },
    ]


    //Yöneticiyi getirir
    const getManager = async () => {
        let res = await getManagerRequest(managerId);
        if (res) {
            setManager(res.data);
        }
    }

    const getCustomers = async () => await getCustomersByManagerIdPageableRequest(managerId, pageable).then(res => {
        setData(res.data.content);
        setPageable({ ...pageable, total: res.data.totalElements });
        setIsTableLoading(false);
    })

    const Sidebar = () => {
        const sidebarItems = [
            {
                icon: <i className="bi bi-telephone-fill" />,
                label: "Telefon",
                value: manager?.phone?.number ? `${manager?.phone?.areaCode} ${manager?.phone?.number}` : "-",
            },
            {
                icon: <i className="bi bi-envelope-fill" />,
                label: "Email",
                value: manager?.email || "-",
            },
        ]

        return (
            <Box className={styles.sidebar}>
                {
                    sidebarItems.map(item =>
                        <Box>
                            <Box className={styles.label}>
                                {item.icon} {item.label}
                            </Box>
                            <Box>{item.value || "-"}</Box>
                        </Box>
                    )
                }
                <Box className={styles.statistic}>
                    <Box className={styles.item}>
                        <Box className={styles.icon}>
                            <i className="bi bi-building" />
                        </Box>
                        <span>{data.length}</span>
                    </Box>
                    <Box className={styles.item}>
                        <Box className={styles.icon}>
                            <i className="bi bi-people-fill" />
                        </Box>
                        <span>12</span>
                    </Box>
                </Box>
            </Box>
        )
    }

    useEffect(() => {
        getCustomers();
    }, [pageable.page, pageable.size])


    useEffect(() => {
        getManager();
    }, [])

    return (
        <PageLayout
            title={manager?.firstName + " " + manager?.lastName}
            detailPage
            sidebar={<Sidebar />}
            menuItems={[
                {
                    onClick: () => setUpdateModal(true),
                    icon: null,
                    text: "Yöneticiyi Düzenle",
                },
            ]}
        >
            <Typography
                variant="h6"
                sx={{ marginBottom: "1.5rem" }}
            >| Yöneticinin Müşterileri</Typography>
            <CustomDataGrid
                data={data}
                columns={columns}
                loading={isTableLoading}
                rowId={(params) => params?.id}
                pageable={pageable}
                setPageable={setPageable}
            />


            {/* Güncelleme modalı */}
            <UpdateManagerModal
                updateModal={updateModal}
                setUpdateModal={setUpdateModal}
                getManager={getManager}
                manager={manager}
            />
        </PageLayout>
    )
}

export default Page;

const UpdateManagerModal = ({ updateModal, setUpdateModal, getManager, manager }) => {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formik = useFormik({
        initialValues: manager,
        onSubmit: values => {
            onSubmit(values);
        },
    });

    const handleClose = () => {
        setUpdateModal(false);
        formik.resetForm();
    }

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await updateManagerRequest(formik.values);
            if (res && res.status === 200) {
                handleClose();
                getManager();
                toast.success("Yönetici başarıyla güncellendi!")
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    useEffect(() => {
        formik.setValues(manager);
    }, [manager])

    return (
        <CustomDialog
            open={updateModal}
            closeFunc={handleClose}
            title="Yönetici Detayları"
            onSubmit={formik.handleSubmit}
            buttons={[
                {
                    onClick: formik.handleSubmit,
                    type: "submit",
                    loading: isSubmitting,
                    variant: "contained",
                    text: "Kaydet",
                }
            ]}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="firstName"
                        label="İsim"
                        placeholder="Mehmet"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="lastName"
                        label="Soyisim"
                        placeholder="Yılmaz"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PhoneTextfield
                        name="phone.number"
                        phone={formik.values.phone}
                        onChange={({ value }) => formik.setFieldValue("phone.number", value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="email"
                        label="Mail Adresi"
                        placeholder="mehmet@yilmaz.com"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="username"
                        label="Kullanıcı Adı"
                        placeholder="myilmaz"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.username}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="password"
                        label="Şifre"
                        fullWidth
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="rePassword"
                        label="Şifre (Tekrar)"
                        fullWidth
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.rePassword}
                    />
                </Grid>
            </Grid>
        </CustomDialog >
    )
};