import { useEffect, useState } from 'react';
import { TextField, Autocomplete, Alert } from '@mui/material';
import PhoneTextfield from '../../../../../atoms/PhoneTextfield';
import { toast } from 'react-toastify';
import { saveEmployeeListRequest, uploadEmployeesExcelRequest } from '../../../../../../api/controllers/employee-controller';
import { useNavigate } from 'react-router-dom';
import { getWorkTimesByCustomerIdRequest } from '../../../../../../api/controllers/work-time-controller';
import { getDepartmentsByCustomerIdRequest } from '../../../../../../api/controllers/department-controller';
import ExcelUpload from '../../../../../atoms/ExcelUpload';
import { DataGrid, } from '@mui/x-data-grid';
import { getProvincesRequest } from '../../../../../../api/controllers/addresses-controller';
import { ProvinceInput, DistrictInput, NeighborhoodInput } from '../Utils';
import template from '../../../../../../assets/templates/Personel_Sablon.xlsx';
import ActionPageTitle from '../../../../../atoms/ActionPageTitle';
import AssignWorkTimeMultiple from '../../../../../molecules/AssignWorkTimeMultiple';
import AssignDepartmentMultiple from '../../../../../molecules/AssignDepartmentMultiple';

function CreateMultiple({ customer }) {

    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [workTimes, setWorkTimes] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [uploadResult, setUploadResult] = useState({
        uploaded: false,
        content: [],
    });

    useEffect(() => {
        (async () => {
            let res = await getWorkTimesByCustomerIdRequest(customer.id);
            if (res) {
                setWorkTimes(res.data);
            }

            let rex = await getDepartmentsByCustomerIdRequest(customer.id);
            if (rex) {
                setDepartments(rex.data);
            }

        })()
    }, [])


    const handleUpload = async (file) => {
        const alert = toast.loading("Dosya yükleniyor...")
        const formData = new FormData();
        formData.append('result', file);
        try {
            let res = await uploadEmployeesExcelRequest(formData);
            if (res && res.status === 200) {
                toast.update(alert, { render: "Dosya başarıyla yüklendi", type: "success", isLoading: false, autoClose: true });
                let x = res.data.map((val, i) => ({ ...val, index: i }));
                uploadResult.uploaded = true;
                uploadResult.content = x;
                setUploadResult({ ...uploadResult });
            }
        } catch (error) {
            toast.update(alert, { render: "Dosya yüklenemedi", type: "error", isLoading: false, autoClose: true });
        }
    }

    const columns = [
        {
            field: 'index',
            headerName: '#',
            valueGetter: ({ value }) => value + 1,
            width: 30
        },
        {
            field: 'firstName',
            headerName: 'İsim',
            minWidth: 150,
            renderCell: ({ value, row }) => (
                <TextField
                    fullWidth
                    size="small"
                    value={value || ""}
                    onChange={e => {
                        uploadResult.content[row.index].firstName = e.target.value;
                        setUploadResult({ ...uploadResult });
                    }}
                    placeholder="İsim"
                    error={!value}
                />
            )
        },
        {
            field: 'lastName',
            headerName: 'Soyisim',
            minWidth: 150,
            renderCell: ({ value, row }) => (
                <TextField
                    fullWidth
                    size="small"
                    value={value || ""}
                    onChange={e => {
                        uploadResult.content[row.index].lastName = e.target.value;
                        setUploadResult({ ...uploadResult });
                    }}
                    placeholder="Soyisim"
                    error={!value}
                />
            )
        },
        {
            field: 'phone',
            headerName: 'Telefon',
            minWidth: 200,
            renderCell: ({ value, row }) => (
                <PhoneTextfield
                    size="small"
                    phone={value || ""}
                    onChange={({ value }) => {
                        uploadResult.content[row.index].phone.number = value;
                        setUploadResult({ ...uploadResult });
                    }}
                    error={!value?.number}
                />
            )
        },
        {
            field: 'workTimeId',
            headerName: 'Vardiya',
            minWidth: 200,
            renderCell: ({ value, row }) => (
                <Autocomplete
                    fullWidth
                    options={workTimes}
                    value={workTimes.find(w => w.id === value) || null}
                    onChange={(event, newValue) => {
                        uploadResult.content[row.index].workTimeId = newValue?.id || null;
                        setUploadResult({ ...uploadResult });
                    }}
                    getOptionLabel={option => `${option.title} (${option.startTime.slice(0, 5)} - ${option.endTime.slice(0, 5)})`}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            size="small"
                            placeholder="Vardiya"
                            error={!value}
                        />}
                />
            )
        },
        {
            field: 'departmentId',
            headerName: 'Departman',
            minWidth: 200,
            renderCell: ({ value, row }) => (
                <Autocomplete
                    fullWidth
                    options={departments}
                    value={departments.find(w => w.id === value) || null}
                    onChange={(event, newValue) => {
                        uploadResult.content[row.index].departmentId = newValue?.id || null;
                        setUploadResult({ ...uploadResult });
                    }}
                    getOptionLabel={option => `${option.title}`}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            size="small"
                            placeholder="Departman"
                            error={!value}
                        />}
                />
            )
        },
        {
            field: 'il',
            headerName: 'İl',
            minWidth: 200,
            renderCell: ({ row }) => <ProvinceInput value={row.address} row={row} provinces={provinces} uploadResult={uploadResult} setUploadResult={setUploadResult} />,
        },
        {
            field: 'ilce',
            headerName: 'İlçe',
            minWidth: 200,
            renderCell: ({ row }) => <DistrictInput value={row.address} row={row} provinces={provinces} uploadResult={uploadResult} setUploadResult={setUploadResult} />,
        },
        {
            field: 'mahalle',
            headerName: 'Mahalle',
            minWidth: 200,
            renderCell: ({ row }) => <NeighborhoodInput value={row.address} row={row} provinces={provinces} uploadResult={uploadResult} setUploadResult={setUploadResult} />,
        },
        {
            field: 'cadde',
            headerName: 'Cadde / Sokak',
            minWidth: 200,
            renderCell: ({ row }) => (
                <TextField
                    fullWidth
                    size="small"
                    value={row.address.street || ""}
                    onChange={e => {
                        uploadResult.content[row.index].address.street = e.target.value;
                        setUploadResult({ ...uploadResult });
                    }}
                    placeholder="Cadde / Sokak"
                    error={!row.address.street}
                />
            )
        },
        {
            field: 'bina',
            headerName: 'Bina No',
            minWidth: 50,
            renderCell: ({ row }) => (
                <TextField
                    fullWidth
                    size="small"
                    value={row.address.buildingInformation || ""}
                    onChange={e => {
                        uploadResult.content[row.index].address.buildingInformation = e.target.value;
                        setUploadResult({ ...uploadResult });
                    }}
                    placeholder="Bina No"
                    error={!row.address.buildingInformation}
                />
            )
        },
        {
            field: 'adres_detayi',
            headerName: 'Adres Detayı',
            minWidth: 200,
            renderCell: ({ row }) => (
                <TextField
                    fullWidth
                    size="small"
                    value={row.address.fullAddress || ""}
                    onChange={e => {
                        uploadResult.content[row.index].address.fullAddress = e.target.value;
                        setUploadResult({ ...uploadResult });
                    }}
                    placeholder="Adres Detayı"
                />
            )
        },
    ];

    const onSubmit = async () => {
        setIsSubmitting(true);
        let checkNull = uploadResult.content.find(v => !v.workTimeId || !v.address.province || !v.address.district || !v.address.neighborhood);
        if (checkNull) {
            toast((checkNull.index + 1) + ". satırda bulunan gerekli alanları doldurunuz!", { type: "error" });
        } else {
            try {
                let res = await saveEmployeeListRequest(uploadResult.content);
                if (res) {
                    toast("Yolcular başarıyle eklendi", { type: "success" });
                    navigate(`/app/musteriler/${customer.id}/yolcular`);
                }
            } catch (error) { }
        }
        setIsSubmitting(false);
    }

    useEffect(() => {
        (async () => {
            let res = await getProvincesRequest();
            if (res) {
                setProvinces(res.data);
            }
        })()
    }, [])

    return (
        <>
            <ActionPageTitle
                title="Toplu Yolcu Ekle"
                buttonText={uploadResult.uploaded ? "Kaydet" : "Şablonu İndir"}
                buttonProps={{
                    loading: isSubmitting,
                    onClick: uploadResult.uploaded ? onSubmit : () => window.open(template, '_blank'),
                    variant: uploadResult.uploaded ? "contained" : "outlined",
                    color: uploadResult.uploaded ? "primary" : "success",
                }}
                extraButton={uploadResult.uploaded && <AssignWorkTimeMultiple workTimes={workTimes} uploadResult={uploadResult} setUploadResult={setUploadResult} />}
                extraButton2={uploadResult.uploaded && <AssignDepartmentMultiple departments={departments} uploadResult={uploadResult} setUploadResult={setUploadResult} />}
            />
            {
                !uploadResult.uploaded &&
                <>
                    <Alert severity="info" color="info" sx={{ my: "1.5rem" }}>
                        Yolcuların toplu olarak sorunsuz eklenebilmesi için üst tarafta bulunan şablona uygun yüklemeniz gerekmektedir.
                    </Alert>
                    <ExcelUpload handleUpload={handleUpload} />
                </>
            }
            {
                uploadResult.uploaded &&
                <DataGrid
                    rows={uploadResult.content}
                    columns={columns}
                    getRowId={row => row.index}
                    sx={{
                        minHeight: "60vh",
                        overflowX: "auto",
                    }}
                />
            }
        </>
    )
}


export default CreateMultiple;