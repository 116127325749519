import React from 'react'
import MultipleTravelerMarker from './MultipleTravelerMarker';
import { Popup } from 'react-leaflet';
import { List } from '@mui/material';
import { useParams } from 'react-router';
import { useStudy } from '../../context/StudyContext';
import EmployeeTravelerMarkerItemMultipleForStudy from './EmployeeTravelerMarkerItemMultipleForStudy';

function EmployeeTravelerMarkerMultipleForStudy({ travelers }) {
    const { stationForm } = useStudy();
    const { planType } = useParams();

    return (
        <MultipleTravelerMarker
            position={[travelers[0].address.position.latitude, travelers[0].address.position.longitude]}
            isSelected={travelers.some(traveler => stationForm.travelersId.some(v => v.id == traveler.id))}
            isPassive={!travelers.filter(traveler => traveler[`${planType}Enable`])?.length > 0}
            count={travelers.length}
        >
            <Popup>
                <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {
                        travelers.map((traveler, i) => <EmployeeTravelerMarkerItemMultipleForStudy key={i} traveler={traveler} />)
                    }
                </List>
            </Popup>
        </MultipleTravelerMarker >
    )
}

export default EmployeeTravelerMarkerMultipleForStudy;