import { useCallback } from "react";
import { Cancel, CloudDownloadRounded } from "@mui/icons-material";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { useDropzone } from "react-dropzone";
import styles from "./DocumentUpload.module.scss";
import { BASE_URL } from "../../../api/ApiProvider";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import PDF from '../../../assets/pdf-foto.png'

function DocumentUpload({ documentTypeId, name, document, getDocuments, uploadDocument }) {
    const onDrop = useCallback(acceptedFiles => uploadDocument({ acceptedFiles, documentTypeId }), [])

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        /* accept: {
            'image/*': [],
        }, */
        onDrop
    });

    const removeDocument = async () => {
        let res = await axios.delete(`/document/${document?.id}`)
        if (res) {
            getDocuments();
            toast.success("Belge silindi")
        }
    }

    const addExpiration = async (date) => {
        let res = await axios.put(`/document/${document?.id}?expiration=${date ? moment(date).format("yyyy-MM-DD") : ""}`)
        if (res) {
            getDocuments();
            toast.success("Geçerlilik tarihi güncellendi")
        }
    }


    return (
        <Box className={styles.upload_item}>
            <p className={styles.title}>
                {name}
            </p>
            {
                document &&
                <Box className={styles.upload_item_root}>
                    <Box className={styles.close_button}>
                        <a href={`${BASE_URL}/document/getData/file/${document.id}`} target="_blank">
                            <Tooltip title="İndir">
                                <IconButton>
                                    <CloudDownloadRounded />
                                </IconButton>
                            </Tooltip>
                        </a>
                        <Tooltip title="Sil">
                            <IconButton onClick={() => removeDocument()}>
                                <Cancel />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <img
                        src={document?.fileName?.substr(document?.fileName?.length - 4) === '.pdf' ? PDF : BASE_URL + document?.url}
                    />
                </Box>
            }
            {
                !document &&
                <Box className={styles.upload_item_root} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Yüklemek için tıklayın!</p>
                </Box>
            }
            {
                document &&
                <DatePicker
                    value={document?.expiration}
                    onChange={(newValue) => addExpiration(newValue)}
                    renderInput={(params) =>
                        <TextField
                            fullWidth
                            {...params}
                            size="small"
                            sx={{ width: "100%", minWidth: "auto" }}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: "Geçerlilik Tarihi",
                            }}
                        />
                    }
                    minDate={new Date()}
                />
            }
        </Box>
    );
};

export default DocumentUpload;