import { createContext, useContext, useState } from 'react';
import { getAdminSettingsRequest } from '../api/controllers/settings-controller';

export const Context = createContext();

export default function Provider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [settings, setSettings] = useState({
        maxWalkingDistance: 0
    })

    const getData = async (formik) => {
        try {
            let res = await getAdminSettingsRequest();
            if (res) {
                if (formik) {
                    formik.setValues(res.data);
                }
                setSettings(res.data)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const data = {
        currentUser,
        setCurrentUser,
        settings,
        setSettings,
        getData
    }

    return (
        <Context.Provider value={data}>
            {children}
        </Context.Provider>
    )
}

export const useAuth = () => useContext(Context)