import { createContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Leaflet, { MAP_DEFAUTLS } from '../../../components/atoms/Leaflet';
import PlannerNavigationForOverTime from '../../../components/molecules/PlannerNavigationForOverTime';
import { Helmet } from 'react-helmet';
import ShowArea from '../../../components/atoms/ShowArea';
import { useCustomer } from '../../../context/CustomerContext';
import CustomerMarker from '../../../components/atoms/CustomerMarker';
import { Tooltip } from 'react-leaflet';
import StationMarker from '../../../components/atoms/StationMarker';
import RoutePolyline from '../../../components/atoms/RoutePolylineForOverTime';
import { useAuth } from '../../../context/AuthContext';
import styles from '../Guzergahlar/style.module.scss';
import { Outlet, useNavigate, useParams } from 'react-router';
import { useOverTime } from '../../../context/OverTimeContext';
import EmployeeTravelerMarkerForOverTime from '../../../components/atoms/EmployeeTravelerMarkerForOverTime';
import CreateStationMarkerForOverTime from '../../../components/atoms/CreateStationMarkerForOverTime';
import { getVehicleCostKmRequest } from '../../../api/controllers/company-controller';
import EmployeeTravelerMarkerMultipleForOverTime from '../../../components/atoms/EmployeeTravelerMarkerMultipleForOverTime';
import { ContextType, Traveler } from '../../../types/OverTimeContext';
import { CostAll } from '../../../types/CommonTypes';

export const CostContext = createContext<ContextType | null>(null);

function Page() {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const { role } = currentUser;
    const map = useRef<L.Map | null>(null);
    const [mapReady, setMapReady] = useState<boolean>(false);
    const { isLoading, filterMarker, travelers, showSidebar, setShowSidebar, stationForm, setStationForm, updateForm, setUpdateForm, plan, plans, setPlan, getOverTime, setTravelers, overTime, areaPolygon, setAreaPolygon } = useOverTime();
    const { customer, setCustomer } = useCustomer();
    const { planUuid, stationId, planType, mesaiId } = useParams();
    const [costs, setCosts] = useState<CostAll>({
        monthlyMaintenance: null,
        monthlyInspection: null,
        monthlyKasko: null,
        monthlyInsurance: null,
        monthlyDriver: null,
        monthlyTyre: null,
        monthlyTotalKm: null,
        fuelPrice: null,
        fuelConsumptionHundredKm: null,
        fuelCostKm: null,
        costKm: null
    });
    type typeplan = "arrival" | "departure";

    const getFilteredTravelers = () => {
        switch (true) {
            case filterMarker.traveler.available && filterMarker.traveler.unavailable:
                return travelers
            case filterMarker.traveler.available:
                return travelers.filter(v => v[`${planType}Enable` as keyof Traveler])
            case filterMarker.traveler.unavailable:
                return travelers.filter(v => !v[`${planType}Enable` as keyof Traveler])
            default:
                return [];
        }
    }

    useEffect(() => {
        if (mapReady) {
            if (Array.isArray(areaPolygon) && areaPolygon.length > 0) {
                const list = areaPolygon.flatMap((v: any) => v.coordinates)
                map && map.current && map.current.flyToBounds(list, { padding: [50, 50] });
            } else {
                map && map.current && map.current.flyTo((MAP_DEFAUTLS as any).center, MAP_DEFAUTLS.zoom)
            }
        }
    }, [mapReady, areaPolygon])

    useEffect(() => {
        if (planUuid && planType && overTime?.plans) {

        }
    }, [JSON.stringify(overTime?.plans)])

    useEffect(() => {
        if (mesaiId && planType) {
            (async () => {
                const overtime = await getOverTime(Number(mesaiId))
                let data = overtime?.plans?.find((v) => v.uuid === planUuid && v.planDirectionType === planType.toUpperCase())
                if (planUuid && data) {
                    setPlan(data)
                } else {
                    setPlan(undefined)
                    navigate(window.location.pathname.split(`/plan/${planUuid}`)[0])
                }
                if (overtime) {
                    setTravelers(overtime.travelers)
                    setCustomer(overtime.customer)
                }
            })()
        }

    }, [mesaiId, planUuid, planType])

    const getCostsData = async () => {
        let res = await getVehicleCostKmRequest();
        if (res) {
            setCosts(res.data);
        }
    };

    useEffect(() => {
        getCostsData();
    }, []);

    return (
        <Box position="relative">
            <Helmet>
                <title>Mesailer | Damlatur Optimizasyon Sistemi</title>
            </Helmet>
            <PlannerNavigationForOverTime setShowSidebar={setShowSidebar} />
            <Leaflet ref={map} setMapReady={setMapReady} isLoading={isLoading}>
                <Box className={`${styles.tabs} ${showSidebar ? styles.sidebar_opened : ""}`}>
                    <Button
                        onClick={() => window.location.pathname.includes('olustur') ? {} : navigate(window.location.pathname.replace(planType as typeplan, "departure"))}
                        className={planType === "departure" ? styles.active : ""}
                    >
                        Gidiş
                    </Button>
                    <Button
                        onClick={() => window.location.pathname.includes('olustur') ? {} : navigate(window.location.pathname.replace(planType as typeplan, "arrival"))}
                        className={planType === "arrival" ? styles.active : ""}
                    >
                        Dönüş
                    </Button>
                </Box>
                {
                    mapReady && customer &&
                    <>
                        {
                            (role === "ADMIN" || role === "MANAGER") &&
                            <>
                                {
                                    //Bölge polygon
                                    Array.isArray(areaPolygon) && areaPolygon.length > 0 &&
                                    areaPolygon.map((v: any, i) =>
                                        <ShowArea
                                            key={i}
                                            coordinates={v.coordinates}
                                            text={v.text}
                                        />
                                    )
                                }
                                {
                                    //Yolcular marker şirket
                                    customer?.customerType === "Company" &&
                                    Array.isArray(travelers) &&
                                    getFilteredTravelers().map((traveler, i) => {
                                        let sameTravelers = travelers.filter(v => JSON.stringify(v.address.position) === JSON.stringify(traveler.address.position))
                                        if (sameTravelers.length > 1) {
                                            return <EmployeeTravelerMarkerMultipleForOverTime
                                                key={i}
                                                travelers={sameTravelers}
                                            />
                                        } else {
                                            return <EmployeeTravelerMarkerForOverTime key={i} traveler={traveler} />
                                        }
                                    }
                                    )
                                }
                                {
                                    plan && planUuid && plan?.vehicle?.driver && (
                                        window.location.pathname.includes("duzenle") ? (
                                            <RoutePolyline plan={updateForm.plan} customer={customer} setUpdateForm={setUpdateForm} />
                                        ) : (
                                            <RoutePolyline plan={plan} customer={customer} />
                                        ))}
                                {
                                    !planUuid && Array.isArray(plans[planType as typeplan]) && filterMarker.routes &&
                                    plans[planType as typeplan]?.map((plan, i) => plan?.vehicle?.driver && <RoutePolyline key={i} plan={plan} customer={customer} />)
                                }
                            </>
                        }
                        {
                            //Müşteri marker
                            customer?.address?.position &&
                            <CustomerMarker position={[customer?.address?.position.latitude, customer?.address?.position.longitude]} />
                        }
                        {
                            customer.customerType === "Company" &&
                            <>
                                {
                                    //Durak oluşturma marker
                                    window.location.pathname.includes("station/olustur") && (
                                        <CreateStationMarkerForOverTime planType={planType as typeplan} />
                                    )
                                }
                                {
                                    //Durak oluşturma mahalleye göre marker
                                    window.location.pathname.includes("station/mahalle-olustur") && (
                                        <CreateStationMarkerForOverTime planType={planType as typeplan} />
                                    )
                                }
                                {
                                    //Durak düzenle marker
                                    stationId && stationForm?.position?.latitude && stationForm?.position?.longitude &&
                                    <StationMarker
                                        position={[stationForm.position.latitude, stationForm.position.longitude]}
                                        eventHandlers={{
                                            dragend: (e: any) => {
                                                setStationForm(prev => ({ ...prev, position: { ...prev.position, latitude: e.target._latlng.lat, longitude: e.target._latlng.lng } }));
                                            }
                                        }}
                                        draggable
                                    />
                                }
                                {
                                    //Durak marker
                                    !stationId && planUuid && plan?.stations && plan?.stations?.length > 0 && filterMarker.station &&
                                    plan?.stations?.map((station, i) =>
                                        <StationMarker
                                            key={i}
                                            position={[station.position.latitude, station.position.longitude]}
                                        >
                                            <Tooltip direction="bottom">
                                                <Box display="flex" flexDirection="column" gap="0.5rem">
                                                    <Typography fontSize="0.75rem" color="primary" fontWeight={500}>{station?.title}</Typography>
                                                    <Divider flexItem />
                                                    <Typography component="ul">
                                                        {
                                                            station?.travelers?.length > 0 ?
                                                                station.travelers.map(traveler =>
                                                                    <Typography fontSize="0.75rem" component="li" key={traveler.id}>{"- " + traveler.firstName + " " + traveler.lastName}</Typography>
                                                                ) :
                                                                <Typography fontSize="0.75rem">Bağlanmış yolcu bulunamadı!</Typography>
                                                        }
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        </StationMarker>
                                    )
                                }
                            </>
                        }
                    </>
                }
            </Leaflet >
            <CostContext.Provider value={costs as any}>
                <Outlet />
            </CostContext.Provider>
        </Box >
    )
}

export default Page;