import { Avatar, ListItemIcon, MenuItem, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomDataGrid from '../../../components/atoms/CustomDataGrid';
import PageLayout from '../../../components/templates/PageLayout';
import { useNavigate } from 'react-router';
import CustomMenu from '../../../components/molecules/CustomMenu';
import { CheckRounded, CloseRounded, Info } from '@mui/icons-material';
import Search from '../../../components/molecules/Search';
import { Stack } from '@mui/system';
import { getAllStudiesRequest } from '../../../api/controllers/study-controller';
import moment from 'moment';
import { Study } from '../../../types/StudyContext';
import { Pageable } from '../../../types/CommonTypes';

const Page = () => {
    const navigate = useNavigate();
    const [isTableLoading, setIsTableLoading] = useState<boolean>(true);
    const [data, setData] = useState<Study[]>([]);
    const [studies, setStudies] = useState<Study[]>([]);
    const [pageable, setPageable] = useState<Pageable>({ page: 0, size: 10, total: 0 });
    const [filter, setFilter] = useState<string>("");

    type RequestStatus = {
        value: "PENDING" | "DONE"
    }


    const columns = [
        {
            field: 'customerRequestStatus',
            headerName: "Durum",
            sortable: false,
            renderCell: ({ value }: RequestStatus) => (
                <Tooltip title={value === 'PENDING' ? "Bekliyor" : value === 'DONE' ? "Tamamlandı" : "-"}>
                    <Avatar sx={{ width: 24, height: 24, fontSize: 14, bgcolor: value === 'DONE' ? "var(--success_12)" : value === 'PENDING' ? "var(--danger_12)" : "var(--danger_12)" }}                >
                        {value === 'DONE' ? <CheckRounded htmlColor="var(--success)" fontSize="inherit" /> : value === 'PENDING' ? < CloseRounded htmlColor="var(--danger)" fontSize="inherit" /> : "-"}
                    </Avatar>
                </Tooltip>
            ),
        },
        {
            field: 'customer',
            headerName: "Firma Ünvanı",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }: any) => value ? value.title : "-",
        },
        {
            field: 'startDate',
            headerName: "Başlangıç tarihi",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value, row }: any) => value ? moment(value).format('LL') : "-",
        },
        {
            field: 'endDate',
            headerName: "Bitiş tarihi",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value, row }: any) => value ? moment(value).format('LL') : "-",
        },
        {
            field: 'travelers',
            headerName: "Kişi Sayısı",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }: any) => value ? value.length : "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ value, row }: any) => <CustomMenu>
                <MenuItem onClick={() => navigate(`/app/etutler/harita/${value}/arrival`)}>
                    <ListItemIcon>
                        <Info fontSize="small" />
                    </ListItemIcon>
                    Detaylar
                </MenuItem>
                {/* {
                    !row.isActive && currentUser.role === "ADMIN" &&
                    <MenuItem onClick={() => handleConfirm(row.title, value)}>
                        <ListItemIcon>
                            <CheckRounded fontSize="small" />
                        </ListItemIcon>
                        Onayla
                    </MenuItem>
                } */}
            </CustomMenu>,
        },
    ]

    useEffect(() => {
        setData(studies.filter(v => v.customer.title.toLocaleUpperCase().includes(filter.toLocaleUpperCase())))
    }, [filter])

    const getAllStudies = async () => {
        setIsTableLoading(true);
        try {
            let res = await getAllStudiesRequest()
            if (res) {
                setStudies(res.data)
                setData(res.data)
                setIsTableLoading(false);
            }
        } catch (error) {
            setIsTableLoading(false);
        }
    }

    useEffect(() => {
        getAllStudies();
    }, [])

    return (
        <PageLayout
            title="Etütler"
            buttons={[]}>
            <Stack spacing={2}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Search
                        onChange={(e: any) => setFilter(e)}
                    />
                </Stack>
                <CustomDataGrid
                    data={data}
                    columns={columns}
                    loading={isTableLoading}
                    rowId={(params: any) => params?.id}
                    pageable={pageable}
                    setPageable={setPageable}
                />
            </Stack>
        </PageLayout >
    );
}

export default Page;