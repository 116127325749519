import { useState } from 'react'
import { Autocomplete, Box, Button, IconButton, TextField } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import styles from './style.module.scss';

function AssignDepartmentMultiple({ onChange, departments, uploadResult, setUploadResult }) {
    const [isFocus, setIsFocus] = useState(false);
    const [text, setText] = useState("");
    const [selectedDepartmentId, setSelectedDepartmentId] = useState()
    const handleChange = e => {
        setText(e.target.value);
        const timer = setTimeout(() => {
            onChange(e.target.value)
        }, e.target.value ? 750 : 0)
        return () => clearTimeout(timer)
    }

    return (
        <Box className={isFocus ? styles.container : styles.containerUnFocus} onBlur={() => !text && setIsFocus(false)} >
            {
                isFocus
                    ?
                    <Box className={styles.form_container}>
                        <IconButton onClick={() => setIsFocus(false)} size="small">
                            <CloseRounded fontSize="inherit" />
                        </IconButton>
                        <Autocomplete
                            fullWidth
                            options={departments}
                            value={departments.find(w => w.id === selectedDepartmentId) || null}
                            onChange={(event, newValue) => {
                                setSelectedDepartmentId(newValue.id);
                                uploadResult.content.map((v, i) => {
                                    v.departmentId = newValue.id;
                                    setUploadResult({ ...uploadResult });
                                })

                            }}
                            getOptionLabel={option => `${option.title}`}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    size="small"
                                    placeholder="Departman"
                                /* error={!selectedDepartmentId} */
                                />}
                        />
                    </Box>
                    :
                    <Button
                        className={styles.button}
                        color="secondary"
                        /*  startIcon={<SearchRounded />} */
                        onClick={() => setIsFocus(true)}
                    >
                        Toplu Departman seç
                    </Button>
            }
        </Box>
    )
}

export default AssignDepartmentMultiple;