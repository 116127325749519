import { useCustomer } from '../../../../../../context/CustomerContext';
import { useSearchParams } from 'react-router-dom';
import CreateSingle from './Single';
import CreateMultiple from './Multiple';

export default function CreateEmployee() {
    const { customer } = useCustomer();
    const [searchParams] = useSearchParams();
    return (
        <>
            {
                searchParams.get("type") === "single" &&
                <CreateSingle customer={customer} />
            }
            {
                searchParams.get("type") === "multiple" &&
                <CreateMultiple customer={customer} />
            }
        </>
    )
}