import { Box } from "@mui/material"
import BgImage from "../../../../components/molecules/BgImage";

export const LoginScreenForms = ({ bgImages, getBgImages }) => {

    return (
        <>
            <Box
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, minmax(10rem, 10rem))",
                    gridAutoRows: "10rem",
                    gap: "1rem",
                }}
            >
                {bgImages?.length > 0 &&
                    bgImages.map((val, i) => (
                        <BgImage
                            key={i}
                            image={val}
                            type="view"
                            getBgImages={getBgImages}
                        />
                    ))}
                <BgImage type="create" getBgImages={getBgImages} />
            </Box>
        </>
    )
}