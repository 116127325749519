import { useState } from 'react';
import { Grid, TextField, } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import CustomDialog from '../../../atoms/CustomDialog';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { delayDailyTaskRequest } from '../../../../api/controllers/daily-task-controller';
import moment from 'moment';

const DelayDailyTask = (props) => {
    const { customerId } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form, setForm] = useState({
        planId: props.selectedId,
        date: props.date,
        startTime: props.selectedWorkTimeEnd,
    })
    const [lists, setLists] = useState({
        plans: [],
        vehicles: [],
        workTimes: [],
    })

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await delayDailyTaskRequest({ ...form, date: moment(form.date).format("yyyy-MM-DD") });
            if (res) {
                toast.success("Sefer ertelendi");
                handleClose();
                props.getData();
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    const handleClose = () => {
        props.setOpen(false);
        setForm({
            planId: props.selectedId,
            date: props.date,
            startTime: props.selectedWorkTimeEnd,
        })
    }

    const onChange = (name, value) => {
        setForm(prev => ({ ...prev, [name]: value }));
    }

    return (
        <CustomDialog
            size="xs"
            open={props.open}
            closeFunc={handleClose}
            title="Sefer dönüşünü ertele"
            buttons={[
                {
                    onClick: handleSubmit,
                    disabled: Object.values(form).includes(null),
                    loading: isSubmitting,
                    variant: "contained",
                    text: "Kaydet",
                }
            ]}
        >
            <Grid container spacing={3}>
                <Grid container item spacing={3} xs={12}>
                    <Grid item xs={12} md={12}>
                        <DatePicker
                            label="Ertelemek İstediğiniz Tarih"
                            value={form.date}
                            onChange={(newValue) => onChange("date", newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            minDate={new Date()}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TimePicker
                            label="Ertelemek İstediğiniz Saat"
                            value={new Date(0, 0, 0, form?.startTime?.slice(0, 2), form?.startTime?.slice(3, 5))}
                            onChange={(newValue) => onChange("startTime", moment(newValue).format("HH:mm:ss"))}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        // shouldDisableTime={props.selectedWorkTimeEnd}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </CustomDialog>
    )
}

export default DelayDailyTask;